import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import Subject from "@material-ui/icons/Subject";
// import WatchLater from "@material-ui/icons/WatchLater";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import office1 from "assets/img/landingPageImage/offices.png";
import office2 from "assets/img/landingPageImage/offices2.png";
import office3 from "assets/img/landingPageImage/offices3.png";

const useStyles = makeStyles(styles);

export default function SectionCards() {
  const classes = useStyles();
  React.useEffect(() => {
    if (window) {
      window.addEventListener("resize", addStylesForRotatingCards);
    }
    addStylesForRotatingCards();
    return function cleanup() {
      if (window) {
        window.removeEventListener("resize", addStylesForRotatingCards);
      }
    };
  });
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
  };
  return (
    <div className="cd-section" id="cards">
      <div className={classes.container}>
        <div className={classes.title}>
          <h3>Popular Destinations</h3>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <Card background style={{ backgroundImage: `url(${office1})` }}>
              <CardBody background>
                {/* <h6 className={classes.cardCategoryWhite}>PRODUCTIVITY APPS</h6> */}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <h4 className={classes.cardTitleWhite}>
                    Gwaliar
                  </h4>
                </a>
                {/* <p className={classes.cardDescriptionWhite}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p> */}
                <Button simple color="white">
                  <Subject /> View All Tour
                </Button>
                {/* <Button simple color="white">
                  <WatchLater /> Watch Later
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card background style={{ backgroundImage: `url(${office2})` }}>
              <CardBody background>
                {/* <h6 className={classes.cardCategoryWhite}>PRODUCTIVITY APPS</h6> */}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <h4 className={classes.cardTitleWhite}>
                    Bhopal
                  </h4>
                </a>
                {/* <p className={classes.cardDescriptionWhite}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p> */}
                <Button simple color="white">
                  <Subject /> View All Tour
                </Button>
                {/* <Button simple color="white">
                  <WatchLater /> Watch Later
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card background style={{ backgroundImage: `url(${office3})` }}>
              <CardBody background>
                {/* <h6 className={classes.cardCategoryWhite}>PRODUCTIVITY APPS</h6> */}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <h4 className={classes.cardTitleWhite}>
                    Bandhavgarh
                  </h4>
                </a>
                {/* <p className={classes.cardDescriptionWhite}>
                  Don{"'"}t be scared of the truth because we need to restart
                  the human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </p> */}
                <Button simple color="white">
                  <Subject /> View All Tour
                </Button>
                {/* <Button simple color="white">
                  <WatchLater /> Watch Later
                </Button> */}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import Tiger from "assets/img/tourPackage/shortTrip/Jabalpur-Bandhavgarh-package.jpg"
import Tigers from "assets/img/tourPackage/shortTrip/Tadoba-Trails-package.jpg"
import Dear from "assets/img/tourPackage/shortTrip/destination-grid-320_385-KanhaPench-National-Park.png"
import Bhopal from "assets/img/tourPackage/shortTrip/destination-grid-320_385-Bhopal.png"
import Pachmarhi from "assets/img/tourPackage/shortTrip/Bhopal-Pachmari-Package.jpg";
import Jungle from "assets/img/tourPackage/shortTrip/destination-grid-320_385-Pachmarhi.png"

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function ShortTrips() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Short Trips</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/tourPackage/shortTrip/DestinationsMainPage.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Short Trips</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <div>
              <div className={classCard.container}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="/tour/jabalpur-bandhavgarh-short-trip">
                          <img src={Tiger} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Trending
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Tiger})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/jabalpur-bandhavgarh-short-trip">
                            <h6 className={classCard.cardCategory}>
                              JABALPUR - BANDHAVGARH - 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="tour/tadoba-short-trip">
                          <img src={Tigers} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Popular Deals
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Tigers})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/tadoba-short-trip">
                            <h6 className={classCard.cardCategory}>
                              TADOBA TRAILS – 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="/tour/jabalpur-kanha-short-trip">
                          <img src={Dear} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Trending
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Dear})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/jabalpur-kanha-short-trip">
                            <h6 className={classCard.cardCategory}>
                              JABALPUR - KANHA - 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="/tour/bhopal-madhai-short-trip">
                          <img src={Bhopal} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Popular Deals
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Bhopal})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/bhopal-madhai-short-trip">
                            <h6 className={classCard.cardCategory}>
                              BHOPAL - MADHAI - 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="/tour/bhopal-pachmarhi-short-trip">
                          <img src={Pachmarhi} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Trending
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Pachmarhi})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/bhopal-pachmarhi-short-trip">
                            <h6 className={classCard.cardCategory}>
                              BHOPAL - PACHMARHI – 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4}>
                    <Card profile plain>
                      <CardHeader image plain>
                        <a href="/tour/pachmarhi-short-trip">
                          <img src={Jungle} alt="..." />
                          {/* <div className={classCard.cardTitleAbsolute}>
                            Popular Deals
                          </div> */}
                        </a>
                        <div
                          className={classCard.coloredShadow}
                          style={{
                            backgroundImage: `url(${Jungle})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                      <CardBody plain>
                        <Info>
                          <a href="/tour/pachmarhi-short-trip">
                            <h6 className={classCard.cardCategory}>
                              PACHMARI PACKAGE - 3N/4D
                            </h6>
                          </a>
                        </Info>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

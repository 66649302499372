import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/gallery/heritage/Bhedaghat/Header-5.jpg";
import image2 from "assets/img/gallery/heritage/Bhedaghat/Marble-rocks.jpg";

import image3 from "assets/img/gallery/heritage/Burhanpur/Ahukhana.jpg"
import image4 from "assets/img/gallery/heritage/Burhanpur/Asirgarh-Fort.jpg"
import image5 from "assets/img/gallery/heritage/Burhanpur/Hammam-and-the-Royal-Bath.jpg"
import image6 from "assets/img/gallery/heritage/Burhanpur/Header-6.jpg"
import image7 from "assets/img/gallery/heritage/Burhanpur/Jama-Masjid.jpg"
import image8 from "assets/img/gallery/heritage/Burhanpur/Kundi-Bhandara.jpg"
import image9 from "assets/img/gallery/heritage/Burhanpur/Shah-Jahan-Mumtaz-in-Burhanpur.jpg"
import image10 from "assets/img/gallery/heritage/Burhanpur/Shah-Nawaz-Khan.jpg"
import image11 from "assets/img/gallery/heritage/Burhanpur/Sikkhism-in-Burhanpur.jpg"
import image12 from "assets/img/gallery/heritage/Burhanpur/Tapti-Ghat.jpg"

import image13 from "assets/img/gallery/heritage/Chanderi/9C4A5236.jpg"
import image14 from "assets/img/gallery/heritage/Chanderi/Chanderi-Fort.jpg"
import image15 from "assets/img/gallery/heritage/Chanderi/Chanderi-Museum.jpg"
import image16 from "assets/img/gallery/heritage/Chanderi/DSC040131735.jpg"
import image17 from "assets/img/gallery/heritage/Chanderi/Jama-Masjid-1.jpg"
import image18 from "assets/img/gallery/heritage/Chanderi/Koshak-Mahal-1.jpg"
import image19 from "assets/img/gallery/heritage/Chanderi/Shehzadi-ka-Roza.jpg"
import image20 from "assets/img/gallery/heritage/Chanderi/Statue-of-the-Jain-Tirthankar-Mahavir.jpg"

import image21 from "assets/img/gallery/heritage/Chitrakoot/c-jankikund.jpg"
import image22 from "assets/img/gallery/heritage/Chitrakoot/Chanderi-Museum.jpg"
import image23 from "assets/img/gallery/heritage/Chitrakoot/Deep-Daan.jpg"
import image24 from "assets/img/gallery/heritage/Chitrakoot/DSC040131735.jpg"
import image25 from "assets/img/gallery/heritage/Chitrakoot/Jama-Masjid-1.jpg"
import image26 from "assets/img/gallery/heritage/Chitrakoot/Koshak-Mahal-1.jpg"
import image27 from "assets/img/gallery/heritage/Chitrakoot/Ram-Ghat.jpg"
import image28 from "assets/img/gallery/heritage/Chitrakoot/Shehzadi-ka-Roza.jpg"

import image29 from "assets/img/gallery/heritage/Gwalior/DSC_1936.jpg"
import image30 from "assets/img/gallery/heritage/Gwalior/gwalior-1649274_1280.jpg"
import image31 from "assets/img/gallery/heritage/Gwalior/Gwalior-fort.jpg"
import image32 from "assets/img/gallery/heritage/Gwalior/Header-7.jpg"
import image33 from "assets/img/gallery/heritage/Gwalior/Jai-Vilas-Palace_600-1280x720-1.jpg"
import image34 from "assets/img/gallery/heritage/Gwalior/Jai-Vilas02.jpg"
import image35 from "assets/img/gallery/heritage/Gwalior/tansen-samaroh-brochure.jpg"

import image36 from "assets/img/gallery/heritage/Maheshwar/Ahilya-Bai-Fort-and-Palace.jpg"
import image37 from "assets/img/gallery/heritage/Maheshwar/Chattri-of-Devi-Ahilya-Bai.jpg"
import image38 from "assets/img/gallery/heritage/Maheshwar/Ghat.jpg"
import image39 from "assets/img/gallery/heritage/Maheshwar/Header-16.jpg"
import image40 from "assets/img/gallery/heritage/Maheshwar/Maheshwar_Fort.jpg"
import image41 from "assets/img/gallery/heritage/Maheshwar/Maheshwari-Saree.jpg"
import image42 from "assets/img/gallery/heritage/Maheshwar/Shiva-temple-Maheshwar-172915-pixahive.jpg"
import image43 from "assets/img/gallery/heritage/Maheshwar/Weaves-Of-Maheshwar.jpg"

import image44 from "assets/img/gallery/heritage/Mandu/Baz-Hahadur.jpg"
import image45 from "assets/img/gallery/heritage/Mandu/Header-9.jpg"
import image46 from "assets/img/gallery/heritage/Mandu/hindola-mahal-Mandu.jpg"
import image47 from "assets/img/gallery/heritage/Mandu/Hindola-Mahal.jpg"
import image48 from "assets/img/gallery/heritage/Mandu/Hoshang_Shahs_Tomb_01.jpg"
import image49 from "assets/img/gallery/heritage/Mandu/Jahaz-Mahal-2.jpg"
import image50 from "assets/img/gallery/heritage/Mandu/Jahaz-Mahal.jpg"
import image51 from "assets/img/gallery/heritage/Mandu/Jami-Masjid.jpg"
import image52 from "assets/img/gallery/heritage/Mandu/kapoor-lake-mandu.jpg"
import image53 from "assets/img/gallery/heritage/Mandu/MANDU-Roopmati-Pavilion.jpg"
import image54 from "assets/img/gallery/heritage/Mandu/Monsoon-Mandu.jpg"

import image55 from "assets/img/gallery/heritage/Orchha/9C4A7179-a.jpg"
import image56 from "assets/img/gallery/heritage/Orchha/Chatris.jpg"
import image57 from "assets/img/gallery/heritage/Orchha/Chaturbhuj_Temple_Orchha.jpg"
import image58 from "assets/img/gallery/heritage/Orchha/Chaturbhuj-Temple-1.jpg"
import image59 from "assets/img/gallery/heritage/Orchha/Header-10.jpg"
import image60 from "assets/img/gallery/heritage/Orchha/Inner-view-of-Jahangir-Mahal-Orchha.jpg"
import image61 from "assets/img/gallery/heritage/Orchha/Jahangir-mahal-palace-at-blue-sky-in-India.jpg"
import image62 from "assets/img/gallery/heritage/Orchha/Jehangir-Mahal.jpg"
import image63 from "assets/img/gallery/heritage/Orchha/Metal-Craft.jpg"
import image64 from "assets/img/gallery/heritage/Orchha/Orccha-Bird-Sancturay.jpg"
import image65 from "assets/img/gallery/heritage/Orchha/Orchha_Fort.jpg"
import image66 from "assets/img/gallery/heritage/Orchha/SEL-Show-1.jpg"

const useStyles = makeStyles(carouselStyle);

export default function HeritageCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhedaghat
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhedaghat
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image7} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image10}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image11}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image12}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Burhanpur
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image13}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image14}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image15}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image16}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image17}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image18}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image19}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image20}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chanderi
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image21}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image22}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image23}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image24}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image25}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image26}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image27}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image28}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Chitrakoot
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image29}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image30}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image31}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image32}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image33}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image34}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image35}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image36}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image37}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image38}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image39}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image40}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image41}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image42}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image43}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Maheshwar
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image44}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image45}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image46}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image47}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image48}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image49}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image50}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image51}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image52}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image53}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image54}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Mandu
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image55}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image56}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image57}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image58}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image59}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image60}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image61}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image62}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image63}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image64}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image65}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image66}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Orchha
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/bhopal/01Bhopal.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Bhopal() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Bhopal</title>
        <meta
          name="keywords"
          content="Bhopal, Bhopal City Tour, Bhopal itinerary, Must visit in Bhopal, Bhopal boat Club, City of Lakes, Street food of Bhopal, Bhopal Tour
Hotels in Bhopal, Tribal Museum, Manavsangrhalay, Ratapani, Sightseeing points in Bhopal"
        />
        <meta
          name="description"
          content="Bhopal- Bhopal City Tour-Bhopal itinerary- Must visit in Bhopal- Bhopal boat Club -City of Lakes- Street food of Bhopal-Bhopal Tour
Hotels in Bhopal-Tribal Museum-Manavsangrhalay -Ratapani-Sightseeing points in Bhopal"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/bhopal/Bhopal.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Bhopal</h1>
              <h4>
                Brilliant Bhopal, a city of lakes and a city ruled by the Begums
                of Bhopal
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                The green city of Madhya Pradesh, Bhopal is also a popular
                tourist destination for those who visit Madhya Pradesh. A place
                like no other, this city in central India is home to multiple
                tourist destinations and travel spots!
                <br />
                <br />
                The green city is called so because of its intricate Upper and
                Lower Lake structure that supplies water to the city.
                <br />
                <br />
                A place also home to the the Bengal Tiger, Asiatic Lions,
                wildcats, foxes, Jackals, Chitals, Blackbucks, mongooses and
                even pythons! The Van Vihar National park has it all!
                Exploration of this park is allowed with trusted guides and only
                a limited number of safari jeeps are allowed to enter to protect
                the wildlife inside.
                <br />
                <br />
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Not only wildlife, Bhopal also has museums that contain the most
                breath-taking paintings, rare sculptures coin galleries, ancient
                inscriptions, weapon gallerys and more.
                <br />
                <br />
                You can also choose to explore the cultures of tribes at the
                Madhya Pradesh Tribal Musuem, consisting of intricate details of
                the 7 major tribes of the state, namely Bheel, Gond, Baiga, Kol,
                Bhariya, Korku, and Sahariya. The vibrant gallery of the museum
                displays the traditional artworks and crafts of the tribes.
                <br />
                <br />
                Don’t forget to visit the largest mosque in the country when in
                Bhopal, the Taj-Ul-Masjid, cover an area of 430,000 sq ft. made
                of red stone, its beauty is enhanced by the Motia Talab.
                <br />
                <br />
                Bhimbetka caves are also a important world heritage site to
                visit, with their prehistoric rock shelters they bear a close
                resemblance to Kakadu National park in Australia.
                <br />
                <br />
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import InfoArea from "components/InfoArea/InfoArea.js";
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteIcon from '@material-ui/icons/Favorite';

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";
// images
import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/presentation-ipad.jpg";
import serviceHotel from "assets/img/ourService/v3Our-services-Hotels-photo.jpg"
import serviceGroup from "assets/img/ourService/v3Our-services-Group-photo.jpg"
import serviceMice from "assets/img/ourService/v3Our-services-MICE-photo.jpg" 
import serviceStargazing from "assets/img/ourService/v3Our-services-Stargazing-photo.jpg"

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(featuresStyle);

export default function OurService() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classCard = useStyl();

  return (
    <div>
      <Helmet>
        <title>Our service</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/ourService/Our-Services-1024x474.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Services We Provide</h1>
              <h4></h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div className={classe.imageContainer} style={{marginTop: "3%"}}>
                <img
                  className={classe.ipadImg}
                  src={serviceHotel}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h3 className={classe.title}>HOTEL MARKETING</h3>
              <h5 className={classe.description}>
                We also book tours for Nepal, Jammu & Kashmir, Chardham Yatra,
                Amarnath Yatra, Himachal Pradesh, Uttrakhand, and Southern
                India. We help and Market Hotels from all over Madhya Pradesh.
                We have a specialized team who stays in contact with the Hotel
                executive and offers Budget package to our customers. <br />
                <br />
                Some of the properties for which we are official partners:{" "}
                <br />
                <br />
                <ol>
                  <li>
                    WH GOLF VIEW CLUB & RESORT – PACHMARHI Best known for their
                    beautiful destinations made especially for Destination
                    Weddings, Jungle safaris, Bird watching, and Multi cuisine
                    Pure veg food!
                  </li>
                  <li>
                    WH JUNGLE HOME RESORT- PENCH A breath-taking view of the
                    Resort, with Jungle tents, Jungle safaris, Village tours,
                    Bird watching Tiger trail tours and so much more is waiting
                    for you!
                  </li>
                </ol>
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={6}>
              <h3 className={classe.title}>MICE</h3>
              <h5 className={classe.description}>
                M-Meetings <br />
                I-Incentives <br />
                C-Conferencing <br />
                E-Exhibitions! <br />
                <br />
                Special and unique facilities with great services are provided!
                Be it a Non-Business tour or a Business tour we will always be
                up to the mark. With MICE facility provided all across India. We
                make sure any and every event is executed as per our client
                requirements and handled with care. <br />
                <br />
                Be it Non Business tour or a Business tour we will always be up
                to the mark. We provide MICE facilities all over India. Every
                event is executed as per our client requirements and handled
                with care.
              </h5>
            </GridItem>
            <GridItem md={6}>
              <div className={classe.imageContainer}>
                <img
                  className={classe.ipadImg}
                  src={serviceGroup}
                  alt="iPad"
                />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={6}>
              <div className={classe.imageContainer}>
                <img
                  className={classe.ipadImg}
                  src={serviceMice}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h3 className={classe.title}>PACKAGE TOURS</h3>
              <h5 className={classe.description}>
                Choose from a wide range of Tours and Packages customized
                perfectly to your needs!
              </h5>
              <ol>
                <li className={classe.descriptions}>Educational Tours</li>
                <li className={classe.descriptions}>Group Tours</li>
                <li className={classe.descriptions}>
                  Customized Leisure Tours
                </li>
                <li className={classe.descriptions}>Escorted Group Tours</li>
                <li className={classe.descriptions}>Corporate Tours</li>
                <li className={classe.descriptions}>
                  Educational Tours / Students Group
                </li>
                <li className={classe.descriptions}>Adventure Holidays</li>
                <li className={classe.descriptions}>Honeymoon Packages</li>
                <li className={classe.descriptions}>Offbeat Destinations</li>
              </ol>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={6}>
              <h3 className={classe.title}>Unique & Exclusive activities</h3>
              <ol>
                <li className={classe.descriptions}>Bird Watching</li>
                <li className={classe.descriptions}>Jungle Trek</li>
                <li className={classe.descriptions}>Walking Safari</li>
                <li className={classe.descriptions}>Star Gazing</li>
                <li className={classe.descriptions}>Cycle Tour</li>
                <li className={classe.descriptions}>Tribal Village Visit</li>
                <li className={classe.descriptions}>Camping</li>
                <li className={classe.descriptions}>Local Food</li>
                <li className={classe.descriptions}>Mud Parasailing</li>
                <li className={classe.descriptions}>Vintage Car Ride</li>
              </ol>
            </GridItem>
            <GridItem md={6}>
              <div className={classe.imageContainer}>
                <img
                  className={classe.ipadImg}
                  src={serviceStargazing}
                  alt="iPad"
                />
              </div>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={ViewHeadlineIcon}
                title="Hotel Reservation"
                description="We offer hotels undertaking the preference and emotions of the customer. We have a dedicated team who are trained and experience in managing hotel reservation (5,4,3,2 star). Assurance of best hotels comparatively with other travelers."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={LocationOnIcon}
                title="Off-Beat Destinations"
                description="Madhya Pradesh is the city of heart and this heart beats for everyone. If you are a wildlife enthusiast or if you prefer history We have everything for you in our off beat destination packages. Why only smell MP when you can taste it."
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={GroupIcon}
                title="Wildlife Safari Tour"
                description="Madhya Pradesh is known for its safari and beautiful creation which is amused by the god itself. Experience the roar of the Bengal Tiger which will get you goosebumps , the bison, and the jump of the Barking Deer. Excited??!! Book your package now!!!!."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={GroupIcon}
                title="Guided Group Tour"
                description="Got a gang?Planning for a trip ?? Explore MP is your answer. We provide guided group tour into the heart of India. Group tours from Budget to luxurious package. No need to wait!! Grab your package now!!"
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={PersonIcon}
                title="Individual Tours"
                description="Got irritated of cancelling your trip with Friends and Family?? No fear when Explore MP is here!! We have individual packages which worth every penny and makes your life WanderLust with the beautiful history of MP. Push off yourself for some new memories."
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} md={4}>
              <InfoArea
                icon={FavoriteIcon}
                title="Honeymoon Packages"
                description="Surprise your love with the best Honeymoon package. Explore MP provides you Honeymoon package from Budget to Luxurious. After all the best gift is the memory which can be cherished whole life."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

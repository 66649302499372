/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/mandu/MANDU-Roopmati-Pavilion.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Mandu() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Mandu</title>
        <meta name="keywords" content="Mandu, Mandu city tour, Places to visit in Mandu, Mandavgarh, Archeological sites, Hotels in Mandu, Rani Roopmati , Baz Bahadur, Jahaz Mahal, 
Resorts in Mandu, Malwa City, Malwa Food" />
        <meta name="description" content="Mandu - Mandu city tour- Places to visit in Mandu- Mandavgarh- Archeological sites- Hotels in Mandu- Rani Roopmati - Baz Bahadur- Jahaz Mahal- 
Resorts in Mandu - Malwa City- Malwa Food" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/mandu/Mandu.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Mandu</h1>
              <h4>Step into Mesmerising Mandu and explore the unexplored.</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div className={classe.imageContainer} style={{marginTop: "10%"}}>
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Mandu is a small but ancient fort city in the state of Madhya
                Pradesh in India, best known for the fort built by Baaz Bahadur
                in memory of his queen Rani Roopmati. Surrounded by stone walls
                dotted with Darwaza mark the entrances to the city.
                <br />
                <br />
                In the city of Mandu, there are many historical and
                archaeological things you can do like visit Masjids and forts.
                The rich culture and history of the place will surely leave you
                in awe!
                <br />
                <br />
                The place is also known for its Afghan architectural heritage.
                <br />
                <br />
                Important landmark buildings that are a must-visit when heading
                to Mandu include Hoshang Shah’s Tomb, a domed marble mausoleum.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                The vast Jami Masjid mosque, with courtyards framed by
                colonnades.
                <br />
                <br />
                The mosque is said to have been built in the Moghul style of
                architecture and was built during the reign of Hoshang Shah
                <br />
                <br />
                The imposing, centuries-old Jahaz Mahal palace stands between 2
                lakes.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/madhayaPradeshPackage/315757517_bb90cd58cd_b.jpg";
import image2 from "assets/img/tourPackage/madhayaPradeshPackage/18979741171_ccafc3c7ff_b.jpg";
import image3 from "assets/img/tourPackage/madhayaPradeshPackage/49919887071_d280c67fdd_b.jpg";
import image4 from "assets/img/tourPackage/madhayaPradeshPackage/Gwalior_Fort_-Gwalior_-Madhya_Pradesh_-IMG_1315.jpg";
import image5 from "assets/img/tourPackage/madhayaPradeshPackage/Gwalior_Fort_-Gwalior_-Madhya_Pradesh_-IMG_1328.jpg";
import image6 from "assets/img/tourPackage/madhayaPradeshPackage/Pabellon_del_Arte_Zagreb_Croacia_2014-04-20_DD_05.jpg";
import image7 from "assets/img/tourPackage/madhayaPradeshPackage/RAJ_2246.jpg";
import Jungle from "assets/img/tourPackage/madhayaPradeshPackage/Homepage-Destinations-image-size-370_370-Pachmarhi.png";
import River from "assets/img/tourPackage/madhayaPradeshPackage/Homepage-packages-image-size-370_370-Sampoorna-MP-.png";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function MadhyaPradeshPackage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Madhya Pradesh Package</title>
        <meta name="keywords" content="enchanting-madhya-pradesh-package-tour-itinerary, World heritagesite, bandhavgarh wildlife, jungle safari, pachmarhi hillstation" />
        <meta name="description" content="enchanting-madhya-pradesh-package-tour-itinerary World heritagesite and bandhavgarh wildlife jungle safari with pachmarhi hillstation" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/tourPackage/madhayaPradeshPackage/Madhya-Pradesh.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Madhya Pradesh Package</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Enchanting Madhya Pradesh</h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;9N/10DAYS
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 10+
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Airport
                              </GridItem>
                            </GridContainer>
                            <h4>
                              An enchanting tour of an enchanting state! Immerse
                              into the culture of Madhya Pradesh, let the allure
                              of the state pull you in and take you on a journey
                              that will stay with you forever!
                              <br />
                              <br />
                              Destinations Covered- Bhopal-Pachmarhi, Jabalpur,
                              Bandhavgarh, Khajuraho, Gwalior
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Bhopal & Gwalior</span>
                                <br />
                                <span>As per schedule</span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span>
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              An enchanting 10-day tour, explore the beauty and
                              allure of Madhya Pradesh with this exploratory
                              tour, with multiple stops to the ultimate
                              locations of Madhya Pradesh. Take wildlife tours,
                              pilgrimage tours and more!
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;<span>Historic tours</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>
                                  Neolithic cave paintings and museums
                                </span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Call of the wild, Bandhavgarh visit</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;<span>Khajuraho, Temple tours</span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "PHOTOS",
                    tabContent: (
                      <span>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <h3>Photos</h3>
                            <Card>
                              <Carousel {...settings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="First slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Second slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Somewhere Beyond, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image6}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image7}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Bhopal-Mumbai to Bhopal",
                                  content:
                                    "Arrive at Bhopal air-port/station and proceed to hotel. Check-in hotel. Lunch. Depart for city sight-seeing. Visit to Sanchi stupas (world heritage site), which is 50 kms from Bhopal city. Evening visit to local Bhopal, upper /lower lake, Laxmi Narayan temple. Dinner and day ends in Bhopal.",
                                },
                                {
                                  title:
                                    "Day 2 Bhopal to Pachmarhi (200kms/4 Hrs Drive)",
                                  content:
                                    "Start for Pachmarhi, to experience the beauty of Satpura mountain ranges. Enroute. Visit the world heritage site -Bhimbetka and a huge Shivlinga of Bhojpur. Reach Pachmarhi. Check-in hotel. Visit to Pachmarhi lake, Pandav caves and Handikhoh. Dinner. Day ends in Pachmarhi.",
                                },
                                {
                                  title: "Day 3 Pachmarhi (Full Day)",
                                  content:
                                    "Pachmarhi, the hill station of Madhya pradesh is also known for the Pachmarhi cantonment. After break-fast, depart to explore the beauty of Pachmarhi. Visit various points like Gupt Mahadeo, Jata Shankar, Pandav caves, Dhoopgarh, Priyadarshini and Handikhoh. Lunch en-route. Dinner at hotel. Day ends in Pachmarhi.",
                                },
                                {
                                  title:
                                    "Day 4 Pachmarhi to Jabalpur (250kms/6 Hrs Drive)",
                                  content:
                                    "After break-fast, start for Jabalpur. Jabalpur, a capital of Gond kings in 12th century. Reach Jabalpur and check-in hotel. Lunch. After lunch, head towards Bhedaghat and enjoy the boating. After boating, visit the Dhuandhar falls. Dinner. Day ends in Jabalpur.",
                                },
                                {
                                  title:
                                    "Day 5 Jabalpur to Bandhavgarh (190 Kms/4 Hrs Drive)",
                                  content:
                                    "This day after early morning breakfast, depart for Bandhavgarh, the land of real Bengal tigers. Reach Bandhavgarh and keep the evening at leisure. A small documentary show on the wild-life habitat. Dinner and overnight stay in Bandhavgarh.",
                                },
                                {
                                  title:
                                    "Day 6 Bandhavgarh to Khajuraho (245 Kms/6 Hrs Drive)",
                                  content:
                                    "This morning, you will get a wakeup call to fix and appointment with the wild cat. After jungle safari, back to hotel. Breakfast and depart for Khajuraho. Reach Khajuraho. Evening at leisure or attend the light-n-sound show. Dinner and overnight stay in Khajuraho.",
                                },
                                {
                                  title: "Day 7 Khajuraho (Full Day)",
                                  content:
                                    "After a relxed morning, visit to the Easter, western and southern group of temples.",
                                },
                                {
                                  title:
                                    "Day 8 Khajuraho to Gwalior via Orchha (310 Kms/8 Hrs Drive)",
                                  content:
                                    "Breakfast and early check out from hotel and head towards Gwalior. En-route we will visit the famous Orchha city well known by the territory of raja ram, after a lunch break, reach Gwalior. Dinner. Overnight stay in Gwalior.",
                                },
                                {
                                  title: "Day 9 Gwlior (Full Day)",
                                  content:
                                    "This morning, after breakfast, we will visit the famous Jain vilas palace, sun temple, Tansen tomb. Lunch and visit Gwalior fort and attend the light-n-sound show. Dinner and overnight stay in Gwalior.",
                                },
                                {
                                  title: "Day 10 Gwalior- Tour Ends",
                                  content:
                                    "After a relaxed morning, checkout and depart for the nearest railhead.",
                                },
                              ]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "VEHICLE USED",
                                "PER PERSON COST",
                                "STANDARD PKG",
                                "DELUXE PKG",
                                "PREMIUM PKG",
                              ]}
                              tableData={[
                                [
                                  "AC SWIFT D’ZIRE",
                                  "2 PAX",
                                  "INR 30,449",
                                  "INR 35,527",
                                  "INR 45,618",
                                ],
                                [
                                  "AC SWIFT D’ZIRE",
                                  "4 PAX",
                                  "INR 22417",
                                  "INR 27,770",
                                  "INR 37,593",
                                ],
                                [
                                  "AC INNOVA",
                                  "6 PAX",
                                  "INR 20633",
                                  "INR 26,254",
                                  "INR 35,810",
                                ],
                                [
                                  "EXTRA PERSON",
                                  "ABOVE 5 YEARS",
                                  "40% OF THE DBL COST",
                                  "40% OF THE DBL COST",
                                  "40% OF THE DBL COST",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "CITY",
                                "STANDARD PKG HOTELS",
                                "DELUXE PKG HOTELS",
                                "PREMIUM PKG HOTELS",
                              ]}
                              tableData={[
                                [
                                  "BHOPAL",
                                  "HOTEL SANGAT REGENCY/SIMILAR",
                                  "HOTEL PALASH RESIDENCY/SIMILAR",
                                  "SAYAJI",
                                ],
                                [
                                  "PACHMARHI",
                                  "HOTEL HIGHLAND/SIMILAR",
                                  "CHAMPAK BUNGALOW",
                                  "WH GOLF VIEW /ROCK-END-MANOR",
                                ],
                                [
                                  "JABALPUR",
                                  "HOTEL CITY INN /SIMILAR",
                                  "V-RESORT / SIMILAR",
                                  "HOTEL VIJAN MAHAL/SIMILAR",
                                ],
                                [
                                  "BANDHAVGARH",
                                  "SUN RESORT/SIMILAR",
                                  "INFINITY RESORT/SIMILAR",
                                  "SYNA TIGER RESORT/SIMILAR",
                                ],
                                [
                                  "KHAJURAHO",
                                  "ISABEL PALACE/SIMILAR",
                                  "MINT RESORT/SIMILAR",
                                  "SYNA HERITAGE",
                                ],
                                [
                                  "GWALIOR",
                                  "HOTEL BELLEVUE/SIMILAR",
                                  "HOTEL AMBIENCE /SIMILAR",
                                  "CLARKS INN SUITES/SIMILAR",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>THE ABOVE PRICE INCLUDES</h4>
                            <ul>
                              <li>Accommodation on twin sharing basis.</li>
                              <li>
                                Map meal plan at all places except ap in jungle.
                              </li>
                              <li>
                                Transfers and sight-seeing as per no. Of persons
                                mentioned above.
                              </li>
                              <li>
                                (Note: ac will be closed while on hills/ghats).
                              </li>
                            </ul>
                            <h4>THE ABOVE PRICE EXCLUDES:</h4>
                            <ul>
                              <li>Any airfare /rail fare.</li>
                              <li>5% GST.</li>
                              <li>
                                Jungle safaris extra at rs 5500/- per round
                                (accommodates 6 persons).
                              </li>
                              <li>Open gypsy at Pachmarhi.</li>
                              <li>
                                Any en-route meals or other meal not mentioned
                                in price includes.
                              </li>
                              <li>
                                Any other destination not mentioned in
                                itinerary.
                              </li>
                              <li>
                                Any monument / museum entry fees, camera fees
                                and expenses of personal nature.
                              </li>
                            </ul>
                            <h4>THINGS TO NOTEDS</h4>
                            <ul>
                              <li>
                                Rooms are subject to availability. We do not
                                hold any rooms.
                              </li>
                              <li>
                                Confirmation of rooms is subject to the receipt
                                of 100% advance payment within 3 working days of
                                booking.
                              </li>
                              <li>
                                Jungle safari is subject to availability at the
                                time of booking.
                              </li>
                              <li>
                                {" "}
                                It is mandatory to carry your photo id’s while
                                on tour.
                              </li>
                            </ul>
                            <hr />
                            <Table
                              tableHead={["PAYMENT POLICY"]}
                              tableData={[
                                ["AT THE TIME OF THE BOOKING", "30%"],
                                ["02 MONTH BEFORE THE TOUR STARTS", "50%"],
                                ["10 DAYS BEFORE THE TOUR STARTS", "20%"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>CANCELLATION POLICY</h4>
                            <Table
                              tableHead={[
                                "CANCELLATION MODE",
                                "AMOUNT REFUND OUT OF TOTAL COST",
                              ]}
                              tableData={[
                                ["60 DAYS BEFORE TOUR", "70 % REFUND"],
                                ["45 DAYS BEFORE TOUR", "60 % REFUND"],
                                ["30 DAYS BEFORE TOUR", "50 % REFUND"],
                                ["15 DAYS BEFORE TOUR", "40 % REFUND"],
                                ["07 DAYS BEFORE TOUR", "25 % REFUND"],
                                ["LESS THAN 07 DAYS BEFORE TOUR", "0 % REFUND"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>NOTE:</h4>
                            <ul>
                              <li>
                                Above rates are net and non-commissionable
                                rates.
                              </li>
                              <li>
                                Room category in all packages is with base
                                category or as mentioned.
                              </li>
                              <li>
                                In sold out position of the hotels, we will try
                                to book other hotel of similar category.
                              </li>
                              <li>
                                Rates will change during long weekends and peak
                                dates.
                              </li>
                            </ul>
                            <h6>
                              {" "}
                              HOPE THE ABOVE IS IN LINE WITH REQUEST. FOR MORE
                              DETAILS, PLEASE FEEL FREE TO CONTACT US.
                            </h6>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                      </span>
                    ),
                  },
                  {
                    tabButton: "FAQ",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>FAQ</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Best time to visit Madhya Pradesh",
                                  content:
                                    "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                                },
                                {
                                  title: "Services offered?",
                                  content:
                                    "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                                },
                                {
                                  title: "Which tour is best for me?",
                                  content:
                                    "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                                },
                                {
                                  title: "Do you provide flight bookings?",
                                  content:
                                    "After break-fast, start for Jabalpur. Jabalpur, a capital of Gond kings in 12th century. Reach Jabalpur and check-in hotel. Lunch. After lunch, head towards Bhedaghat and enjoy the boating. After boating, visit the Dhuandhar falls. Dinner. Day ends in Jabalpur.",
                                },
                                {
                                  title:
                                    "Who can I contact in case of any problems during my tour/ stay?",
                                  content:
                                    "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                                },
                              ]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Jungle} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Popular Deals
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Jungle})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Satpuras Queen
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={River} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Choice
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${River})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Sampoorna Madhya Prades Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

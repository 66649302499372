/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/jabalpur/01Jabalpur.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Jabalpur() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Jabalpur</title>
        <meta
          name="keywords"
          content="Jabalpur, Jabalpur city tour, Jabalpur sight, seeing points, Bhedaghat, Marble Rocks, Dhuandhar Falls, Madan Mahal fort, Bargi dam, Gwahri Ghat,
Hotels in Jabalpur"
        />
        <meta
          name="description"
          content="Jabalpur - Jabalpur city tour- Jabalpur sight-seeing points- Bhedaghat- Marble Rocks- Dhuandhar Falls- Madan Mahal fort- Bargi dam- Gwahri Ghat-
Hotels in Jabalpur"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/jabalpur/Jabalpur.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Jabalpur</h1>
              <h4>Jovial Jabalpur and its beautiful waterfalls!</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Jabalpur, one of the major cities of Madhya Pradesh, is also a
                famous tourist destination for locals as well as international
                tourists. Cradled at the bank of Narmada, with its beautiful
                weather and beautiful spots to visit, it becomes an automatic
                hit people travelling to Madhya Pradesh.
                <br />
                <br />
                Madhya Pradesh is a overall green state, and Jabalpur proves
                this statement word for word. With its awe-inspiring waterfall
                and rock formations.
                <br />
                <br />
                An important educational and cultural hub of the state, it
                boasts not only in cultural but also educational front.
                <br />
                <br />
                The best time to visit would be winter seasons when the climate
                is cold and the locations open for tourist visits.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                There are a lot of things to do here, from taking a dip in the
                holy Narmada river, to boat riding adventures.
                <br />
                <br />
                The cultural city has the splendour of modernity and ancient
                values.
                <br />
                <br />
                When visiting, dont forget the pristine Dhuandhar falls which
                get their name from the rushing waters that look almost white!
                <br />
                <br />
                But not to also forget the Marbles tocks that lay near the
                Narmada river. The rushing Narmada has cut through the beautiful
                white marble rocks, giving it a beautiful view unlike any other!
                The moonlight on Purnima is said to have magic healing
                properties, commonly refered to as Amrit Varsha.
                <br />
                <br />
                There are also some adventure activities and sports tourists can
                also take part in when visiting the state
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

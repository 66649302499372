import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.10.0";

// pages for this product
import ExplorePage from "views/ExplorePage/ExplorePage.js";
import OurService from "views/ExplorePage/Sections/OurService"
import ExploreContact from "views/ExplorePage/Sections/ExploreContact"
import ExploreBlog from "views/ExplorePage/Sections/ExploreBlog"
import ExploreAbout from "views/ExplorePage/Sections/ExploreAbout";
import ExploreGallery from "views/ExplorePage/Gallery/ExploreGallery"
import Bandhavgarh from "views/ExplorePage/DestinationOption/Bandhavgarh"
import Bhopal from "views/ExplorePage/DestinationOption/Bhopal"
import Gwaliar from "views/ExplorePage/DestinationOption/Gwalior";
import Indore from "views/ExplorePage/DestinationOption/Indore"
import Jabalpur from "views/ExplorePage/DestinationOption/Jabalpur"
import KanhaAndPenchNationalPark from "views/ExplorePage/DestinationOption/KanhaAndPenchNationalPark";
import Khajuraho from "views/ExplorePage/DestinationOption/Khajuraho";
import Maheshwar from "views/ExplorePage/DestinationOption/Maheshwar";
import Mandu from "views/ExplorePage/DestinationOption/Mandu";
import Orchha from "views/ExplorePage/DestinationOption/Orchha";
import Pachmarhi from "views/ExplorePage/DestinationOption/Pachmarhi";
import Ujjain from "views/ExplorePage/DestinationOption/Ujjain";
import Sanchi from "views/ExplorePage/DestinationOption/Sanchi";
import MadhyaPradeshPackage from "views/ExplorePage/TourPackage/MadhyaPradeshPackage"
import GlimpsesofMadhayaPradesh from "views/ExplorePage/TourPackage/GlimpsesofMadhyaPradesh"
import SatpurasQueen from "views/ExplorePage/TourPackage/SatpurasQueen";
import RoyalMadhyaPradesh from "views/ExplorePage/TourPackage/RoyalMadhyaPradesh";
import MalwaDelight from "views/ExplorePage/TourPackage/MalwaDelight";
import MagicalMadhyaPradesh from "views/ExplorePage/TourPackage/MagicalMadhyaPradesh";
// import SampoornaMadhyaPradeshGroupTourPackage from "views/ExplorePage/TourPackage/SampoornaMadhyaPradeshGroupTourPackages";
// import NarmadaParikramaYatraGroupTourPackage from "views/ExplorePage/TourPackage/NarmadaParikramaYatraGroupTourPackages";
import MHMPJyotirlingaSpecialGroupTourPackages from "views/ExplorePage/TourPackage/MHMPJyotirlingaSpecialGroupTourPackages";
import JainTour from "views/ExplorePage/TourPackage/JainTour";
import LadiesSpecialTourPackages from "views/ExplorePage/TourPackage/LadiesSpecialTourPackages";
import ShortTrips from "views/ExplorePage/TourPackage/ShortTrips";
import JabalpurBandhavgarh from "views/ExplorePage/TourPackage/JabalpurBandhavgarh";
import TadobaShort from "views/ExplorePage/TourPackage/TadobaShort";
import JabalpurKanha from "views/ExplorePage/TourPackage/JabalpurKanha";
import BhopalMadhai from "views/ExplorePage/TourPackage/BhopalMadhai";
import BhopalPachmarhi from "views/ExplorePage/TourPackage/BhopalPachmarhi";
import PachmarhiTour from "views/ExplorePage/TourPackage/PachmarhiTour";
import Sampoorna from "views/ExplorePage/TourPackage/Sampoorna";
import Narmada from "views/ExplorePage/TourPackage/Narmada";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/our-service" component={OurService} />
      <Route path="/contact-us" component={ExploreContact} />
      <Route path="/blogs" component={ExploreBlog} />
      <Route path="/about-us" component={ExploreAbout} />
      <Route path="/gallery" component={ExploreGallery} />
      <Route path="/bandhavgarh-tour" component={Bandhavgarh} />
      <Route path="/bhopal-tour" component={Bhopal} />
      <Route path="/gwalior-tour" component={Gwaliar} />
      <Route path="/indore-tour" component={Indore} />
      <Route path="/jabalpur-tour" component={Jabalpur} />
      <Route path="/kanha-and-pench-national-park-tour" component={KanhaAndPenchNationalPark} />
      <Route path="/khajuraho-tour" component={Khajuraho} />
      <Route path="/maheshwar-tour" component={Maheshwar} />
      <Route path="/mandu-tour" component={Mandu} />
      <Route path="/orchha-tour" component={Orchha} />
      <Route path="/pachmarhi-tour" component={Pachmarhi} />
      <Route path="/ujjain-tour" component={Ujjain} />
      <Route path="/sanchi-tour" component={Sanchi} />
      <Route path="/tour/enchanting-madhya-pradesh-package-tour-itinerary" component={MadhyaPradeshPackage} />
      <Route path="/tour/madhya-pradesh-travel-itinerary" component={GlimpsesofMadhayaPradesh} />
      <Route path="/tour/satpura-tour-package" component={SatpurasQueen} />
      <Route path="/tour/madhya-pradesh-tour-plan" component={RoyalMadhyaPradesh} />
      <Route path="/tour/malwa-tour-package" component={MalwaDelight} />
      <Route path="/tour/madhya-pradesh-package-tour" component={MagicalMadhyaPradesh} />
      <Route path="/tour/15-days-sampoorna-madhya-pradesh-tour-itinerary" component={Sampoorna} />
      <Route path="/tour/narmada-parikrama-tour-packages" component={Narmada} />
      <Route path="/tour/jyotirlinga-tour-package-from-mumbai" component={MHMPJyotirlingaSpecialGroupTourPackages} />
      <Route path="/tour/madhya-pradesh-jain-tour" component={JainTour} />
      <Route path="/tour/madhya-pradesh-tour-package-for-ladies" component={LadiesSpecialTourPackages} />
      <Route path="/madhya-pradesh-short-trips" component={ShortTrips} />
      <Route path="/tour/jabalpur-bandhavgarh-short-trip" component={JabalpurBandhavgarh} />
      <Route path="/tour/tadoba-short-trip" component={TadobaShort} />
      <Route path="/tour/jabalpur-kanha-short-trip" component={JabalpurKanha} />
      <Route path="/tour/bhopal-madhai-short-trip" component={BhopalMadhai} />
      <Route path="/tour/bhopal-pachmarhi-short-trip" component={BhopalPachmarhi} />
      <Route path="/tour/pachmarhi-short-trip" component={PachmarhiTour} />
      {/* <Route path="/sampoorna" component={Sampoorna} /> */}
      <Route path="/" component={ExplorePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);

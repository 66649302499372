/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/orchha/01Orchha.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Orchha() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Orchha</title>
        <meta
          name="keywords"
          content="Orchha, Orchha city tour, Betwa river, River Rafting, Ram Raja temple, Kanchana ghat, Chaturbhuj temple, Chattris , Orchha fort, Hotels in Orchha
Betwa retreat, Sheesh Mahal, SEL Show, Sound-n-Light show"
        />
        <meta
          name="description"
          content="Orchha - Orchha city tour- Betwa river- River Rafting- Ram Raja temple- Kanchana ghat- Chaturbhuj temple- Chattris - Orchha fort- Hotels in Orchha
Betwa retreat- Sheesh Mahal -SEL Show- Sound-n-Light show"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/orchha/Orchha.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Orchha</h1>
              <h4>
                Opulent Orchha, with its flowing Betwa river is surely a
                princely land!
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Orchha is a town in the Niwari District of Madhya Pradesh. The
                town was established by Rudra Pratap Singh around 1501, as the
                central point of the Bundelkhand region.
                <br />
                <br />
                Rudra Pratap Singh was the first king of the Orchha region and
                the person who built the fort of Orchha! The city of Orchha is a
                4-hour drive from the Kanpur Airport making it a scenic spot to
                drive to.
                <br />
                <br />
                The fort of Orchha, nestled on the banks of the Betwa river
                seems like an ancient town frozen in time, with its beautiful
                architecture and detailed intricacies in the carvings, it’s a
                must-see sight.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                The other points of interest in Orchha are the Chaturbhuj
                Temple, Chhatris. Jehangir Mahal, Laxmi Narayan temple and the
                Orchha Bird Sanctuary!
                <br />
                <br />
                The famous Chhatris of orchhas are the cenotaphs built as
                memorials for the rulers of Orchha. These famed structures loom
                over in height and are a beautiful sight in the early morning
                sunlight.
                <br />
                <br />
                The Queen of Orchha was a great devotee of Lord Ram, in his
                honour the temple, Rama Raj was built. The fort is also one of
                great surprise, the only town in which the great lord Rama is
                woshipped as both a King and a God.
                <br />
                <br />
                Another famous temple of the region, Built in 875 AD during the
                reign of Gurjara Pratihara dynasty, is dedicated to Lord Vishnu.
                The name literally means the one with four arms, and Lord Vishnu
                has been referred as Chaturbhuj.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

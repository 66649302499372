/*eslint-disable*/
import React, {useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/sampoorna/Bhedaghat-marble-rocks-jabalpur.jpg";
import image2 from "assets/img/tourPackage/sampoorna/Kandariya-Mahadev-Temple-khajuraho.jpg";
import image3 from "assets/img/tourPackage/sampoorna/Sanchi1_N-MP-220-1024x685.jpg";
import image4 from "assets/img/tourPackage/sampoorna/Maheshwar_Fort_-_Exterior_01-1024x706.jpg";
import image5 from "assets/img/tourPackage/sampoorna/Royal_Bengal_Tiger_Kanha-1024x669.jpg";
import River from "assets/img/tourPackage/sampoorna/Mp-MH-package.jpg";
import Jungle from "assets/img/tourPackage/sampoorna/Homepage-Destinations-image-size-370_370-Pachmarhi.png";
import mapImage from "assets/img/tourPackage/sampoorna/madhya-pradesh-map.jpg";

import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyCard = makeStyles(styles);

export default function Sampoorna() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const classCard = useStyCard();

  const d = Intl.DateTimeFormat("en-GB", {
    // day: "numeric",
    month: "short",
    // year: "numeric",
  }).format(new Date()).replaceAll('/', '-');

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const scrollBar = {
    height: "230px",
    overflowX: "hidden",
    overflowY: "scroll"
  };

  useEffect(() => {
    console.log(d)
  }, []);

  return (
    <div>
      <Helmet>
        <title>Sampoorna Madhya Pradesh Group Tour Package</title>
        <meta
          name="keywords"
          content="Sampoorna Madhya Pradesh Group Tour Package group tour, MP, exploremp-14-days-best of madhya-pradesh-tour-itinerary, group departure, full madhya pradesh-senior citizen, ladies, jain community outing, kitchen trip, mini madhya pradesh, Destinations Covered, Gwalior,
          Khajuraho, Bandhavgarh, Jabalpur, Pachmarhi, Bhopal, Ujjain, Maheshwar, IndorePlaces coverd : Gwalior,  Orchha, Khajuraho, Bandhavgarh, Bhedaghat, Pachmarhi, Bhimbetka, Bhopal, Sanchi, Ujjain, Omkareshwar, Maheshwar, Mandu, Indore, Sampurna Madhya Pradesh   fixed group Departure for January, February and March.
          With discounted offer Places coverd : Gwalior,  Orchha, Khajuraho, Bandhavgarh, Bhedaghat, Pachmarhi, Bhimbetka, Bhopal, Sanchi, Ujjain, Omkareshwar, Maheshwar, Mandu, Indore.
          Sampurna MP covers 2 Jyotirling, 3 World Heritage site, National park, Hill station, Dhuadhar falls
          Explore MP LLP offers Sampoorna Madhya Pradesh or Mini Madhya Pradesh with all most visited places and offbeat destinations and with the best price. "
        />
        <meta
          name="description"
          content="Sampoorna Madhya Pradesh Group Tour Package group tour/MP/exploremp-14-days-best of madhya-pradesh-tour-itinerary/group departure- full madhya pradesh-senior citizen -ladies -jain community outing-kitchen trip/mini madhya pradesh - Destinations Covered, Gwalior,
          Khajuraho, Bandhavgarh, Jabalpur, Pachmarhi, Bhopal, Ujjain, Maheshwar, Indore, Sampurna Madhya Pradesh   fixed group Departure for January, February and March.
          With discounted offer Places coverd : Gwalior,  Orchha, Khajuraho, Bandhavgarh, Bhedaghat, Pachmarhi, Bhimbetka, Bhopal, Sanchi, Ujjain, Omkareshwar, Maheshwar, Mandu, Indore.
          Sampurna MP covers 2 Jyotirling, 3 World Heritage site, National park, Hill station, Dhuadhar falls
          Explore MP LLP offers Sampoorna Madhya Pradesh or Mini Madhya Pradesh with all most visited places and offbeat destinations and with the best price."
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        // fixed
        // color="transparent"
        color="info"
        // changeColorOnScroll={{
        //   height: 300,
        //   color: "info",
        // }}
      />

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginTop: "10px" }}
      >
        <div
          className={classes.container}
          style={{
            padding: "40px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              {/* <h3>Photos</h3> */}
              <Card>
                <Carousel {...settings}>
                  <div>
                    <img
                      src={image1}
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        Magnificent Bheda Ghat
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image2}
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        Artistic Khajuraho
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image3}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        Ancient Sanchi Stupa
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image4}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        Historic Maheshwar Fort
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image5}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        Famous Bhadhavgarh National Park
                      </h2>
                    </div>
                  </div>
                </Carousel>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <h3 style={{ color: "red", textAlign: "center" }}>
                  MP SAMPOORNA MADHYA PRADESH
                </h3>
                <hr />
                <div className="slick-caption" style={{ textAlign: "center" }}>
                  <h5>
                    <LocationOn
                      style={{ color: "#FF0000" }}
                      className="slick-icons"
                    />{" "}
                    13N/14D
                  </h5>
                </div>
                <GridContainer justify="center">
                  <GridItem md={12}>
                    <NavPills
                      color="info"
                      tabs={[
                        {
                          tabButton: "Feb",
                          tabContent: (
                            <span>
                              <GridContainer
                                className={classNames(
                                  classes.mlAuto,
                                  classes.mrAuto
                                )}
                              >
                                <GridItem md={12} xs={12} sm={12}>
                                  <Card>
                                    <CardHeader color="info" icon>
                                      <h6 className={classes.cardIconTitle}>
                                        Departure Dates & Price Per Person
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {/* <marquee behavior="scroll" direction="up" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"> */}
                                      <GridContainer style={scrollBar}>
                                        <GridItem md={12} sm={12} xs={12}>
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>13N/14D</p>

                                              <p>03 Feb 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 54,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                          <hr />
                                        </GridItem>
                                      </GridContainer>
                                      {/* </marquee> */}
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </span>
                          ),
                        },
                        {
                          tabButton: "Mar",
                          tabContent: (
                            <span>
                              <GridContainer
                                className={classNames(
                                  classes.mlAuto,
                                  classes.mrAuto
                                )}
                              >
                                <GridItem md={12} xs={12} sm={12}>
                                  <Card>
                                    <CardHeader color="info" icon>
                                      <h6 className={classes.cardIconTitle}>
                                        Departure Dates & Price Per Person
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {/* <marquee behavior="scroll" direction="up" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"> */}
                                      <GridContainer style={scrollBar}>
                                        <GridItem md={12} sm={12} xs={12}>
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>13N/14D</p>
                                              <p>18 Mar 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 54,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                          <hr />
                                        </GridItem>
                                      </GridContainer>
                                      {/* </marquee> */}
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </span>
                          ),
                        },
                        {
                          tabButton: "April",
                          tabContent: (
                            <span>
                              <GridContainer
                                className={classNames(
                                  classes.mlAuto,
                                  classes.mrAuto
                                )}
                              >
                                <GridItem md={12} xs={12} sm={12}>
                                  <Card>
                                    <CardHeader color="info" icon>
                                      <h6 className={classes.cardIconTitle}>
                                        Departure Dates & Price Per Person
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {/* <marquee behavior="scroll" direction="up" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"> */}
                                      <GridContainer style={scrollBar}>
                                        <GridItem md={12} sm={12} xs={12}>
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>13N/14D</p>
                                              <p>14 April 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 54,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                          <hr />
                                        </GridItem>
                                      </GridContainer>
                                      {/* </marquee> */}
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </span>
                          ),
                        }
                      ]}
                    />
                    <h6
                      className={classNames(classes.mlAuto, classes.mrAuto)}
                      style={{ textAlign: "center" }}
                    >
                      The above Discounted Tour Price is as of now, will change
                      as the seats fill.The Discounted price is inclusive of
                      INR. Taxes Extra Terms and Conditions apply
                    </h6>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Sampoorna Madhya Prades Group Tour Packages</h3>
                            <GridContainer>
                              <GridItem md={6} sm={12}>
                                <BsStopwatch color="red" />
                                &nbsp;13 Nights 14 Days
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              {/* <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 12+
                              </GridItem> */}
                              <GridItem md={6} sm={12}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Airport
                              </GridItem>
                            </GridContainer>
                            <h4>
                              A 14-days sampoorna package tour that will take
                              you on a journey unforgettable! Take a trip down
                              historical, pilgrimage and awe-inspiring sites
                              across Madhya Pradesh. Take the trip of lifetime
                              and immerse into the culture with zeal!
                              <br />
                              <br />
                              Destinations Covered - Gwalior - Khajuraho -
                              Bandhavgarh - Jabalpur - Pachmarhi - Bhopal -
                              Ujjain - Maheshwar - Indore
                              <br />
                              Price Per Person - Rs 54,999/-
                              <br />
                              Extra Person - Rs 51,999/-
                              <br />
                            </h4>
                            <h4>Inclusions-</h4>
                            <h4>
                              Comfortable Stay on Dbl/ Triple Sharing Basis.
                              Daily Breakfast + Lunch + Evening Tea/coffee +
                              Dinner Comfortable and Well Sanitized Vehicle From
                              Pick Up Till Drop. Small Vehicles Wherever Needed
                              as Per Itinerary.
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Gwalior / Indore</span>
                                <br />
                                <span>As per schedule</span>
                                {/* <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span> */}
                                {/* <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span> */}
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              A tour unlike no other! Take the trip from
                              Historical sites to UNESCO heritage destinations.
                              With the perfect balance of leisure, luxury and
                              adventure! Visit the best tourist spots Madhya
                              Pradesh has to offer!
                            </h4>
                            {/* <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;<span>Tansen’s Tomb,</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Saas Bahu Mandir</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Kaanch Mandir</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Jatashankar And Bee Fall</span>
                              </GridItem>
                            </GridContainer> */}
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Mumbai to Gwalior",
                                  content:
                                    "Arrival at Gwalior airport /station. Meet and greet our representative. Check in hotel. This day visit to sun temple, Tansen tomb, Saas bahu mandir and Teli ka mandir. Breakfast+lunch+dinner as per schedule. Overnight stay in Gwalior.",
                                },
                                {
                                  title: "Day 2 Gwalior Sight-seeing",
                                  content:
                                    "This day visit jai vilas palace and Gwalior fort. Sel show. Evening visit to the local market. Lunch + dinner.",
                                },
                                {
                                  title: "Day 3 Gwalior to Khajuraho.",
                                  content:
                                    "Early wakeup and depart for Khajuraho via Jhansi. Reach Khajuraho by evening. Evening Visit the local markets. Breakfast+lunch+dinner as per schedule. Overnight stay in Khajuraho.",
                                },
                                {
                                  title: "Day 4 Khajuraho Sight-seeing",
                                  content:
                                    "This day visit to the western group of temples and eastern group of temples, Chaturbhuj temple, Laxmana temple and Matangeshwar temple. Breakfast+lunch+dinner as per schedule. Overnight stay in Khajuraho.",
                                },
                                {
                                  title: "Day 5 Khajuraho to Bandhavgarh",
                                  content:
                                    "This day depart for Bandhavgarh, the land of real Bengal tigers. Reach Bandhavgarh by evening. Refreshment and a small documentary show on the habitat of Bandhavgarh jungle. Breakfast +lunch+dinner as per schedule. Overnight stay in Bandhavgarh.",
                                },
                                {
                                  title: "Day 6 Bandhavgarh to Jabalpur",
                                  content:
                                    "This day get early wakeup call and enjoy the jungle safari. Back to hotel. After lunch depart for Jabalpur city. Evening at leisure. Breakfast+lunch+dinner as per schedule. Overnight stay in Jabalpur.",
                                },
                                {
                                  title: "Day 7 Jabalpur to Pachmarhi",
                                  content:
                                    "Depart for Pachmarhi via Bhedghat. Areach Pachmarhi by late evening. Check in hotel. Relax and keep the time at leisure. Breakfast +lunch+dinner as per schedule. Overnight stay in Pachmarhi.",
                                },
                                {
                                  title:
                                    "Day 8 Pachmarhi Full Day Sight-seeing",
                                  content:
                                    "Start your day with a morning walk to and enjoy bird watching session. After refreshment visit to Mahadeo, Gupt Mahadeo, Jatashankar and bee fall. Breakfast+lunch+dinner as per schedule. Ovenight stay in Pachmarhi.",
                                },
                                {
                                  title: "Day 9 Pachmarhi to Bhopal",
                                  content:
                                    "Early morning depart for Bhopal, the city of lakes. Enroute visit to Bhimbetka caves. Reach Bhopal. Check in hotel. Visit to old masjid and boat club of Bhopal. Breakfast+lunch+dinner as per schedule. Overnight stay in Bhopal.",
                                },
                                {
                                  title: "Day 10 Bhopal to Ujjain",
                                  content:
                                    "Depart for Ujjain city, the city of temples. Reach Ujjain by evening. Visit to the Mahakal temple, Kal bhairav mandir and Sandipani ashram. Breakfast+lunch+dinner as per schedule. Overnight stay in Ujjain.",
                                },
                                {
                                  title: "Day 11 Ujjain to Maheshwar via Mandu",
                                  content:
                                    "Depart for Maheshwar city via Mandu and visit the famous Jahaz mahal, Baaz bahadur palace and Rani Roopmati palace. After Mandu sight-seeing proceed to Mahshwar. Evening time for shopping Maheshwar sarees. Breakfast+lunch+dinner as per schedule. Overnight stay in Maheshwar.",
                                },
                                {
                                  title: "Day 12 Maheshwar to Indore",
                                  content:
                                    "Early depart for Indore city. Enroute visit to Omkareshwar jyotirlinga. Visit to Mandhata temple and Mamleshwar temple. After darshan proceed for Indore. Visit to the Khajrana temple. Breakfast + lunch+dinner as per schedule. Overnight stay in Indore",
                                },
                                {
                                  title: "Day 13 Indore City Sight-seeing.",
                                  content:
                                    "This day visit to the Rajwada, Lalbagh palace, Bada Ganesh mandir. Evening at Sarafa bazaar and cloth market. Breakfast + lunch+dinner as per schedule. Overnight stay In Indore.",
                                },
                                {
                                  title: "Day 14 Tour Ends.",
                                  content:
                                    "Relax your self and enjoy the beautiful morning. Give time for your self. Check out as per hotel check out timings. Pack you beautiful memories and get a drop at nearest railhead / airport.",
                                },
                              ]}
                            />
                            <h4>Things to Be Noted-</h4>
                            <h4>THE ABOVE PRICE EXCLUDES</h4>
                            <ul>
                              <li>
                                Govt Tax of 5% over and above the Tour Cost
                                mentioned
                              </li>
                              <li>To & Fro Air & Rail Ticket</li>
                              <li>Cost of Insurance</li>
                              <li>Cost of Pre/Post tour hotel accommodation</li>
                              <li>
                                Any extra expense such as route change, Airline
                                change, Date change, Accommodation facilities,
                                etc incurred due to the unforeseen, unavoidable
                                forced majeure circumstances during the tour
                              </li>
                              <li>
                                Porterage (coolie charges), laundry, telephone
                                charges, shopping, wines and alcoholic
                                beverages, mineral water, items of personal
                                nature and food or drink which is not part of a
                                set group menu
                              </li>
                              <li>
                                Any extra cost incurred on behalf of an
                                individual due to illness, accident,
                                hospitalization, or any personal emergency
                              </li>
                              <li>
                                Any services or activity charges other than
                                those included in the group tour itinerary
                              </li>
                              <li>
                                Anything specifically not mentioned in the ‘tour
                                price includes’ column.
                              </li>
                            </ul>
                            <h4>Important Guide Lines For The Tour:</h4>
                            <ul>
                              <li>
                                Room Heater available with an extra cost borne
                                by the guest directly.
                              </li>
                              <li>
                                Hotels are very strict with the child policy.
                                Please carry the age proof so that it can be
                                produced if asked by hotel.
                              </li>
                              <li>
                                A valid photo ID proof for all guests staying at
                                the hotel is mandatory (compulsory).
                              </li>
                              <li>
                                For Extra adult in the room we will provide an
                                extra bed (wherever possible), but most of the
                                hotels only provide extra mattress or roll out
                                bed. Most of the hotels have no provision of an
                                extra bed.
                              </li>
                              <li>
                                In the area (almost all) the hotels do not have
                                a lift facility and in all the hotels the guest
                                will have to climb stairs. In case if any guest
                                is having problem of blood pressure, knee or
                                other ailment for which they cannot climb the
                                higher floors they will have to intimate us
                                during the time of booking so that we can
                                arrange rooms at the lowest floor of the hotel
                                enabling the guest to climb the minimum number
                                of stairs possible.
                              </li>
                              <li>
                                All the meals will be provided at the hotel
                                dining rooms as per their timings, the food will
                                be provided as per their meal plan
                              </li>
                              <li>
                                Please ensure that you do not leave your
                                belongings in the vehicle or in hotel rooms as
                                there is very little possibility of getting them
                                back. Always check your baggage before you leave
                                the vehicle or hotel room.
                              </li>
                              <li>
                                If guests want any changes in their sightseeing
                                schedule they should be informed to our
                                executive previous day.
                              </li>
                              <li>
                                The guest should always keep cool with the
                                drivers as they are not tourism educated and
                                come from different remote villages.
                              </li>
                              <li>
                                We would appreciate, if the guest does not lend
                                their ears to the drivers as most of the time
                                they misguide the passengers. In such instances
                                we would request them to contact our executive.
                              </li>
                              <li>
                                Operator reserves the right to re-arrange
                                itinerary to suit hotel availability without
                                changing the total number of days in each
                                destination and without compromising any
                                services.
                              </li>
                            </ul>
                            <h4>
                              Explore MP LLP is no circumstanced be liable
                              for-any death, Personal Injury, Sickness,
                              accident, loss, delay, discomfort, increase
                              expense consequential loss or damage or theft the
                              temporary or permanent loss or damage to baggage
                              or personal effects however caused. The
                              organization reserves the right to claim any
                              additional amount of money for expenses due to
                              delay in train, bus, air or any other weather
                              conditions, strikes, war quarantine or any other
                              cause. All the loss or expenses will have to borne
                              by the passenger.
                            </h4>
                            <h4>Terms & Conditions:</h4>
                            <ul>
                              <li>
                                At the time of the booking every person will
                                have to pay 50% amount in advance, and the
                                remaining payment should be made 20 days before
                                the departure date of the tour, And if the
                                booking is made 20 days before tour departure
                                full payment shall be made in
                              </li>
                              <li>
                                Payment can be made by cash, cheque or demand
                                draft, Nat Banking.
                              </li>
                              <li>
                                Air Tickets Issued for this tour are Non
                                Refundable.
                              </li>
                              <li>
                                Sightseeing and Traveling by 2×2 Luxury Coach
                                (in case there are less tourists, then the
                                sightseeing and traveling can be arranged by
                                Sumo, Tempo Traveler, Minibus) hotel stay on
                                twin sharing basis or family basis, meal
                                (breakfast, lunch, Dinner)
                              </li>
                              <li>
                                Seating arrangement in Bus / Coach are
                                allocation rotation daily
                              </li>
                              <li>
                                Travel arrangement to and from the airport to
                                hotel is at customers own cost for other than
                                certain destinations specially mentioned in tour
                              </li>
                              <li>
                                There are pre-set menus for meal provided on
                                tour. In Train /Air we do not provide the f We
                                however reserve the right to change the meal
                                arrangements if circumstances make it necessary
                                for us to do so. In the event of the tour
                                participant missing any meal due to any reason,
                                then no claim can be made for the meal he has
                                missed and not utilized.
                              </li>
                              <li>
                                The company reserves the right to alter,
                                postpone or cancel any of the tour advertised in
                                the brochure, without assigning any reason.
                                whatsoever. Under circumstances of cancellation
                                of any tour by the company, money paid by the
                                tour participant will be fully refunded, but no
                                compensation is payable.
                              </li>
                              <li>
                                The organizer or tour manager has sole authority
                                to make changes in the program
                              </li>
                              <li>
                                In most Hotels extra Bed provided constitutes
                                the mattress with bed sheet, blanket and pillow.
                              </li>
                              <li>
                                If the tour participant misbehaves in a manner
                                causing inconvenience or annoyance to other tour
                                participants or causes damage to the property of
                                the company he / she will be asked to leave the
                                tour immediately and the tour escorts have been
                                authorized to do so. There will not be any
                                compensation whatsoever in such
                              </li>
                              <li>
                                Cancellation Policy:-
                                <br />* 60 days before the tour departure 15%
                                deduction from the tour cost.
                                <br />* 59 to 51 days before the tour departure
                                30% deduction from the tour cost.
                                <br />* 50 to 31 days before the tour departure
                                40% deduction from the tour cost.
                                <br />* 30 to 11 days before the tour departure
                                50% deduction from the tour cost.
                                <br />* 10 days before the tour departure no
                                refund
                              </li>
                              <li>
                                10 days before the tour departure no refund
                              </li>
                              <li>
                                The tour organization in no circumstances be
                                liable for:-
                              </li>
                              <li>
                                Company reserves the right to publish the
                                passengers photograph or group photograph
                                without intimation or permission of the same
                                passenger or group.
                              </li>
                              <li>
                                There can be in increase in the tour cost in
                                case of hike in diesel or petrol.
                              </li>
                              <li>
                                Any disputes arising in respect of the tour
                                shall be subject to Mumbai Jurisdiction
                              </li>
                              <li>
                                It is understood that tour participants
                                traveling with Explore MP LLP have read and
                                accepted the above terms & conditions.
                              </li>
                            </ul>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <CardHeader image plain>
                                  <img src={mapImage} alt="Map" />
                                </CardHeader>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                  // {
                  //   tabButton: "FAQ",
                  //   tabContent: (
                  //     <span>
                  //       <GridContainer
                  //         className={classNames(classes.mlAuto, classes.mrAuto)}
                  //       >
                  //         <GridItem md={12}>
                  //           <h3>FAQ</h3>
                  //           <Accordion
                  //             active={0}
                  //             activeColor="info"
                  //             collapses={[
                  //               {
                  //                 title: "Best time to visit Madhya Pradesh",
                  //                 content:
                  //                   "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                  //               },
                  //               {
                  //                 title: "Services offered?",
                  //                 content:
                  //                   "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                  //               },
                  //               {
                  //                 title: "Which tour is best for me?",
                  //                 content:
                  //                   "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                  //               },
                  //               {
                  //                 title: "Do you provide flight bookings?",
                  //                 content:
                  //                   "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                  //               },
                  //               {
                  //                 title:
                  //                   "Who can I contact in case of any problems during my tour/ stay?",
                  //                 content:
                  //                   "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                  //               },
                  //             ]}
                  //           />
                  //         </GridItem>
                  //       </GridContainer>
                  //     </span>
                  //   ),
                  // },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={River} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${River})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        MH-MP (Jyotirlinga) Special Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Jungle} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Popular Deals
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Jungle})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Satpuras Queen
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/royalMadhyaPradesh/Baz_Bahadur_Palace_-_Inside.jpg";
import image2 from "assets/img/tourPackage/royalMadhyaPradesh/images-2.jpg";
import image3 from "assets/img/tourPackage/royalMadhyaPradesh/Jama_Masjid_-_In_the_Noon.jpg";
import image4 from "assets/img/tourPackage/royalMadhyaPradesh/rani-roopmati-mahal.jpg";
import image5 from "assets/img/tourPackage/royalMadhyaPradesh/Shri_Mahakaleshwar_Temple_Ujjain_-_panoramio_4.jpg";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function RoyalMadhyaPradesh() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Royal Madhya Pradesh</title>
        <meta name="keywords" content="Royal madhya pradesh tour plan, mp packages, group ladies, senior citizen tour, unesco world heritage site, khajuraho sight, seeing package, khajuraho temples, khajuraho tour packages, light-n-sound show, guided tours, Gwalior places to visit, Jai vilas palace, Gwalior fort" />
        <meta name="description" content="madhya-pradesh-tour-plan/mp packages/ group -ladies-senior citizen tour/unesco world heritage site/khajuraho sight-seeing package/ khajuraho temples/ khajuraho tour packages/light-n-sound show/guided tours-Gwalior places to visit- Jai vilas palace- Gwalior fort" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/tourPackage/royalMadhyaPradesh/Royal-MP.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Royal Madhya Pradesh</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Royal Madhya Pradesh</h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;5N/6D
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 12+
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Airport
                              </GridItem>
                            </GridContainer>
                            <h4>
                              A royal tour of a royally beautiful place! Spend 6
                              days exploring and immersing in the culture of
                              Madhya Pradesh!
                              <br />
                              <br />
                              Destinations Covered- Gwalior- Jhansi-Orchha-
                              Khajuraho
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Gwalior & Khajuraho</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Nagpur & Pachmarhi</span>
                                <br />
                                <span>As per schedule</span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span>
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              A royal tour, unlike any other, explore the royal
                              heritage of Madhya Pradesh with this package. From
                              the famous Gwalior palace to the beauty of
                              Khajuraho temples. A tour that will take you on a
                              historic and blessed journey!
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;<span>Beautiful Gwalior Palaces</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Pilgrimage tours, Jain Temples</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Explore Khajuraho Temples</span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "PHOTOS",
                    tabContent: (
                      <span>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <h3>Photos</h3>
                            <Card>
                              <Carousel {...settings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="First slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Second slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Somewhere Beyond, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Gwalior",
                                  content:
                                    "Gwalior was once not only the capital of Scindias but is also a historically rich and one of the most popular cities of Madhya Pradesh. On arrival, meet our representative and proceed to the hotel. After check in and some refreshments, today you will visit the famous Gwalior fort , jai vilas palace, sun temple and Tansen tomb. Evening, attend the light-n-show at Gwalior fort (optional for those who are interested). Dinner and night stay in Gwalior.",
                                },
                                {
                                  title: "Day 2 Gwalior City Tour",
                                  content:
                                    "This particular day, you have two options to visit either the summer house of Scindias I.E., Shivpuri, or the famous Datia/ Sonagiri Jain temples. By evening, back to Gwalior and night stay in Gwalior.",
                                },
                                {
                                  title:
                                    "Day 3 Gwalior – Orchha via Jhansi (130 Kms/3 Hrs Drive)",
                                  content:
                                    "Breakfast and depart for Orchha. Orchha city well known by the territory of raja ram, the only place where lord Rama is seen and saluted as a king. Check in hotel and you will visit ram raja temple, Chaturbhuj temple, Jahangir mahal, Laxmi Narayan temple, raja mahal, chhatris and if interested can enjoy river rafting in the Betwa river. Evening, attend the sel show (sound and light show). Dinner and day ends in Orchha.",
                                },
                                {
                                  title:
                                    "Day 4 Orchha – Khajuraho (180 Kms/5 Hrs Drive)",
                                  content:
                                    "Breakfast and depart for the world famous temple of Khajuraho. Reach Khajuraho and check in hotel. Keep evening at leisure and visit the markets of Khajuraho. Evening, sel show or just relax. Dinner and day ends in Khajuraho.",
                                },
                                {
                                  title: "Day 5 Khajuraho City Tour",
                                  content:
                                    "Today, after breakfast, you will be visiting the western, eastern and southern group of temples. Chaturbhuj temple, Laxmana temple, Kandariya Mahadev temple, brahma temple, Adivart tribal and folk art museum and Matangeshwar temple. Dinner and day ends in Khajuraho.",
                                },
                                {
                                  title: "Day 6 Tour ends",
                                  content:
                                    "Breakfast. Check out for the day. Drive to the nearest rail head or airport for further journey.",
                                },
                              ]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "VEHICLE USED",
                                "PER PERSON COST",
                                "STANDARD PKG",
                                "DELUXE PKG",
                                "PREMIUM PKG",
                              ]}
                              tableData={[
                                [
                                  "AC SEDAN",
                                  "2 PAX",
                                  "INR 17,976",
                                  "INR 20,277",
                                  "INR 23,219",
                                ],
                                [
                                  "AC SEDAN",
                                  "4 PAX",
                                  "INR 13,161",
                                  "INR 15,462",
                                  "INR 18,404",
                                ],
                                [
                                  "AC INNOVA",
                                  "6 PAX",
                                  "INR 12,091",
                                  "INR 14,392",
                                  "INR 17,334",
                                ],
                                [
                                  "EXTRA PERSON",
                                  "ABOVE 5-10 YEARS",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                ],
                                [
                                  "",
                                  "ABOVE 5 YEARS",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "CITY",
                                "STANDARD PKG HOTELS",
                                "DELUXE PKG HOTELS",
                                "PREMIUM PKG HOTELS",
                              ]}
                              tableData={[
                                [
                                  "GWALIOR",
                                  "HOTEL BELLEVUE",
                                  "HOTEL AMBIENCE / SIMILAR",
                                  "CLARKS INN SUITES / SIMILAR",
                                ],
                                [
                                  "ORCHHA",
                                  "HOTEL SHEESH MAHAL / SIMILAR",
                                  "RAJ MAHAL / SIMILAR",
                                  "AMAR MAHAL / SIMILAR",
                                ],
                                [
                                  "KHAJURAHO",
                                  "HOTEL ISABEL PALACE / SIMILAR",
                                  "MINT RESORT/ SIMILAR",
                                  "SYNA HERITAGE (DELUXE)",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>THE ABOVE PRICE INCLUDES</h4>
                            <ul>
                              <li>Accommodation on twin sharing basis.</li>
                              <li>
                                Map meal plan at all places except ap in jungle.
                              </li>
                              <li>
                                Transfers and sight-seeing as per no. Of persons
                                mentioned above.
                              </li>
                              <li>
                                (Note: ac will be closed while on hills/ghats).
                              </li>
                            </ul>
                            <h4>THE ABOVE PRICE EXCLUDES:</h4>
                            <ul>
                              <li>Any airfare /rail fare.</li>
                              <li>5% GST.</li>
                              <li>
                                Jungle safaris extra at rs 5500/- per round
                                (accommodates 6 persons).
                              </li>
                              <li>Open gypsy at Pachmarhi.</li>
                              <li>
                                Any en-route meals or other meal not mentioned
                                in price includes.
                              </li>
                              <li>
                                Any other destination not mentioned in
                                itinerary.
                              </li>
                              <li>
                                Any monument / museum entry fees, camera fees
                                and expenses of personal nature.
                              </li>
                            </ul>
                            <h4>THINGS TO NOTEDS</h4>
                            <ul>
                              <li>
                                Rooms are subject to availability. We do not
                                hold any rooms.
                              </li>
                              <li>
                                Confirmation of rooms is subject to the receipt
                                of 100% advance payment within 3 working days of
                                booking.
                              </li>
                              <li>
                                {" "}
                                It is mandatory to carry your photo id’s while
                                on tour.
                              </li>
                            </ul>
                            <hr />
                            <Table
                              tableHead={["PAYMENT POLICY"]}
                              tableData={[
                                ["AT THE TIME OF THE BOOKING", "30%"],
                                ["02 MONTH BEFORE THE TOUR STARTS", "50%"],
                                ["10 DAYS BEFORE THE TOUR STARTS", "20%"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>CANCELLATION POLICY</h4>
                            <Table
                              tableHead={[
                                "CANCELLATION MODE",
                                "AMOUNT REFUND OUT OF TOTAL COST",
                              ]}
                              tableData={[
                                ["60 DAYS BEFORE TOUR", "70 % REFUND"],
                                ["45 DAYS BEFORE TOUR", "60 % REFUND"],
                                ["30 DAYS BEFORE TOUR", "50 % REFUND"],
                                ["15 DAYS BEFORE TOUR", "40 % REFUND"],
                                ["07 DAYS BEFORE TOUR", "25 % REFUND"],
                                ["LESS THAN 07 DAYS BEFORE TOUR", "0 % REFUND"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>NOTE:</h4>
                            <ul>
                              <li>
                                Above rates are net and non-commissionable
                                rates.
                              </li>
                              <li>
                                Room category in all packages is with base
                                category or as mentioned.
                              </li>
                              <li>
                                In sold out position of the hotels, we will try
                                to book other hotel of similar category.
                              </li>
                              <li>
                                Rates will change during long weekends and peak
                                dates.
                              </li>
                            </ul>
                            <h6>
                              {" "}
                              HOPE THE ABOVE IS IN LINE WITH REQUEST. FOR MORE
                              DETAILS, PLEASE FEEL FREE TO CONTACT US.
                            </h6>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                      </span>
                    ),
                  },
                  {
                    tabButton: "FAQ",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>FAQ</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Best time to visit Madhya Pradesh",
                                  content:
                                    "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                                },
                                {
                                  title: "Services offered?",
                                  content:
                                    "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                                },
                                {
                                  title: "Which tour is best for me?",
                                  content:
                                    "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                                },
                                {
                                  title: "Do you provide flight bookings?",
                                  content:
                                    "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                                },
                                {
                                  title:
                                    "Who can I contact in case of any problems during my tour/ stay?",
                                  content:
                                    "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                                },
                              ]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  //   {
                  //     tabButton: "REVIEWS",
                  //     tabContent: (
                  //       <span>

                  //       </span>
                  //     ),
                  //   },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

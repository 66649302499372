/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import PilgrimageCarousel from "views/ExplorePage/Gallery/PilgrimageCarousel"
import WildlifeCarousel from "views/ExplorePage/Gallery/WildLifeCarousel";
import CitiesCarousel from "views/ExplorePage/Gallery/CitiesCarousel";
import HeritageCarousel from "views/ExplorePage/Gallery/HeritageCarousel";
import LeisureCarousel from "views/ExplorePage/Gallery/LeisureCarousel";
import TravelCarousel from "views/ExplorePage/Gallery/TravelCarousel";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/presentation-ipad.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function ExploreGallery() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Gallery</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/gallery/Gallery.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>GALLERY</h1>
              <h4>Some views that can't be contained within pictures, but we tried.</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{
              padding: "80px 0",
            }}>
            <GridContainer justify="center">
                <GridItem md={12}>
                <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "PILGRIMAGE",
                    tabContent: (
                      <span>
                       <PilgrimageCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "WILDLIFE",
                    tabContent: (
                      <span>
                        <WildlifeCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "HERITAGE",
                    tabContent: (
                      <span>
                        <HeritageCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "CITIES",
                    tabContent: (
                      <span>
                       <CitiesCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "LEISURE",
                    tabContent: (
                      <span>
                        <LeisureCarousel />
                      </span>
                    ),
                  },
                  {
                    tabButton: "TRAVELLER'S CLICK",
                    tabContent: (
                      <span>
                        <TravelCarousel />
                      </span>
                    ),
                  },
                ]}
              />
                </GridItem>
            </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

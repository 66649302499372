/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import SectionOverview from "views/PresentationPage/Sections/SectionOverview";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/aboutUs/map.png";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function ExploreAbout() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>About Us</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/aboutUs/About-Us.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>ABOUT US</h1>
              <h4>Tour Madhya Pradesh with us</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div className={classe.imageContainer}>
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                A game-changer when it comes to travel, we give you the
                opportunity to lose yourself in the beauty of Madhya Pradesh.
                When it comes to choosing the best Explore MP should be your
                only choice! Take an enthusiastic trip down Madhya Pradesh with
                us and take home memories worth a lifetime!
              </h5>
              <h3 className={classe.title}>
                Explore MP is here to make your travel a dream!
              </h3>
              <h5 className={classe.description}>
                Visit mesmerising destinations with us and immerse yourself in
                the beautiful culture of this beautiful state! Learn more about
                temples carved into rocks, fortresses reaching the skies and
                nature that pulls you into its embrace!
              </h5>
              <h3 className={classe.title}>Book your next holiday today!</h3>
            </GridItem>
          </GridContainer>
          <div
            style={{
              textAlign: "center",
              padding: "80px 0",
            }}
          >
            <GridContainer justify="center">
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  title="Our Mission"
                  description="Make travelers fall in love with Madhya Pradesh."
                  icon={ThumbUpIcon}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  title="Our Vision"
                  description="Create an experience unlike any other!"
                  icon={ViewAgendaIcon}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <InfoArea
                  title="Values"
                  description="Making sure the history of Madhya Pradesh is reflected with grace"
                  icon={RecordVoiceOverIcon}
                  iconColor="danger"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {/* ..... */}
      <SectionOverview />
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/bandhavgarh/Bandhavgarh_ElephantFamily.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Bandhavgarh() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Bandhavgarh</title>
        <meta
          name="keywords"
          content="Bandhavgarh, Hotels in Bandhavgarh, Wildlife Tour, Bengal Tiger, White Tiger, Jungle Safari, Tiger Safari, Jungle Game Drive, Resorts in Bandhavgarh
Hotels in Umaria, Bandhavgarh documentary"
        />
        <meta
          name="description"
          content="Bandhavgarh- Hotels in Bandhavgarh- Wildlife Tour-Bengal Tiger-White Tiger-Jungle Safari- Tiger Safari -Jungle Game Drive- Resorts in Bandhavgarh
Hotels in Umaria- Bandhavgarh documentary-"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/destination/bandhavgarh/bandhavgarh.jpg").default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Bandhavgarh</h1>
              <h4>Answer the call of the wild at Bewitching Bandhavgarh!</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Located in the Umaria district of Madhya Pradesh, Bandhavgarh is
                home to the elusive Bengal tiger. The National park, declared as
                such in 1968 is currently a protected reserve. <br />
                <br />
                The number of tigers in this park are the highest known number
                in India. The park holds a large biodiversity and boasts a huge
                population of leopards, deers, Nilgais and so much more! <br />
                <br />
                It also is home to a wide range of birds like the Brown Fish
                Owl, Rufous Woodpecker, Indian Grey hornbill, Golden Oriole and
                256 more species of birds! <br />
                <br />
                Originally, the place was the hunting ground for Rewa Maharaja,
                the old fort built in the first is the standing sign of this
                activity and fort still stands inside the forest.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Tourists that visit can vouch for this that the Bandhavgarh fort
                is a must-visit spot for anyone entering the national park.
                <br />
                <br />
                The beautiful Vindhyas that surround are a sight to behold! The
                entire village, the valleys, and the beautiful meadows that
                surround this place are breathtaking.
                <br />
                <br />
                The best time to visit Bandhavgarh is in October to March period
                when the park is open.
                <br />
                <br />
                The famous Tala village and the ancient Bandhavgarh caves should
                also be on your list when visiting the area!
                <br />
                <br />
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

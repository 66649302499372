/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/ujjain/01Ujjain.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Ujjain() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Ujjain</title>
        <meta
          name="keywords"
          content="Ujjain, Ujjain City Tour, Ujjain hotels, Mahakaleshwar darshan, Bhasma aarti, Bhakt Niwas, Kal bhairav, Sandipani Ashram, Iskcon Temple Ujjain, 
Mangalnath mandir, Harsiddhi shaktipeeth, Shaktipeeth, Kshipra River"
        />
        <meta
          name="description"
          content="Ujjain - Ujjain City Tour- Ujjain hotels- Mahakaleshwar darshan- Bhasma aarti- Bhakt Niwas- Kal bhairav-Sandipani Ashram-Iskcon Temple Ujjain- 
Mangalnath mandir-Harsiddhi shaktipeeth- Shaktipeeth- Kshipra River"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/ujjain/Ujjain-1.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Ujjain</h1>
              <h4>Take a tour of unique Ujjain!</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                The ancient city of Ujjain lays on the banks of Kshipra River.
                An important Hindu Pilgrimage destination, Ujjain can be found
                in the Central Indian State of Madhya Pradesh. A city of many
                important pilgrim points, there’s multiple temples followers can
                visit.
                <br />
                <br />
                Not only popular for its temple, but Ujjain also hosts the
                world-famous Kumbh Mela every 12 years. Temples like Bade Ganesh
                Temple, Harsiddhi Temple, Mahakaleshwar Jyotirling temple are
                located here.
                <br />
                <br />
                The most prominent feature of the city is that it resides on the
                Malwa Plateau of Central India, it became the political capital
                of India around 600 BCE. Kingdoms like Avanti, a part of the
                sixteen Mahajanpadas is this city.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Ujjain remained the commercial, political and cultural centre
                all the way up to the 19th Century until the British
                administration took over. Even after years, Ujjain continues to
                be an important pilgrimage for Shaivites, Vaishnavites and
                followers of Shakta
                <br />
                <br />
                A visit to Ujjain is incomplete without taking a holy dip in the
                river and participating in the evening Aarti. Ujjain is not just
                a city of temples, it’s also a city of alleyways. A tangle of
                slender lanes snakes their way from the railway station down to
                the river bank.
                <br />
                <br />
                Don’t forget to try delicious foods including Pani puri,
                Bhelpuri, vada pav, kachori, jalebi, samosa, Poha, masala
                Bhutta, and so much more!
                <br />
                <br />
                Exploration of the local markets will also reveal unique little
                trinkets and art like Batik!
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/gallery/leisure/Hanuwantiya/Boat-Club.jpg"
import image2 from "assets/img/gallery/leisure/Hanuwantiya/Boriyamal-Island.jpg"
import image3 from "assets/img/gallery/leisure/Hanuwantiya/Header-17.jpg"
import image4 from "assets/img/gallery/leisure/Hanuwantiya/House-Boat.jpg"
import image5 from "assets/img/gallery/leisure/Hanuwantiya/Jal-Mahotsav.jpg"
import image6 from "assets/img/gallery/leisure/Hanuwantiya/Rustic-charm-topped-with-scopes-for-adventure.jpg"
import image7 from "assets/img/gallery/leisure/Hanuwantiya/The-adjoining-villages.jpg"

import image8 from "assets/img/gallery/leisure/Pachmarhi/Bison-lodge.jpg"
import image9 from "assets/img/gallery/leisure/Pachmarhi/Chauragh-Temple.jpg"
import image10 from "assets/img/gallery/leisure/Pachmarhi/Churches.jpg"
import image11 from "assets/img/gallery/leisure/Pachmarhi/Dhoopgarh.jpg"
import image12 from "assets/img/gallery/leisure/Pachmarhi/Handi-Kho.jpg"
import image13 from "assets/img/gallery/leisure/Pachmarhi/header-18.jpg"
import image14 from "assets/img/gallery/leisure/Pachmarhi/Jata-Shankar.jpg"
import image15 from "assets/img/gallery/leisure/Pachmarhi/Monsoon-in-Pachmarhi.jpg"

import image16 from "assets/img/gallery/leisure/Parsili/barefoot-trek.jpg"
import image17 from "assets/img/gallery/leisure/Parsili/sanjya-dubri-National-Park.jpg"

import image18 from "assets/img/gallery/leisure/Tamia/Header-19.jpg"
import image19 from "assets/img/gallery/leisure/Tamia/patalkot.jpg"
import image20 from "assets/img/gallery/leisure/Tamia/tribal-Museum.jpg"

const useStyles = makeStyles(carouselStyle);

export default function LeisureCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image7} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Hanuwantiya
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image10} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image11} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image12} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image13} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image14} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image15} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Pachmarhi
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image16} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Parsili
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image17} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Parsili
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image18} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Tamia
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image19} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Tamia
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image20} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Tamia
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import Mandir from "assets/img/tourPackage/shortTrip/tadobaShort/birla-mandir.jpg";
import Lake from "assets/img/tourPackage/shortTrip/tadobaShort/Lower_lake_Bhopal.jpg";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function TadobaShort() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Tadoba Short</title>
        <meta
          name="keywords"
          content="Tadoba Trails
Tadoba Andhari Tiger Reserve, Kolara Gate, Moharli Gate, Sirkheda, Belara, Madnapur, Alijanza, Homestay in Tadoba, Tadoba Jungle Safari, 
Wildlife Safari, Umred Karandla, Vanya Vilas Tadoba, Chimur Tiger Resort, Taru Van ,7 Tigers Resort, 
Jharna Tiger Resort, Navegaon, Jungle Camp Tadoba, Pugmarks "
        />
        <meta
          name="description"
          content="Tadoba Trails
Tadoba Andhari Tiger Reserve- Kolara Gate- Moharli Gate- Sirkheda- Belara- Madnapur- Alijanza- Homestay in Tadoba- Tadoba Jungle Safari- 
Wildlife Safari- Umred Karandla- Vanya Vilas Tadoba- Chimur Tiger Resort- Taru Van -7 Tigers Resort- 
Jharna Tiger Resort- Navegaon - Jungle Camp Tadoba- Pugmarks "
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/tourPackage/shortTrip/tadobaShort/Tigress_MAYA_with_her_cubs_at_TADOBA.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Tadoba Short</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>TADOBA TRAILS – 3N/4D</h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;4 Days 3 Nights
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;Stay on DBL/TRPL sharing basis
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Min People : 6
                                <br />
                                <ImUser color="red" />
                                &nbsp;Sight-seeing in Open Gypsy
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal : MAP
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Transfers from: Nagpur to Tadoba
                              </GridItem>
                            </GridContainer>
                            <br />
                            <br />
                            <ul>
                              <li>No of persons: 06 (minimum)</li>
                              <li>
                                Stay in AC rooms on DBL /TRPL sharing basis.
                              </li>
                              <li>Meals: AP</li>
                              <li>AC Transfers from Nagpur to Tadoba</li>
                              <li>Jungle Safari in Open Gypsy.</li>
                              <li>Per Person cost: RS 12,999/-</li>
                            </ul>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Accommodation</span>
                                <br />
                                <span>Meals</span>
                                <br />
                                <span>Sight seeing</span>
                                <br />
                                <span>Cost Per Person</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Nagpur to Tadoba</span>
                                <br />
                                <span>Stay on DBL/TRPL sharing basis</span>
                                <br />
                                <span>MAP</span>
                                <br />
                                <span>In Open Gypsy</span>
                                <br />
                                <span>RS 13,999/-</span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title:
                                    "Day 1 Arrival at Nagpur Railway Station and Transfer to Tadoba (Approx. 100 kms / 1½ hrs drive)",
                                  content:
                                    "Arrival at Nagpur Railway Station and transfer to Tadoba Tiger Reserve. Check in to your hotel / resort on arrival. Day at leisure. Evening a small documentary show On the habitats of Tadoba. Overnight stay in tadoba.",
                                },
                                {
                                  title: "Day 2 Tadoba Sightseeing",
                                  content:
                                    "This day enjoy Morning and Afternoon Jungle Safari.  Evening at leisure. Overnight stay in Tadoba.",
                                },
                                {
                                  title: "Day 3 Tadoba Sightseeing",
                                  content:
                                    "After breakfast a small trek to nearest jungle and enjoy bird watching session. Lunch and after lunch afternoon Jungle Safari. Overnight stay in Tadoba.",
                                },
                                {
                                  title: "Day 4 Departure from Nagpur",
                                  content:
                                    "The departure will be after breakfast. get transfers till Nagpur station/ airport.",
                                },
                              ]}
                            />
                            <h4>Things to Be Noted:</h4>
                            <ul>
                              <li>
                                Rates mentioned are net and non-commissionable.
                              </li>
                              <li>5% gst will be extra.</li>
                              <li>
                                Any monument, museums and camera fees will be
                                extra.
                              </li>
                              <li>Travel insurance not included.</li>
                              <li>
                                Rooms and safaris are subject to availability at
                                the time of booking.
                              </li>
                            </ul>
                            <hr />
                            <Table
                              tableHead={["PAYMENT POLICY"]}
                              tableData={[
                                ["AT THE TIME OF THE BOOKING", "30%"],
                                ["02 MONTH BEFORE THE TOUR STARTS", "50%"],
                                ["10 DAYS BEFORE THE TOUR STARTS", "20%"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>CANCELLATION POLICY</h4>
                            <Table
                              tableHead={[
                                "CANCELLATION MODE",
                                "AMOUNT REFUND OUT OF TOTAL COST",
                              ]}
                              tableData={[
                                ["60 DAYS BEFORE TOUR", "70 % REFUND"],
                                ["45 DAYS BEFORE TOUR", "60 % REFUND"],
                                ["30 DAYS BEFORE TOUR", "50 % REFUND"],
                                ["15 DAYS BEFORE TOUR", "40 % REFUND"],
                                ["07 DAYS BEFORE TOUR", "25 % REFUND"],
                                ["LESS THAN 07 DAYS BEFORE TOUR", "0 % REFUND"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Mandir} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Mandir})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Bhopal – Pachmarhi – 3N/4D
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Lake} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Lake})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Bhopal- Madhai 3N/4D
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import TrendingUp from "@material-ui/icons/TrendingUp";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import Danger from "components/Typography/Danger.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { RiStarHalfFill, RiStarFill } from "react-icons/ri";
// import { MdOutlineWatchLater } from "react-icons/md";

import blog6 from "assets/img/landingPageImage/Mp-MH-package.jpg";
import blog8 from "assets/img/landingPageImage/Homepage-packages-image-size-370_370-Sampoorna-MP-.png";
import blog7 from "assets/img/landingPageImage/Homepage-packages-image-size-370_370-Narmada-Parikrama.png";
import placeHolder from "assets/img/placeholder.jpg"

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(sectionSimilarStoriesStyle);
const useStyle = makeStyles(teamsStyle);

export default function SectionSimilarStories() {
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Trending Tour Packages
            </h2>
            <h4 className={classes.title + " " + classes.textCenter}>
              Wander now, wonder later! Pick from pre-curated packages
            </h4>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="#pablo">
                      <img src={blog6} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog6 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Danger>
                      <h6>BEST SELLER</h6>
                    </Danger>
                    <h4 className={classes.cardTitle}>
                      <a href="#pablo">
                        <Danger>
                          <TrendingUp />
                        </Danger>
                        MH-MP (Jyotirlinga) Special Group Tour Packages
                      </a>
                    </h4>
                    <p className={classes.description}><Danger>5 Hours</Danger></p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="#pablo">
                      <img src={blog8} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog8 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Danger>
                      <h6>BEST CHOICE</h6>
                    </Danger>
                    <h4 className={classes.cardTitle}>
                      <a href="#pablo">
                        <Danger>
                          <TrendingUp />
                        </Danger>
                        Sampoorna Madhya Prades Group Tour Packages
                      </a>
                    </h4>
                    <p className={classes.description}><Danger>15 Days 14 Nights</Danger></p>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card blog>
                  <CardHeader image>
                    <a href="#pablo">
                      <img src={blog7} alt="..." />
                    </a>
                    <div
                      className={classes.coloredShadow}
                      style={{
                        backgroundImage: "url(" + blog7 + ")",
                        opacity: "1",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <Danger>
                      <h6>HOT DEAL</h6>
                    </Danger>
                    <h4 className={classes.cardTitle}>
                      <a href="#pablo">
                        <Danger>
                          <TrendingUp />
                        </Danger>{" "}
                        Narmada Parikrama Yatra Group Tour Packages
                      </a>
                    </h4>
                    <p className={classes.description}><Danger>10 Hours</Danger></p>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classe.mlAuto + " " + classe.mrAuto + " " + classe.textCenter
            }
          >
            <h2 className={classe.title}>Customer Reviews</h2>
          </GridItem>
        </GridContainer>
        <GridContainer style={{marginTop: "5%"}}>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img src={placeHolder} alt="..." />
                </a>
              </CardAvatar>
              <CardBody>
                <h4 className={classe.cardTitle}>
                  DREAMSNAPS <br /> India
                </h4>
                <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" />
                {/* <Muted>
                    <h6 className={classe.cardCategory}>CEO / CO-FOUNDER</h6>
                  </Muted> */}
                <p className={classe.description}>
                  Amazing experience with Explore MP, Special Thanks to Naina
                  Ji... We got good service...Good Food...amazing photo
                  opportunities and more! Overall we had a very good experience
                  Thank you
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img src={placeHolder} alt="..." />
                </a>
              </CardAvatar>
              <CardBody>
                <h4 className={classe.cardTitle}>
                  ANAND Y.R <br /> India
                </h4>
                <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarHalfFill color="red" />
                {/* <Muted>
                    <h6 className={classe.cardCategory}>BACKEND HACKER</h6>
                  </Muted> */}
                <p className={classe.description}>
                  We made a fabulous trip to khajuraho, Panna and Bandhavgarh
                  Tiger Reserves. We had a very Well-behaved driver. I would
                  recommend Explore MP any day to all my friends and relatives.
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img src={placeHolder} alt="..." />
                </a>
              </CardAvatar>
              <CardBody>
                <h4 className={classe.cardTitle}>
                  JAYITA MAZUMDAR <br /> India
                </h4>
                <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarFill color="red" /> <RiStarHalfFill color="red" />
                {/* <Muted>
                    <h6 className={classe.cardCategory}>WEB DESIGNER</h6>
                  </Muted> */}
                <p className={classe.description}>
                  Overall experience with Explore MP for our recent trip to
                  Pachmarhi and Satpura was very good. Well planned and good
                  hotels. It was always a pleasure talking to Sangeeta...She was
                  very helpful.
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/khajuraho/01Khajuraho.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Khajuraho() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Khajuraho</title>
        <meta
          name="keywords"
          content="Khajuraho, Khajuraho, Unesco site, Archeological sites, Khajuraho temples, Khajuraho sightseeing, Khajuraho itinerary, Western temples, Eastern group of temples,
Parshvanath Jain Mandir, Hotels in Khajuraho, SEL Show, MPtousim hotel"
        />
        <meta
          name="description"
          content="Khajuraho- Khajuraho - Unesco site- Archeological sites- Khajuraho temples- Khajuraho sightseeing - Khajuraho itinerary- Western temples- Eastern group of temples-
Parshvanath Jain Mandir- Hotels in Khajuraho- SEL Show- MPtousim hotel"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/destination/khajuraho/Khajuraho-Dance-Festival.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Khajuraho</h1>
              <h4>
                Graceful Khajuraho, with its beautifully carved temples is a
                place out of a fairytale!
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Khajuraho, is a small town located in the Budelkhand region of
                Madhya Pradesh and is famous for its group of Hindu and Jain
                Temples. The name Khajuraho is derived from the ancient Sanskrit
                word of Khajura, which translates to one who carries or bears
                the date palm.
                <br />
                <br />
                The temples of Khajuraho were built between the 950 to 1050 AD
                during the Chandela dynasty of Central India.
                <br />
                <br />
                A mix of Hindu and Jain temples, only 20 of which remain still
                standing, built together even though they were dedicated to 2
                different religions and histories, the mix shows harmony and
                understanding of the other religions.
                <br />
                <br />
                Khajuraho, is a small town located in the Budelkhand region of
                Madhya Pradesh
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Khajuraho is famous for its group of Hindu and Jain Temples. The
                name Khajuraho is derived from from the ancient Sanskrit word of
                Khajura, which translates to one who carries or bears the date
                palm.
                <br />
                <br />
                The temples of Khajuraho were built between the 950 to 1050 AD
                during the Chandela dynasty of Central India.
                <br />
                <br />A mix of Hindu and Jain temples, only 20 of which remain
                still standing, built together even though they were dedicated
                to 2 different religions and histories, the mix shows harmony
                and understanding of the other religions.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/indore/01Indore.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Indore() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Indore</title>
        <meta
          name="keywords"
          content="Indore, Indore city tour, Indore hotels, Sarafa Bazar, Hotels in Vijaynagarindore, Sayaji hotel, Effotel by sayaji, Hotel Ambassador Indore, Rajwada
Lalbag palace indore, Khajrna temple, Bada Ganesh, Annapurna temple, Navgrah Mandir, Cloth Market Sarafa
Sailani Island, Choral Dam, Hanuwantiya, Jal Mahotsav, Omkareshwar Jyotirlinga, Omkareshwar Bhaktniwas, Hotels in Omkareshwar"
        />
        <meta
          name="description"
          content="Indore- Indore city tour- Indore hotels- Sarafa Bazar - Hotels in Vijaynagarindore- Sayaji hotel- Effotel by sayaji- Hotel Ambassador Indore- Rajwada
Lalbag palace indore-Khajrna temple-Bada Ganesh-Annapurna temple-Navgrah Mandir- Cloth Market Sarafa
Sailani Island -Choral Dam -Hanuwantiya- Jal Mahotsav -Omkareshwar Jyotirlinga- Omkareshwar Bhaktniwas- Hotels in Omkareshwar"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/destination/indore/Indore-Sarafa-Bazaar.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Indore</h1>
              <h4>An incredible story to tell, explore incredible Indore!</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Deriving its name from the Indreshwar Mahadev Temple, the city
                was named Indrapura, later being termed Indore. Indore is a city
                in west-central India.
                <br />
                <br />
                Well-known for the 7-story Rajwada Palace and the Lal Baag
                Palace dating back to Indore’s 19th-century Holkar dynasty. It
                also boasts more historical sights like Annapurna Temple,
                Behkatesh temple, Gomatgiri and more!
                <br />
                <br />
                Sometimes, Indore can be called the ‘food city’. Indore’s
                culinary culture has a blend of Maharashtrian, Malwi, Rajasthani
                and Gujarati influence.
                <br />
                It has great variety and taste in food, visit popular foodie
                destinations like Sarafa Bazar, Chappan Dukaan, Nafees, Hakeem,
                etc. These stores provide authentic Indori food experience!
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                You can also explore destinations like the Indo-Gothic Gandhi
                Hall and clock tower. The Jain temple Kanch Mandir has a
                mirrored mosaic interior.
                <br />
                <br />
                Not being left behind in commercial aspect, and thanks to the
                rich black soil of the Malwa Plateau, Indore has prospered into
                being the fourth largest centre of the cotton textile industry
                in India.
                <br />
                <br />
                The city of Indore is also considered as an education hub for
                the state and boasts campuses of both the Indian Institute of
                Technology and the Indian Institute of Management.
                <br />
                <br />
                Indore boasts of adorable products including Chanderi and
                Maheshwari sarees, exclusive glass bangles, attractive
                indigenous Handicrafts, tribal accessories and unique stuff that
                one cannot miss to buy on an Indore trip.
                <br />
                <br />
                To purchase the colourful and distinctive Chanderi and
                Maheshwari sarees visit the famous MT market, Sitlamata market
                and Rajwada market. Choose from a variety of beautiful bangles
                that are available at every nook and corner of the city.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

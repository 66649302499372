/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Bandhavgarh_National_Park.jpg";
import image2 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Bhedaghat_jabalpur.jpg";
import image3 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Famous_Sitting_Spot_at_Pachmarhi_Lake.jpg";
import image4 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Hanuman_langur_@_Bandhavgarh_National_Park_02.jpg";
import image5 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Indian_Roller_Bird_at_Bandhavgarh_National_Park.jpg";
import image6 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Marble_Rocks_Bhedaghat_2.jpg";
import image7 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Pachmarhi_valley_Madhya_Pradesh_INDIA.jpg";
import image8 from "assets/img/tourPackage/glimpsesMadhyaPradesh/Pandava_Caves_@_Pachmarhi.jpg";
import Jungle from "assets/img/tourPackage/glimpsesMadhyaPradesh/Homepage-Destinations-image-size-370_370-Pachmarhi.png";
import River from "assets/img/tourPackage/glimpsesMadhyaPradesh/Homepage-packages-image-size-370_370-Sampoorna-MP-.png";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function GlimpsesofMadhyaPradesh() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Glimpses of Madhya Pradesh</title>
        <meta name="keywords" content="Glimpses madhya pradesh travel itinerary, best hillstation pachmarhi, kanha wildlife safari, national park, tiger safari, golden triangle, marble rocks, boating" />
        <meta name="description" content="Glimpses madhya-pradesh-travel-itinerary best hillstation pachmarhi -kanha wildlife safari -national park- tiger safari- golden triangle-marble rocks-boating" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/tourPackage/glimpsesMadhyaPradesh/tiger.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Glimpses of Madhya Pradesh</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Glimpses of Madhya Pradesh</h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;7 Days 6 Nights
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 5+
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals Provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Nearest Railhead
                              </GridItem>
                            </GridContainer>
                            <h4>
                              Some glimpses leave us awakened from within! Take
                              the tour of a lifetime with this Glimpses tour!
                              Spend 6 breathtaking days in Madhya Pradesh!
                              <br />
                              <br />
                              Destinations covered- Pachmarhi- Kanha –
                              Jabalpur/Bhedaghat
                              <br />
                              <br />
                              Pick-up & Drop point- Nearest Railhead /Airport
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Nagpur & Pachmarhi</span>
                                <br />
                                <span>As per schedule</span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span>
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              A short tour, but memories that last a lifetime!
                              Take the glimpses of Madhya Pradesh tour to
                              explore the state in an awe-inspiring light, visit
                              temples, national parks and the Pachmarhi hill
                              station in this power-packed package.
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>
                                  Wildlife Tours, Kanha and Bandhavgarh
                                </span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Queen of Satpura, Pachmarhi</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>
                                  Neolithic cave paintings and museums
                                </span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "PHOTOS",
                    tabContent: (
                      <span>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <h3>Photos</h3>
                            <Card>
                              <Carousel {...settings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="First slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Second slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Somewhere Beyond, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image6}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image7}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image8}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Pachmarhi",
                                  content:
                                    "Arrival at pipariya station and straight proceed to Pachmarhi. Check in and keep the evening at leisure. Dinner and night stay in Pachmarhi.",
                                },
                                {
                                  title: "Day 2 Pachmarhi Full Day",
                                  content:
                                    "Bird watching session (on extra charges). B/F. After b/f visit to Jatashankar, Mahadeo, Gupt Mahadeo and bee fall. Lunch. Later visit to Reechgarh and Dhoopgarh. Night drive option (on extra charges). Dinner and night stay in Pachmarhi.",
                                },
                                {
                                  title: "Day 3 Pachmarhi Full Day",
                                  content:
                                    "Visit to other sight-seeing points of Pachmarhi. Dinner and night stay in Pachmarhi.",
                                },
                                {
                                  title:
                                    "Day 4 Pachmarhi to Kanha (350 Kms/8 Hrs Drive)",
                                  content:
                                    "B/F and depart for Kanha national park. It will take almost 8/9 hrs to reach Kanha. Reach Kanha and check in hotel. After refreshments a small wildlife documentary show Will be arranged. Dinner and night stay in Kanha.",
                                },
                                {
                                  title: "Day 5 Kanha Jungle Safari",
                                  content:
                                    "A wake-up call for morning jungle safari. Back to hotel. B/F with poolside music. Time for refreshment and lunch. After lunch in evening a small trek along with our naturalist. Dinner and night stay in Kanha.",
                                },
                                {
                                  title:
                                    "Day 6 Kanha to Jabalpur (160 Kms/4 Hrs Drive)",
                                  content:
                                    "B/F and check out and depart for Jabalpur. Enroute visit to the marble rocks and Dhuandhar falls. Dinner and night stay in Jabalpur.",
                                },
                                {
                                  title: "DAY 7 Tour Ends",
                                  content:
                                    "B/F and check out and depart for Jabalpur station.",
                                },
                              ]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "VEHICLE USED",
                                "PER PERSON COST",
                                "STANDARD PKG",
                                "DELUXE PKG",
                                "PREMIUM PKG",
                              ]}
                              tableData={[
                                [
                                  "AC SWIFT D’ZIRE",
                                  "2 PAX",
                                  "INR 22,042",
                                  "INR 27,023",
                                  "INR 38,628",
                                ],
                                [
                                  "AC SWIFT D’ZIRE",
                                  "4 PAX",
                                  "INR 16,425",
                                  "INR 21,405",
                                  "INR 33,011",
                                ],
                                [
                                  "AC INNOVA",
                                  "6 PAX",
                                  "INR 15,176",
                                  "INR 20,157",
                                  "INR 31,762",
                                ],
                                [
                                  "EXTRA PERSON",
                                  "ABOVE 5-10 YEARS",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                ],
                                [
                                  "",
                                  "ABOVE 10 YEARS",
                                  "40% OF THE DBL COST",
                                  "40% OF THE DBL COST",
                                  "40% OF THE DBL COST",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "CITY",
                                "STANDARD PKG HOTELS",
                                "DELUXE PKG HOTELS",
                                "PREMIUM PKG HOTELS",
                              ]}
                              tableData={[
                                [
                                  "KANHA",
                                  "MAHUA TIGER RESORT/SIMILAR",
                                  "7 TIGERS RESORT /INFINITY RESORT",
                                  "CLUB MAHINDRA /SIMILAR",
                                ],
                                [
                                  "PACHMARHI",
                                  "HOTEL HIGHLAND/SIMILAR",
                                  "CHAMPAK BUNGALOW/SIMILAR",
                                  "WH GOLF VIEW /SIMILAR",
                                ],
                                [
                                  "JABALPUR",
                                  "HOTEL CITY INN /SIMILAR",
                                  "MPSTDC RESORT/ SIMILAR",
                                  "HOTEL VIJAN MAHAL",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>THE ABOVE PRICE INCLUDES</h4>
                            <ul>
                              <li>
                                Accommodation on twin/triple sharing basis.
                              </li>
                              <li>
                                Map meal plan at all places except ap in jungle.
                              </li>
                              <li>
                                Transfers and sight-seeing as per no. Of persons
                                mentioned above.
                              </li>
                              <li>
                                (Note: ac will be closed while on hills/ghats).
                              </li>
                            </ul>
                            <h4>THE ABOVE PRICE EXCLUDES:</h4>
                            <ul>
                              <li>Any airfare /rail fare.</li>
                              <li>5% GST.</li>
                              <li>
                                Jungle safaris extra at rs 5500/- per round
                                (accommodates 6 persons).
                              </li>
                              <li>Open gypsy at Pachmarhi.</li>
                              <li>
                                Any en-route meals or other meal not mentioned
                                in price includes.
                              </li>
                              <li>
                                Any other destination not mentioned in
                                itinerary.
                              </li>
                              <li>
                                Any monument / museum entry fees, camera fees
                                and expenses of personal nature.
                              </li>
                            </ul>
                            <h4>THINGS TO NOTEDS</h4>
                            <ul>
                              <li>
                                Rooms are subject to availability. We do not
                                hold any rooms.
                              </li>
                              <li>
                                Confirmation of rooms is subject to the receipt
                                of 100% advance payment within 3 working days of
                                booking.
                              </li>
                              <li>
                                {" "}
                                It is mandatory to carry your photo id’s while
                                on tour.
                              </li>
                            </ul>
                            <hr />
                            <Table
                              tableHead={["PAYMENT POLICY"]}
                              tableData={[
                                ["AT THE TIME OF THE BOOKING", "30%"],
                                ["02 MONTH BEFORE THE TOUR STARTS", "50%"],
                                ["10 DAYS BEFORE THE TOUR STARTS", "20%"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>CANCELLATION POLICY</h4>
                            <Table
                              tableHead={[
                                "CANCELLATION MODE",
                                "AMOUNT REFUND OUT OF TOTAL COST",
                              ]}
                              tableData={[
                                ["60 DAYS BEFORE TOUR", "70 % REFUND"],
                                ["45 DAYS BEFORE TOUR", "60 % REFUND"],
                                ["30 DAYS BEFORE TOUR", "50 % REFUND"],
                                ["15 DAYS BEFORE TOUR", "40 % REFUND"],
                                ["07 DAYS BEFORE TOUR", "25 % REFUND"],
                                ["LESS THAN 07 DAYS BEFORE TOUR", "0 % REFUND"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>NOTE:</h4>
                            <ul>
                              <li>
                                Above rates are net and non-commissionable
                                rates.
                              </li>
                              <li>
                                Room category in all packages is with base
                                category or as mentioned.
                              </li>
                              <li>
                                In sold out position of the hotels, we will try
                                to book other hotel of similar category.
                              </li>
                              <li>
                                Rates will change during long weekends and peak
                                dates.
                              </li>
                            </ul>
                            <h6>
                              {" "}
                              HOPE THE ABOVE IS IN LINE WITH REQUEST. FOR MORE
                              DETAILS, PLEASE FEEL FREE TO CONTACT US.
                            </h6>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                      </span>
                    ),
                  },
                  {
                    tabButton: "FAQ",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>FAQ</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Best time to visit Madhya Pradesh",
                                  content:
                                    "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                                },
                                {
                                  title: "Services offered?",
                                  content:
                                    "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                                },
                                {
                                  title: "Which tour is best for me?",
                                  content:
                                    "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                                },
                                {
                                  title: "Do you provide flight bookings?",
                                  content:
                                    "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                                },
                                {
                                  title:
                                    "Who can I contact in case of any problems during my tour/ stay?",
                                  content:
                                    "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                                },
                              ]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Jungle} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Popular Deals
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Jungle})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Satpuras Queen
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={River} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Choice
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${River})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Sampoorna Madhya Prades Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

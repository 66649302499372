/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
// import SectionText from "./Sections/SectionText.js";
// import SectionBlogInfo from "./Sections/SectionBlogInfo.js";
// import SectionComments from "./Sections/SectionComments.js";
import SectionSimilarStories from "./Sections/SectionSimilarStories.js";
import SectionDes from "views/ExplorePage/Sections/SectoinDes";
import SectionCards from "views/ExplorePage/Sections/SectionCards";

import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostPageStyle);

export default function ExplorePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Helmet>
        <title>Exploremp.com</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/landingPageImage/bg5.jpg").default}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem md={8} className={classes.textCenter}>
              <h1 className={classes.title}>Explore M P</h1>
              <h4 className={classes.subtitle}>
                {/* The last 48 hours of my life were total madness. This is what I
                did. */}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.main}>
        <div className={classes.container}>
          <SectionDes />
          <SectionCards />
        </div>
      </div>
      <SectionSimilarStories />
      <Footer />
    </div>
  );
}

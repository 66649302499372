import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/gallery/cities/Bhopal/103.jpg"
import image2 from "assets/img/gallery/cities/Bhopal/Bhojtal.jpg"
import image3 from "assets/img/gallery/cities/Bhopal/birla-mandir.jpg"
import image4 from "assets/img/gallery/cities/Bhopal/DSC088616443.jpg"
import image5 from "assets/img/gallery/cities/Bhopal/Gouhar-Mahal.jpg"
import image6 from "assets/img/gallery/cities/Bhopal/Header-13.jpg"
import image7 from "assets/img/gallery/cities/Bhopal/Laxmi-Narayan-Temple.jpg"
import image8 from "assets/img/gallery/cities/Bhopal/Lower_lake_Bhopal.jpg"
import image9 from "assets/img/gallery/cities/Bhopal/Moti-Masjid.jpg"
import image10 from "assets/img/gallery/cities/Bhopal/Sadar-Manzil.jpg"
import image11 from "assets/img/gallery/cities/Bhopal/Taj-ul-Masajid.jpg"
import image12 from "assets/img/gallery/cities/Bhopal/Upper_Lake_from_Van-Vihar-National-Park.jpg"
import image13 from "assets/img/gallery/cities/Bhopal/Whats-in-the-Name.jpg"

import image14 from "assets/img/gallery/cities/Indore/Choral-Dam.jpg"
import image15 from "assets/img/gallery/cities/Indore/gandhi-hall-indore.jpg"
import image16 from "assets/img/gallery/cities/Indore/Header-Indore.jpg"
import image17 from "assets/img/gallery/cities/Indore/Indore-Sarafa-Bazaar.jpg"
import image18 from "assets/img/gallery/cities/Indore/Kanch_Mandir_Indore.jpg"
import image19 from "assets/img/gallery/cities/Indore/Leather-Toys-Making.jpg"
import image20 from "assets/img/gallery/cities/Indore/Rajwada_Palace_Indore.jpg"
import image21 from "assets/img/gallery/cities/Indore/Rajwada-Indore.jpg"
import image22 from "assets/img/gallery/cities/Indore/Sunset-at-Sirpur-lake-IndoreMP.jpg"

import image23 from "assets/img/gallery/cities/Jabalpur/chausath-yogini-temple.jpg"
import image24 from "assets/img/gallery/cities/Jabalpur/Chousath-Yogini-Temple-1.jpg"
import image25 from "assets/img/gallery/cities/Jabalpur/Header-14.jpg"
import image26 from "assets/img/gallery/cities/Jabalpur/Madan-Mahal-Fort.jpg"
import image27 from "assets/img/gallery/cities/Jabalpur/Marble-rocks.jpg"
import image28 from "assets/img/gallery/cities/Jabalpur/Rani-Durgavati-Museum.jpg"
import image29 from "assets/img/gallery/cities/Jabalpur/Station-Marker-Jabalpur.jpg"

import image30 from "assets/img/gallery/cities/Gwalior/DSC_1936.jpg"
import image31 from "assets/img/gallery/cities/Gwalior/gwalior-1649274_1280.jpg"
import image32 from "assets/img/gallery/cities/Gwalior/Gwalior-fort.jpg"
import image33 from "assets/img/gallery/cities/Gwalior/Header-7.jpg"
import image34 from "assets/img/gallery/cities/Gwalior/Jai-Vilas-Palace_600-1280x720-1.jpg"
import image35 from "assets/img/gallery/cities/Gwalior/Jai-Vilas02.jpg"
import image36 from "assets/img/gallery/cities/Gwalior/tansen-samaroh-brochure.jpg"

const useStyles = makeStyles(carouselStyle);

export default function CitiesCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image7} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image10}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image11}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image12}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image13}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bhopal
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image14}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image15}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image16}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image17}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image18}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image19}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image20}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image21}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image22}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Indore
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image23}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image24}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image25}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image26}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image27}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image28}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image29}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Jabalpur
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img
                    src={image30}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image31}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image32}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image33}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image34}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image35}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image36}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Gwalior
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

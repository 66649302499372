/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/maheshwar/Maheshwar_Fort.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Maheshwar() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Maheshwar</title>
        <meta name="keywords" content="Maheshwar, Maheshwar city tour, Maheshwari sarees, Maheshwari handloom, Ahilyabai holkar museum, Maheshwar ghat, Hotels in Maheshwar" />
        <meta name="description" content="Maheshwar- Maheshwar city tour- Maheshwari sarees- Maheshwari handloom- Ahilyabai holkar museum- Maheshwar ghat- Hotels in Maheshwar" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/maheshwar/maheshwar.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Maheshwar</h1>
              <h4>
                Visit Magnificent Maheshwar and its beautiful temples and
                peaceful aura
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div className={classe.imageContainer} style={{marginTop: "10%"}}>
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Located in the beautiful Central state of Madhya Pradesh,
                Maheshwar is a breathtaking place to visit! Located no more than
                90kms from Indore, which is a very close destination to reach!
                <br />
                <br />
                The city which lies on the north bank of the Narmada river is
                home to destinations like Ahilyabai’s fort, Akhileshwar Temple,
                Narmada Ghat, and the Ek Mukhi Datta Temple.
                <br />
                <br />
                A destination that will impress both tourists and pilgrims!
                <br />
                <br />
                This city also has one of the oldest handloom weaving cultures,
                dating back to the 5th Century! The city produces exquisite
                Maheshwari Saris and Fabrics.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                The life-size statue of Rani Ahilya Bai seated on her throne in
                the Palace is a view mesmerizing!
                <br />
                <br />
                Maheshwar also has a lot of Shiv temples and is a must-visit for
                Shiv bhakts. Pilgrims will be in awe of the number of temples
                that are built here.
                <br />
                <br />
                Well-known for its spiritual importance with mentions even in
                Hindu epics like Ramayana and Mahabharata, temples like
                Kaleshwara Temple, Rajarajeshwara Temple, Akhileshwara Temple
                and Vithaleshwara Temple, are also popular pilgrim spots. They
                are also architecture wonders of the city!
                <br />
                <br />
                While exploring Maheshwar, visiting the local markets for
                handlooms and sari fabrics is also a must! Local shops are a
                goldmine for their rich fabric.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

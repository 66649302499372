/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/malwaDelight/17225993.jpg";
import image2 from "assets/img/tourPackage/malwaDelight/50958300.jpg";
import image3 from "assets/img/tourPackage/malwaDelight/151023142717-cuba-valle-de-vinales-restr-super-169.jpg";
import image4 from "assets/img/tourPackage/malwaDelight/View_of_valley_between_Malwa_and_Mandu_plateaus.jpg";
import image5 from "assets/img/tourPackage/malwaDelight/Vindhyas_Mountain_Range_seen_from_Malwa_Plateau_Mandu_Mandav_Madhya_Pradesh_India_2009.jpg";
import Mandir from "assets/img/tourPackage/malwaDelight/birla-mandir.jpg";
import Lake from "assets/img/tourPackage/malwaDelight/Lower_lake_Bhopal.jpg";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function MalwaDelight() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>Malwa Delight</title>
        <meta name="keywords" content="group tour malwa-tour-package, jain meals, jyotirlinga darshan, ujjain, omkareshwar, mahakal, bhasma arti, sarafa bazar, street food indore,  maheshwari saree, handlooms,  maheshwar, Ahilyabai holkar museum, mandavgarh, mandu, love story, raniroopmati, jahaz mahal" />
        <meta name="description" content="group tour malwa-tour-package/jain meals -jyotirlinga darshan -ujjain -omkareshwar-mahakal-bhasma arti-sarafa bazar-street food indore- maheshwari saree-handlooms- maheshwar-Ahilyabai holkar museum-mandavgarh/mandu-love story-raniroopmati -jahaz mahal" />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/tourPackage/malwaDelight/Malwa.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Malwa Delight</h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Malwa Delight</h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;5 Days
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 10+
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Airport
                              </GridItem>
                            </GridContainer>
                            <h4>
                              Take an interesting tour of the Malwa grounds,
                              Occupying the volcanic region of the western
                              ghats, Malwa’s roots hold a lot of history. The
                              sultanate of Malwa was founded in 1392 and since
                              then has seen multiple rulers. Explore the history
                              of this region with a 4 days tour!
                              <br />
                              <br />
                              Destinations Covered- Ujjain- Omkareshwar-
                              Maheshwar- Mandu – Indore
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Mandu & Ujjain</span>
                                <br />
                                <span>As per schedule</span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span>
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              A destination that takes you to another lifetime.
                              Get back to the roots of Madhya Pradesh, a place
                              city founded in the 1300s to the present. Immerse
                              deeply in the rich history and pilgrimage of
                              Madhya Pradesh with this beautiful tour set for
                              the explorers!
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;<span>Ahilyabais fort and rule</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Beautiful Maheshwari Sarees</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>The holy river of Narmada</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Temple tours</span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "PHOTOS",
                    tabContent: (
                      <span>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <h3>Photos</h3>
                            <Card>
                              <Carousel {...settings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="First slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Second slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Somewhere Beyond, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                                <div>
                                  <img
                                    src={image5}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Mumbai to Ujjain",
                                  content:
                                    "Mumbai – ujjain: arrive indore by awantika express and meet our representative on arrival.Proceed to the hotel and check in. After some refreshments, get ready to visit ujjain city (55 km from indore), visit the temples of ujjain. Day ends in ujjain.",
                                },
                                {
                                  title:
                                    "Day 2 Ujjain to Mandu (180 Kms/5 Hrs Drive)",
                                  content:
                                    "Early breakfast and depart for mandu which is a fort hilltop located in madhya pradesh.Enroute visit to some pointsof mandu before check in. Later check in and relax. Evening at leisure. Dinner and day ends in mandu.",
                                },
                                {
                                  title:
                                    "Day 3 Mandu to Indore via Maheshwar and Omkareshwar (Total 200 Kms)",
                                  content:
                                    "After an early breakfast, we will start for maheshwar. Maheshwar is also famous amongst the ladies as it has the very old looms of maheshwari fabric. After lunch, start for omkareshwar, yet another most visited jyotirlinga which is 65 kms from maheshwar city. Omakreshwar is situated on the banks of holy river narmada and the shape of the island is like the hindu symbol ॐ. After the darshan of mandhata and mamleshwar start for indore. Enroute, we will visit the khajrana temple. Dinner and day ends in indore.",
                                },
                                {
                                  title: "Day 4 Indore City Sight-seeing",
                                  content:
                                    "Indore is a city in west-central india. It’s known for the 7-story rajwada palace and the lal baag palace, which date back to indore’s 19th-century holkar dynasty. The holkar rulers are honored by a cluster of tombs and cenotaphs at chhatri baag. The night market sarafa bazar sells street food. East is the indo-gothic gandhi hall and clock tower. The jain temple kanch mandir has a mirrored mosaic interior. Day ends in indore.",
                                },
                                {
                                  title: "Day 5 Indore- Tour Ends",
                                  content:
                                    "Keep a relaxed morning and enjoy the good memories of the trip at your breakfast table. Please take some time to share your feedback with our representative. Check out and we will depart to the nearest railhead/airport. Some time to shop the souvenirs.",
                                },
                              ]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "VEHICLE USED",
                                "PER PERSON COST",
                                "STANDARD PKG",
                                "DELUXE PKG",
                                "PREMIUM PKG",
                              ]}
                              tableData={[
                                [
                                  "AC SWIFT D'ZIRE",
                                  "2 PAX",
                                  "INR 14,538",
                                  "INR 17,099",
                                  "INR 20,299",
                                ],
                                [
                                  "AC SWIFT D'ZIRE",
                                  "4 PAX",
                                  "INR 10,828",
                                  "INR 13,320",
                                  "INR 16,559",
                                ],
                                [
                                  "AC INNOVA",
                                  "6 PAX",
                                  "INR 10,033",
                                  "INR 12,529",
                                  "INR 15,799",
                                ],
                                [
                                  "EXTRA PERSON",
                                  "ABOVE 5-10 YEARS",
                                  "25% OF THE DBL COST",
                                  "25% OF THE DBL COST",
                                  "25% OF THE DBL COST",
                                ],
                                [
                                  "",
                                  "ABOVE 10 YEARS",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                  "30% OF THE DBL COST",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <hr />
                            <Table
                              tableHead={[
                                "CITY",
                                "STANDARD PKG HOTELS",
                                "DELUXE PKG HOTELS",
                                "PREMIUM PKG HOTELS",
                              ]}
                              tableData={[
                                [
                                  "UJJAIN",
                                  "HOTEL PARK PALACE/SIMILAR",
                                  "SHIPRA RESIDENCY /SIMILAR",
                                  "HOTEL ANJUSHREE /SIMILAR",
                                ],
                                [
                                  "MANDU",
                                  "MALWA RETREAT /SIMILAR",
                                  "JAHAZ MAHAL /SIMILAR",
                                  "MALWA RESORT/SIMILAR",
                                ],
                                [
                                  "INDORE",
                                  "HOTEL APNA PALACE/SIMILAR",
                                  "LEMON TREE/SIMILAR",
                                  "SAYAJI /SIMILAR",
                                ],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>THE ABOVE PRICE INCLUDES</h4>
                            <ul>
                              <li>
                                Accommodation on twin/triple sharing basis.
                              </li>
                              <li>Map meal plan at all places.</li>
                              <li>
                                Transfers and sight-seeing as per no. Of persons
                                mentioned above.
                              </li>
                              <li>
                                (Note: ac will be closed while on hills/ghats).
                              </li>
                            </ul>
                            <h4>THE ABOVE PRICE EXCLUDES:</h4>
                            <ul>
                              <li>Any airfare /rail fare.</li>
                              <li>5% GST.</li>
                              <li>
                                Jungle safaris extra at rs 5500/- per round
                                (accommodates 6 persons).
                              </li>
                              <li>Bhasma Aarti Tickets at Ujjain.</li>
                              <li>
                                Any en-route meals or other meal not mentioned
                                in price includes.
                              </li>
                              <li>
                                Any other destination not mentioned in
                                itinerary.
                              </li>
                              <li>
                                Any monument / museum entry fees, camera fees
                                and expenses of personal nature.
                              </li>
                              <li>Travel Insurance</li>
                            </ul>
                            <h4>THINGS TO NOTEDS</h4>
                            <ul>
                              <li>
                                Rooms are subject to availability. We do not
                                hold any rooms.
                              </li>
                              <li>
                                Confirmation of rooms is subject to the receipt
                                of 100% advance payment within 3 working days of
                                booking.
                              </li>
                              <li>Days of Booking</li>
                              <li>
                                {" "}
                                It is mandatory to carry your photo id’s while
                                on tour.
                              </li>
                              <li>Travel Insurance</li>
                            </ul>
                            <hr />
                            <Table
                              tableHead={["PAYMENT POLICY"]}
                              tableData={[
                                ["AT THE TIME OF THE BOOKING", "30%"],
                                ["02 MONTH BEFORE THE TOUR STARTS", "50%"],
                                ["10 DAYS BEFORE THE TOUR STARTS", "20%"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>CANCELLATION POLICY</h4>
                            <Table
                              tableHead={[
                                "CANCELLATION MODE",
                                "AMOUNT REFUND OUT OF TOTAL COST",
                              ]}
                              tableData={[
                                ["60 DAYS BEFORE TOUR", "70 % REFUND"],
                                ["45 DAYS BEFORE TOUR", "60 % REFUND"],
                                ["30 DAYS BEFORE TOUR", "50 % REFUND"],
                                ["15 DAYS BEFORE TOUR", "40 % REFUND"],
                                ["07 DAYS BEFORE TOUR", "25 % REFUND"],
                                ["LESS THAN 07 DAYS BEFORE TOUR", "0 % REFUND"],
                              ]}
                              customCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customClassesForCells={[0, 4, 5]}
                              customHeadCellClasses={[
                                classTable.textCenter,
                                classTable.textRight,
                                classTable.textRight,
                              ]}
                              customHeadClassesForCells={[0, 4, 5]}
                            />
                            <h4>NOTE:</h4>
                            <ul>
                              <li>
                                Above rates are net and non-commissionable
                                rates.
                              </li>
                              <li>
                                Room category in all packages is with base
                                category or as mentioned.
                              </li>
                              <li>
                                In sold out position of the hotels, we will try
                                to book other hotel of similar category.
                              </li>
                              <li>
                                Rates will change during long weekends and peak
                                dates.
                              </li>
                            </ul>
                            <h6>
                              {" "}
                              HOPE THE ABOVE IS IN LINE WITH REQUEST. FOR MORE
                              DETAILS, PLEASE FEEL FREE TO CONTACT US.
                            </h6>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                      </span>
                    ),
                  },
                  {
                    tabButton: "FAQ",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>FAQ</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Best time to visit Madhya Pradesh",
                                  content:
                                    "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                                },
                                {
                                  title: "Services offered?",
                                  content:
                                    "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                                },
                                {
                                  title: "Which tour is best for me?",
                                  content:
                                    "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                                },
                                {
                                  title: "Do you provide flight bookings?",
                                  content:
                                    "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                                },
                                {
                                  title:
                                    "Who can I contact in case of any problems during my tour/ stay?",
                                  content:
                                    "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                                },
                              ]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Mandir} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Mandir})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Bhopal-Pachmarhi-3N/4D
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Lake} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Lake})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Bhopal-Madhai 3N/4D
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

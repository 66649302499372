import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/Barasingha.jpg"
import image2 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/DSC0297_Snapseed_Fotor-590448a25f9b5810dcf78a5a.jpg"
import image3 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/Header-3.jpg"
import image4 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/Kanha_national_park_3.jpg"
import image5 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/Kanha-National-Park-Travel-Guide.jpg"
import image6 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/Kanha-National-Park.jpg"
import image7 from "assets/img/gallery/wildLife/Kanha & Pench National Parks/pench_nagpur_5.jpg"

import image8 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Baghela-Museum-2.jpg"
import image9 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Bhairavnath-Statue.jpg"
import image10 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Header-4.jpg"
import image11 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Rani-ka-Talab.jpg"
import image12 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Rewa-Fort.jpg"
import image13 from "assets/img/gallery/wildLife/Bandhavgarh National Park/Viyankat-Bhawan.jpg"

const useStyles = makeStyles(carouselStyle);

export default function WildlifeCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image7} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Kanha & Pench National Parks
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image10} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image11} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image12} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image13} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Bandhavgarh National Park
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

import React from "react";
// @material-ui/core components
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import { SiWhatsapp } from "react-icons/si";
// import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

import city from "assets/img/contact/Jehangir-Mahal.jpg";

const useStyles = makeStyles(contactsStyle);
const useStyleAlert = makeStyles(style);

export default function ExploreContact({ ...rest }) {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameErr, setFirstNameErr] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastnameErr, setLastNameErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const match = useRouteMatch();

  const classes = useStyles();
  const classeAlert = useStyleAlert();

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleMessage = () => {
    const msg =
      "FistName: " +
      firstName +
      ", LastName: " +
      lastName +
      ", Phone Number: " +
      mobile +
      ", Date: " +
      date +
      ", Email Id: " +
      email +
      ", Message: " +
      message +
      ", Url: " +
      `https://www.exploremp.com${match.path}`;

    let errorCount = 0;
    if (firstName === "" || !firstName) {
      errorCount++;
      setFirstNameErr("FirstName is Required");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Email is Required");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Message is Required");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Mobile is Required");
    }
    if (lastName === "" || !lastName) {
      errorCount++;
      setLastNameErr("LastName is Required");
    }
    if (errorCount === 0) {
      fetch(
        `http://localhost:8085/api/register/send/byClient?to=info@exploremp.com&email=info@exploremp.com&message=${msg}&subject=${match.path}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Send Successfully !!!") {
            setSubmitted(true);
            alertTypeOps("success");
            alertMsgOps("Send Successfully");
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  return (
    <div className="cd-section" {...rest}>
      <Helmet>
        <title>Contact Us</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      {/* Contact us 1 START */}
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            {submitted ? (
              <SweetAlert
                type={alertType}
                style={{ display: "block", marginTop: "100px" }}
                title={alertMsg}
                onConfirm={() => hideAlert()}
                confirmBtnCssClass={
                  classeAlert.button + " " + classeAlert.success
                }
              />
            ) : null}
            <GridItem xs={12} sm={12} md={5}>
              <h2 className={classes.title}>LEAVE US YOUR INFO</h2>
              <h5 className={classes.description}>
                And we will get back to you.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Find us at the office"
                description={
                  <span>
                    103, First Floor, Vishwas CHS., Ghatkopar (East), Mumbai-
                    400 077, Maharashtra, India.
                  </span>
                }
                icon={PinDrop}
              />
              <InfoArea
                className={classes.infoArea}
                title="Give us a ring"
                description={
                  <span>
                    <a href="tel:+91-84248 81169">+91-84248 81169</a>
                    <br /> <a href="tel:+91-84248 82009">+91-84248 82009</a>
                  </span>
                }
                icon={Phone}
              />
              <a
                href={`https://api.whatsapp.com/send?phone=918424881169&text=https://exploremp.com/%20Hello%20I%20am%20Interested%20in%20your%20Tour%20Trip&app_absent=0`}
              >
              <InfoArea
                  className={classes.infoArea}
                  title="Click on the Whatsapp icon to get connected with us"
                  description=""
                  icon={SiWhatsapp}
                  iconColor="success"
                />
                </a>
            </GridItem>
            <GridItem xs={12} sm={12} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    color="primary"
                    className={classes.textCenter}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "First Name",
                            value: firstName,
                            onChange: (e) => {
                              const { value } = e.target;
                              setFirstName(value);
                              setFirstNameErr("");
                            },
                          }}
                          required={true}
                        />
                        <span style={{ color: "red" }}>{firstNameErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="last"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            placeholder: "Last Name",
                            value: lastName,
                            onChange: (e) => {
                              const { value } = e.target;
                              setLastName(value);
                              setLastNameErr("");
                            },
                          }}
                          required={true}
                        />
                        <span style={{ color: "red" }}>{lastnameErr}</span>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <PhoneInput
                          // style={mystyle}
                          inputStyle={{
                            width: "100%",
                            border: "none",
                            borderBottom: "1px solid #495057",
                            borderRadius: "0",
                          }}
                          country={"in"}
                          name="mobile"
                          value={mobile}
                          onChange={(mobile) => {
                            handleMobileNumber(mobile);
                          }}
                          required={true}
                        />
                        <span style={{ color: "red" }}>{mobileErr}</span>
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Email Address",
                        value: email,
                        onChange: (e) => {
                          const { value } = e.target;
                          setEmail(value);
                          setEmailErr("");
                        },
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{emailErr}</span>
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: message,
                        onChange: (e) => {
                          const { value } = e.target;
                          setMessage(value);
                          setMessageErr("");
                        },
                      }}
                      required={true}
                    />
                    <span style={{ color: "red" }}>{messageErr}</span>
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <Button
                      color="primary"
                      className={classes.pullRight}
                      onClick={handleMessage}
                    >
                      Send Message
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
      <Footer />
    </div>
  );
}

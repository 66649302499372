/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/jyotirlinga/khajuraho-1952716_1280.jpg";
import image2 from "assets/img/tourPackage/jyotirlinga/MANDU.jpg";
import image3 from "assets/img/tourPackage/jyotirlinga/Orchha_Fort_complex_-Orchha_-Madhya_Pradesh_-IMG_4932.jpg";
import image4 from "assets/img/tourPackage/jyotirlinga/Sanchi1_N-MP-220.jpg";
import River from "assets/img/tourPackage/jyotirlinga/Homepage-packages-image-size-370_370-Sampoorna-MP-.png";
import Jungle from "assets/img/tourPackage/jyotirlinga/Homepage-packages-image-size-370_370-Narmada-Parikrama.png";
import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function MHMPJyotirlingaSpecialGroupTourPackages() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <Helmet>
        <title>MH-MP Jyotirlinga Special Group Tour Packages</title>
        <meta
          name="keywords"
          content="MH MP JYOTIRLING TOUR
Maharashtra Jyotirlinga, Jyotirlinga darshan, 12 Jyotirlinga, Shirdi, Nasik, Saibaba Darshan, Tryambakeshwar, Shiva, 5 jyotirlinga, darshan, Madhya Pradesh Jyotirlinga, Ujjain, Omkareshwar, Mahakal, Mahakaleshwar, 
Mandahata -Narmada, Bhasma arti"
        />
        <meta
          name="description"
          content="MH MP JYOTIRLING TOUR
Maharashtra Jyotirlinga - Jyotirlinga darshan- 12 Jyotirlinga -Shirdi -Nasik-Saibaba Darshan- Tryambakeshwar- Shiva-5 jyotirlinga- darshan- Madhya Pradesh Jyotirlinga- Ujjain- Omkareshwar- Mahakal-Mahakaleshwar- 
Mandahata -Narmada - Bhasma arti"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/tourPackage/jyotirlinga/Jyotirlinga.jpg").default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>
                MH-MP Jyotirlinga Special Group Tour Packages
              </h1>
              {/* <h4>
                Some views that can't be contained within pictures, but we
                tried.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div
          className={classes.container}
          style={{
            padding: "80px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>
                              MH-MP (Jyotirlinga) Special Group Tour Packages
                            </h3>
                            <GridContainer>
                              <GridItem md={4} sm={4}>
                                <BsStopwatch color="red" />
                                &nbsp;6N/7D
                                <br />
                                <BsCalendar2Event color="red" />
                                &nbsp;N/A
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <ImUsers color="red" />
                                &nbsp;Max People : N/A
                                <br />
                                <ImUser color="red" />
                                &nbsp;Min Age : 12+
                              </GridItem>
                              <GridItem md={4} sm={4}>
                                <GiMeal color="red" />
                                &nbsp;Meal: Daily Meals provided
                                <br />
                                <ImLocation color="red" />
                                &nbsp;Pickup: Airport
                              </GridItem>
                            </GridContainer>
                            <h4>
                              Take an historical and Pilgrimage tour of Madhya
                              Pradesh, starting all the way from Nashik heading
                              into Madhya Pradesh and then returning back to
                              Holy Shirdi to seek blessings of Sai Baba! This
                              tour will leave you spiritually awakend!
                              <br />
                              <br />
                              Destination Covered: Tryambakeshwar – Omkareshwar
                              –maheshwar-mandu Ujjain- Shirdi Pick Up & Drop
                              Point – Mumbai /thane/navi Mumbai
                              <br />
                              Per Head Cost: Rs 21,999/-
                              <br />
                              Extra Adult Charges: Rs 17,999/-
                              <br />
                              5% Gst Extra
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <sapn>Departure & Return Location</sapn>
                                <br />
                                <span>Departure Time</span>
                                <br />
                                <span>Price Includes</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <span>Gwalior / Indore (Google Map)</span>
                                <br />
                                <span>As per schedule</span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable Stay on DBL/TRPL sharing basis
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Comfortable and well sanitized Vehicles from
                                  pick up till drop
                                </span>
                                <br />
                                <span>
                                  <BsCheck color="red" />
                                  Small Vehicles wherever needed
                                </span>
                              </GridItem>
                            </GridContainer>
                            <h3>What to Expect</h3>
                            <h4>
                              An exclusive special tour, travel from Nashik and
                              back to Shirdi for a spiritual awakening! Visit
                              the holy destinations that make Madhya Pradesh so
                              special in this 7-Day tour!
                            </h4>
                            <GridContainer>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;<span>Tryambakeshwar</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Rani Roopmati</span>
                              </GridItem>
                              <GridItem md={6} sm={6}>
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Mahakaleshwar And Kal Bhairav</span>
                                <br />
                                <TbCircleDot color="red" />
                                &nbsp;
                                <span>Jyotirlings</span>
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "PHOTOS",
                    tabContent: (
                      <span>
                        <GridContainer>
                          <GridItem
                            xs={12}
                            md={12}
                            className={classes.marginAuto}
                          >
                            <h3>Photos</h3>
                            <Card>
                              <Carousel {...settings}>
                                <div>
                                  <img
                                    src={image1}
                                    alt="First slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image2}
                                    alt="Second slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Somewhere Beyond, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image3}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                  {/* <div className="slick-caption">
                                    <h4>
                                      <LocationOn className="slick-icons" />
                                      Yellowstone National Park, United States
                                    </h4>
                                  </div> */}
                                </div>
                                <div>
                                  <img
                                    src={image4}
                                    alt="Third slide"
                                    className="slick-image"
                                  />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Day 1 Mumbai-Nashik (179 Kms /4 Hrs)",
                                  content:
                                    "Pick up from given pick-up point @ 9.00am. Start for Nashik. Your first destination will be Tryambakeshwar. Reach Tryambakeshwar by lunch time. Take alloted rooms and rest. Evening darshan. Stay in Tryambakeshwar /Nashik (as per availability).",
                                },
                                {
                                  title:
                                    "Day 2 Tryambakeshwar–Omkareshwar (450 Kms/9 Hrs)",
                                  content:
                                    "B/f and proceed for Omkareshwar. Tea/coffee & lunch halt enroute. Reach Omkareshwar by evening/late night. Dinner. Night stay in Omkareshwar. ",
                                },
                                {
                                  title:
                                    "Day 3 Omkareshwar–Mandu (130 Kms/ 6 Hrs)",
                                  content:
                                    "Early morning b/f and darshan of Mandhata temple and Mamleshwar temple. After darshan, straight proceed for Maheshwar. Break for lunch and later visit the Ahilya museum and Maheshwar ghat. Proceed for Mandu. Dinner and night stay in Mandu. ",
                                },
                                {
                                  title: "Day 4 Mandu–Ujjain (160 Kms /5 Hrs)",
                                  content:
                                    "B/f and visit to Rani Roopmati pavilion and Jahaz mahal. Lunch and proceed for Ujjain. Enroute visit to the Sandipani ashram. Check in hotel. Evening at leisure. Dinner and night stay in Ujjain.",
                                },
                                {
                                  title: "Day 5 Ujjain–Indore (160 Kms /5 Hrs)",
                                  content:
                                    "Early b/f and darshan of Mahakaleshwar and Kal Bhairav and proceed for Indore city. Check-in hotel. Rest and keep evening at leisure. Night stay in Indore.",
                                },
                                {
                                  title: "Day 6 Indore–Shirdi (415 Kms /9 Hrs)",
                                  content:
                                    "B/f and proceed for your last sight-seeing point, Shirdi. Reach Shirdi by late evening. Dinner and evening at leisure. Night halt.",
                                },
                                {
                                  title: "Day 7 Shirdi-Mumbai (241 Kms /6 Hrs)",
                                  content:
                                    "Early morning Darshan of Sai baba and after lunch proceed back for Mumbai by evening. Tour ends with some beautiful memories.",
                                },
                              ]}
                            />
                            <h4>Inclusions :</h4>
                            <ul>
                              <li>
                                Comfortable accommodations on dbl /trpl
                                occupancy.
                              </li>
                              <li>
                                Daily b/f +lunch+ eveng. Tea + dinner (fixed
                                menu)
                              </li>
                              <li>
                                Comfortable and well sanitized bus. (35 seater
                                ac bus- pushback).
                              </li>
                              <li>Daily one water bottle per person.</li>
                              <li>Tour manager / escort.</li>
                              <li>Boating at Maheshwar.</li>
                              <li>Guide charges at Maheshwar and Mandu.</li>
                            </ul>
                            <h4>Exclusions :</h4>
                            <ul>
                              <li>Porterage charges & personal expenses.</li>
                              <li>Travel insurance.</li>
                              <li>Extra meal apart from the fixed menu.</li>
                              <li>
                                Extra stay at any place apart from the fixed
                                itinerary.
                              </li>
                              <li>Tips at hotel.</li>
                              <li>
                                Govt tax of 5% over and above the tour cost
                                mentioned
                              </li>
                              <li>To & fro air & rail ticket</li>
                              <li>Cost of insurance</li>
                              <li>Cost of pre/post tour hotel accommodation</li>
                              <li>
                                Any extra expense such as route change, airline
                                change, date change, accommodation facilities,
                                etc incurred due to the unforeseen, unavoidable
                                forced majeure circumstances during the tour
                              </li>
                              <li>
                                Porterage (coolie charges), laundry, telephone
                                charges, shopping, wines and alcoholic
                                beverages, mineral water, items of personal
                                nature and food or drink which is not part of a
                                set group menu
                              </li>
                              <li>
                                Any extra cost incurred on behalf of an
                                individual due to illness, accident,
                                hospitalization, or any personal emergency
                              </li>
                              <li>
                                Any services or activity charges other than
                                those included in the group tour itinerary
                              </li>
                              <li>
                                Anything specifically not mentioned in the ‘tour
                                price includes’ column.
                              </li>
                            </ul>
                            <h4>Important Guide Lines For The Tour:</h4>
                            <ul>
                              <li>
                                Room Heater available with an extra cost borne
                                by the guest directly.
                              </li>
                              <li>
                                Hotels are very strict with the child policy.
                                Please carry the age proof so that it can be
                                produced if asked by hotel.
                              </li>
                              <li>
                                A valid photo ID proof for all guests staying at
                                the hotel is mandatory (compulsory).
                              </li>
                              <li>
                                For Extra adult in the room we will provide an
                                extra bed (wherever possible), but most of the
                                hotels only provide extra mattress or roll out
                                bed. Most of the hotels have no provision of an
                                extra bed.
                              </li>
                              <li>
                                In the area (almost all) the hotels do not have
                                a lift facility and in all the hotels the guest
                                will have to climb stairs. In case if any guest
                                is having problem of blood pressure, knee or
                                other ailment for which they cannot climb the
                                higher floors they will have to intimate us
                                during the time of booking so that we can
                                arrange rooms at the lowest floor of the hotel
                                enabling the guest to climb the minimum number
                                of stairs possible.
                              </li>
                              <li>
                                All the meals will be provided at the hotel
                                dining rooms as per their timings, the food will
                                be provided as per their meal plan
                              </li>
                              <li>
                                Please ensure that you do not leave your
                                belongings in the vehicle or in hotel rooms as
                                there is very little possibility of getting them
                                back. Always check your baggage before you leave
                                the vehicle or hotel room.
                              </li>
                              <li>
                                If guests want any changes in their sightseeing
                                schedule they should be informed to our
                                executive previous day.
                              </li>
                              <li>
                                The guest should always keep cool with the
                                drivers as they are not tourism educated and
                                come from different remote villages.
                              </li>
                              <li>
                                We would appreciate, if the guest does not lend
                                their ears to the drivers as most of the time
                                they misguide the passengers. In such instances
                                we would request them to contact our executive.
                              </li>
                              <li>
                                Operator reserves the right to re-arrange
                                itinerary to suit hotel availability without
                                changing the total number of days in each
                                destination and without compromising any
                                services.
                              </li>
                            </ul>
                            <h4>
                              Explore MP LLP is no circumstanced be liable
                              for-any death, Personal Injury, Sickness,
                              accident, loss, delay, discomfort, increase
                              expense consequential loss or damage or theft the
                              temporary or permanent loss or damage to baggage
                              or personal effects however caused. The
                              organization reserves the right to claim any
                              additional amount of money for expenses due to
                              delay in train, bus, air or any other weather
                              conditions, strikes, war quarantine or any other
                              cause. All the loss or expenses will have to borne
                              by the passenger.
                            </h4>
                            <h4>Terms & Conditions:</h4>
                            <ul>
                              <li>
                                At the time of the booking every person will
                                have to pay 50% amount in advance, and the
                                remaining payment should be made 20 days before
                                the departure date of the tour, And if the
                                booking is made 20 days before tour departure
                                full payment shall be made in
                              </li>
                              <li>
                                Payment can be made by cash, cheque or demand
                                draft, Nat Banking.
                              </li>
                              <li>
                                Air Tickets Issued for this tour are Non
                                Refundable.
                              </li>
                              <li>
                                Sightseeing and Traveling by 2×2 Luxury Coach
                                (in case there are less tourists, then the
                                sightseeing and traveling can be arranged by
                                Sumo, Tempo Traveler, Minibus) hotel stay on
                                twin sharing basis or family basis, meal
                                (breakfast, lunch, Dinner)
                              </li>
                              <li>
                                Seating arrangement in Bus / Coach are
                                allocation rotation daily
                              </li>
                              <li>
                                Travel arrangement to and from the airport to
                                hotel is at customers own cost for other than
                                certain destinations specially mentioned in tour
                              </li>
                              <li>
                                There are pre-set menus for meal provided on
                                tour. In Train /Air we do not provide the f We
                                however reserve the right to change the meal
                                arrangements if circumstances make it necessary
                                for us to do so. In the event of the tour
                                participant missing any meal due to any reason,
                                then no claim can be made for the meal he has
                                missed and not utilized.
                              </li>
                              <li>
                                The company reserves the right to alter,
                                postpone or cancel any of the tour advertised in
                                the brochure, without assigning any reason.
                                whatsoever. Under circumstances of cancellation
                                of any tour by the company, money paid by the
                                tour participant will be fully refunded, but no
                                compensation is payable.
                              </li>
                              <li>
                                The organizer or tour manager has sole authority
                                to make changes in the program
                              </li>
                              <li>
                                In most Hotels extra Bed provided constitutes
                                the mattress with bed sheet, blanket and pillow.
                              </li>
                              <li>
                                If the tour participant misbehaves in a manner
                                causing inconvenience or annoyance to other tour
                                participants or causes damage to the property of
                                the company he / she will be asked to leave the
                                tour immediately and the tour escorts have been
                                authorized to do so. There will not be any
                                compensation whatsoever in such
                              </li>
                              <li>
                                Cancellation Policy:-
                                <br />* 60 days before the tour departure 15%
                                deduction from the tour cost.
                                <br />* 59 to 51 days before the tour departure
                                30% deduction from the tour cost.
                                <br />* 50 to 31 days before the tour departure
                                40% deduction from the tour cost.
                                <br />* 30 to 11 days before the tour departure
                                50% deduction from the tour cost.
                                <br />* 10 days before the tour departure no
                                refund
                              </li>
                              <li>
                                10 days before the tour departure no refund
                              </li>
                              <li>
                                The tour organization in no circumstances be
                                liable for:-
                              </li>
                              <li>
                                Company reserves the right to publish the
                                passengers photograph or group photograph
                                without intimation or permission of the same
                                passenger or group.
                              </li>
                              <li>
                                There can be in increase in the tour cost in
                                case of hike in diesel or petrol.
                              </li>
                              <li>
                                Any disputes arising in respect of the tour
                                shall be subject to Mumbai Jurisdiction
                              </li>
                              <li>
                                It is understood that tour participants
                                traveling with Explore MP LLP have read and
                                accepted the above terms & conditions.
                              </li>
                            </ul>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: (
                      <span>
                        <TourMap />
                      </span>
                    ),
                  },
                  {
                    tabButton: "FAQ",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>FAQ</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title: "Best time to visit Madhya Pradesh",
                                  content:
                                    "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                                },
                                {
                                  title: "Services offered?",
                                  content:
                                    "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                                },
                                {
                                  title: "Which tour is best for me?",
                                  content:
                                    "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                                },
                                {
                                  title: "Do you provide flight bookings?",
                                  content:
                                    "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                                },
                                {
                                  title:
                                    "Who can I contact in case of any problems during my tour/ stay?",
                                  content:
                                    "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                                },
                              ]}
                            />
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={River} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Choice
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${River})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Sampoorna Madhya Prades Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Jungle} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Hot Deal
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Jungle})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Narmada Parikrama Yatra Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

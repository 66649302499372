/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/gwalior/01Gwalior.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Gwaliar() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Gwaliar</title>
        <meta
          name="keywords"
          content="Gwalior, Gwalior City Tour, Gwalior sightseeing points, Must visit in Gwalior, Jai Vilas Palace, Gwalior fort, SEL SHow, Gwalior Sarafa, Gwalior street food
Hotels in Gwalior"
        />
        <meta
          name="description"
          content="Gwalior - Gwalior City Tour-Gwalior sightseeing points-Must visit in Gwalior- Jai Vilas Palace- Gwalior fort- SEL SHow- Gwalior Sarafa- Gwalior street food
Hotels in Gwalior"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/gwalior/Gwalior-1.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Gwaliar</h1>
              <h4>
                Glorious Gwalior, with its bold forts that stand the test of
                time
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Gwalior, with its imposing hilltop fort, was the winter capital
                and the central point of communication for India before
                Independence. The princely state remained under the British Raj
                all the way to independence.
                <br />
                <br />
                The princely state of Gwalior is also home to the Maratha clan
                Scindia, which was a major part of the regional power in the
                18th Century.
                <br />
                <br />
                Perched high on the vast rocky hills, the Gwalior Fort is
                famously described as a “The pearl among the fortresses in
                India”. A fort that is hard to miss can be spotted from any
                point in the city, is regarded as one of the most impregnable
                fortresses in the north and central India. The construction of
                the Gwalior fort was done in two parts by two different rulers
                and thus holds an intricate mix of architecture and history.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                The Jai Vilas Palace or the Jai Vilas Mahal is another
                magnificent edifice built to welcome King Edward VII and the
                Prince of Wales in 1874! Today it serves as a residence for the
                descendants of the royal Maratha family.
                <br />
                <br />
                Don’t forget to visit the Tomb of Tansen, one of the greatest
                musicians of India and an eminent vocalist in the court of
                Akbar, he was considered among one of the nine gems of the
                Mughal Court. His music and singing could enchant the animals
                and cause rainfall!
                <br />
                <br />
                Theres more spots in Gwalior that are a must visit as well, like
                the Teli ka mandir, Man Mandir Palace, Gujari Mahal, Scindia
                Museum, Gwalior Zoo, Tomb of Ghaus Mohammed and more!
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

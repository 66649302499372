/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/pachmarhi/01Pachmarhi.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Pachmarhi() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Pachmarhi</title>
        <meta
          name="keywords"
          content="Pachmarhi, Pachmarhi hillstation, Satpura ki rani, Satpura ranges, Queen of Satpura range, Hotels in PAchmarhi, Resorts in PAchmarhi, Villa in Pachmarhi
Homestay in PAchmarhi, Budget hotels in Pachmarhi, MP Tourism hotels inPachmarhi, Dhoopgarh,Bee falls, Pandav gufa, Pandav caves, Chauragarh, Jatashankar
Nagdwar mela"
        />
        <meta
          name="description"
          content="Pachmarhi - Pachmarhi hillstation- Satpura ki rani- Satpura ranges- Queen of Satpura range- Hotels in PAchmarhi- Resorts in PAchmarhi- Villa in Pachmarhi
Homestay in PAchmarhi- Budget hotels in Pachmarhi- MP Tourism hotels inPachmarhi- Dhoopgarh-Bee falls- Pandav gufa- Pandav caves- Chauragarh -Jatashankar
Nagdwar mela -"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/destination/pachmarhi/Pachmarhi.jpg").default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Pachmarhi</h1>
              <h4>Poetic Pachmarhi, the Queen of Satpura Ranges</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Pachmarhi, a hill station destination in the Hoshangabad
                district of Madhya Pradesh, is a popular tourist destination for
                many! Listed in the UNESCO Biosphere Reserve for its flora and
                fauna, the serene destination is also popularly called “Satpura
                Ki Rani”.
                <br />
                <br />
                The beautiful place rests amidst the Satpura ranges and also
                boasts the spot for the highest waterfall in a single drop in
                India at 351 ft!
                <br />
                <br />
                The beautiful region of the hill was discovered by Captain James
                Forsyth in 1857 and is a treasure of ancient caves of the
                Buddhist era!
                <br />
                <br />
                The place is sure to make you feel calm welcomed and blissful.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Pachmarhi has a lot of cave paintings in the forest and it is
                estimated that some are around 10,000 years old!
                <br />
                <br />
                More definite spots to visit are the Pandava caves, Bee Fall,
                Handi Khoh, Dhupgarh, Apsara Falls, Bada Mahadev, Gupt Mahadev
                and more!
                <br />
                <br />
                Pandav Caves are five rock-cut temples. The history of the caves
                can be traced back to 1st Century AD. It is said the caves were
                inhabited by the Buddhist monks.
                <br />
                <br />
                However, some people believe that the caves existed even when
                the exiled Pandavas took refuge in them. The caves are simple
                but attractive and have uncomplicated carvings.
                <br />
                <br />
                There is no best time to visit this place as the tourist spots
                are open almost the whole year!
                <br />
                <br />
                The destination is also easily accessible by road and train with
                bus transportation also available.
                <br />
                <br />
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import Footer from "components/Footer/Footer.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { BsTelephone } from "react-icons/bs";
// import { HiOutlineMail } from "react-icons/hi";

import face1 from "assets/img/faces/card-profile6-square.jpg";
import face2 from "assets/img/faces/christian.jpg";
import face3 from "assets/img/faces/card-profile4-square.jpg";
import face4 from "assets/img/faces/card-profile1-square.jpg";
import face5 from "assets/img/faces/marc.jpg";
import face6 from "assets/img/faces/kendall.jpg";
import face7 from "assets/img/faces/card-profile5-square.jpg";
import face8 from "assets/img/faces/card-profile2-square.jpg";

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(styles);

export default function InshaFooter() {
  const classes = useStyles();
  // const activeSession = sessionStorage.getItem("sessionData");
  return (
    <Footer
      theme="white"
      content={
        <div>
          {/* <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#pablo"
                  className={classes.block}
                  onClick={(e) => e.preventDefault()}
                >
                  Blog
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#pablo"
                  className={classes.block}
                  onClick={(e) => e.preventDefault()}
                >
                  Presentation
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#pablo"
                  className={classes.block}
                  onClick={(e) => e.preventDefault()}
                >
                  Discover
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#pablo"
                  className={classes.block}
                  onClick={(e) => e.preventDefault()}
                >
                  Payment
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="#pablo"
                  className={classes.block}
                  onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </a>
              </ListItem>
            </List>
          </div> */}
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()}{" "}
            <a href="https://inshabangles.com" target="_blank" rel="noreferrer">
              inshabangles.com
            </a>
          </div>
        </div>
      }
    >
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Contact Us</h5>
          <p>
            103, First Floor, Vishwas CHS., Ghatkopar (East), Mumbai- 400 077,
            Maharashtra, India.
          </p>
          <p>
            <BsTelephone /> <a href="tel:+91-84248 81169">+91-84248 81169</a>
          </p>
          <p>
            <BsTelephone /> <a href="tel:+91-84248 82009">+91-84248 82009</a>
          </p>
          <p>info@exploremp.com</p>
          <p>exploremp.mumbai@gmail.com</p>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Social Feed</h5>
          <p>
            Shop no.20, Asmita Jyoti CHS D/10/11 bldg, Charkop Naka, Marve Road,
            Malad West Mumbai-400095 - Maharashtra
          </p>
          <p>
            <BsTelephone /> <a href="tel:02220892468">02220892468</a>
          </p>
          <p>
            <BsTelephone /> <a href="tel:+919967457786">+919967457786</a>
          </p>
          <p>inshabangles@gmail.com</p>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <h5>Instagram Feed</h5>
          <div className={classes.galleryFeed}>
            <img
              src={face1}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face2}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face3}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face4}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face5}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face6}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face7}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
            <img
              src={face8}
              className={classNames(
                classes.img,
                classes.imgRaised,
                classes.imgRounded
              )}
              alt="..."
            />
          </div>
        </GridItem>
      </GridContainer>
    </Footer>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";

import { FaFacebook, FaGooglePlus, FaPinterest } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/preFooter.js";
// images
import presentationiPad from "assets/img/blog/Monsoon-in-Pachmarhi.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStylIcon = makeStyles(styles);

export default function ExploreBlog() {
  const [checked, setChecked] = React.useState([]);

  //   React.useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classIcon = useStylIcon();

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  return (
    <div>
      <Helmet>
        <title>Blogs</title>
        {/* <meta name="keywords" content="Helmet application" />
        <meta name="description" content="Helmet application" /> */}
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/bg9.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Blogs</h1>
              <h4>A Mini-Guide Of Travelling</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={12}>
              <div className={classe.imageContainer} style={{marginTop:"3%"}}>
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={12}>
              <h3 className={classe.title}>A MINI-GUIDE OF TRAVELING</h3>
              <h5 className={classe.description}>
                Travelling is a hobby to some and to some it is a profession.
                Figuring out how to travel the world is no easy feat, so here’s
                a guide to make it easy! A guide book is a book for tourists or
                travellers that provides details about a geographic location,
                tourist destination, or itinerary. It is the written equivalent
                of a tour guide. Travel guides are promotional documents that
                advertise specific destinations, provide information about them,
                and also about hotels, sights, accommodation, transportation,
                restaurants, and activities. Various maps to help travel around
                the city need to be included in travel guides too. The most
                Important goal of a travel guide is to help any potential
                traveler prepare for their journey and have the best experience
                of their life. Below are a few of the most common types of
                travel guides as well as benefits of each.
              </h5>
            </GridItem>
            <GridItem md={12}>
              <h3 className={classe.title}># Destination travel guide.</h3>
              <h5 className={classe.description}>
                For destination travel guide is a guide that revolves around a
                specific destination. These guides cover an entire country and
                can also cover specific cities or towns. A destination travel
                guide should enter someone to visit the destination and it
                should provide readers with specifics about that spot.
              </h5>
            </GridItem>
            <GridItem md={12}>
              <h3 className={classe.title}># Financial travel guide.</h3>
              <h5 className={classe.description}>
                Travelling comes with huge bills as well. Many a times
                prospective travellers are eager to find ways to travel more and
                spend less. For that reason, travel guides specifically focus on
                the costs related to travel. Financial travel guides that offer
                specific costs for accommodation, transport, food and
                attractions are incredibly useful.
              </h5>
            </GridItem>
            <GridItem md={12}>
              <h3 className={classe.title}># Special Interest Travel guide.</h3>
              <h5 className={classe.description}>
                While a destination guide focuses on specific area and include
                as much detail as possible, a special interest travel guide is
                far more focused. This type of guide is designed to a specific
                demographic. Some ideas include culture, history, traditions,
                cuisine or pets. Food is a major focus for special interest
                travel articles.
              </h5>
            </GridItem>
            <GridItem md={12}>
              <h3 className={classe.title}># Narrative Travel Guide.</h3>
              <h5 className={classe.description}>
                A narrative travel guide explains the story of another person’s
                or groups travel experience. The guide explains the challenges
                on the trip and events that took place all along. This might
                inspire another person reading the story to travel as well.
                Stories can revolve around travel in off-the-beaten-path
                locations, or it can be an emotional journey. These articles are
                less likely to include any bullet points since they are more
                likely to read like short stories. <br /> <br />
                Different guide books may focus on different aspects of travel,
                from adventure travel to relaxation, or be aimed at travellers
                with larger or smaller travel budgets, or focus on the
                particular interests and concerns of certain groups such as
                sexual orientation or dietary restrictions. Guide books are
                generally intended to be used in conjunction with actual travel,
                although simply enjoying a guide book with little or no
                intention of visiting may be referred to as “armchair tourism”.
              </h5>
            </GridItem>
            <GridItem md={12}>
              <div
                className={classNames(
                  classIcon.socialLine,
                  classIcon.textCenter,
                  classIcon.white,
                  classIcon.bigIcons
                )}
              >
                <div className={classIcon.container}>
                  <GridContainer justify="center">
                    <h3>0 SHARES</h3>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="twitter"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-twitter" />
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="facebook"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-facebook" />
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="google"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-google-plus-g" />
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="dribbble"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-pinterest" />
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="youtube"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-youtube" />
                      </Button>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={2}
                      md={2}
                      className={classIcon.border}
                    >
                      <Button
                        color="instagram"
                        justIcon
                        simple
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        <i className="fab fa-instagram" />
                      </Button>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </GridItem>

            <GridItem
              xs={12}
              md={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              {/* contact */}
              <form>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Full Name"
                        id="first2"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Email Address"
                        id="email-address2"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Website"
                        id="website"
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Comment"
                    id="message2"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                  />
                </CardBody>
                <CardFooter className={classContact.justifyContentBetween}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onClick={() => handleToggle(2)}
                        checkedIcon={
                          <Check className={classContact.checkedIcon} />
                        }
                        icon={<Check className={classContact.uncheckedIcon} />}
                        classes={{
                          checked: classContact.checked,
                          root: classContact.checkRoot,
                        }}
                      />
                    }
                    classes={{ label: classContact.label }}
                    label="Save my Name, Email, and Website in this browser for the next time I comment."
                  />
                  <Button color="info" className={classContact.pullRight}>
                    Post Comment
                  </Button>
                </CardFooter>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Route, useLocation, useRouteMatch } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { SiWhatsapp } from "react-icons/si";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import { BsHeadset } from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { BsCheck } from "react-icons/bs";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styDrop from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import SweetAlert from "react-bootstrap-sweetalert";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

const useStyles = makeStyles(aboutUsStyle);
const useStyl = makeStyles(contactsStyle);
const useSty = makeStyles(styDrop);
const useStyleAlert = makeStyles(style);

export default function SendEnquiry() {
  const [name, setName] = React.useState("");
  const [nameErr, setNameErr] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageErr, setMessageErr] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [Date, setDate] = React.useState("");
  const [DateErr, setDateErr] = React.useState("");
  const [noOfpeople, setNoOfPeople] = React.useState("");
  const [noOfpeopleErr, setNoOfPeopleErr] = React.useState("");
  const [spin, setSpin] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertMsg, alertMsgOps] = React.useState("");
  const [alertType, alertTypeOps] = React.useState("");

  const match = useRouteMatch();

  // const r = Route();
  //   React.useEffect(() => {
  //     window.scrollTo(0, 0);
  //     document.body.scrollTop = 0;
  //   });

  const classes = useStyles();
  const classContact = useStyl();
  const classDrop = useSty();
  const classeAlert = useStyleAlert();

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    setMobileErr("");
  };

  const handleDatePicker = (Date) => {
    setDate(
      Date._d.getDate() +
        "-" +
        (Date._d.getMonth() + 1) +
        "-" +
        Date._d.getFullYear()
    );
    setDateErr("");
  };

  const handleMessage = () => {
    const msg =
      "Name: " +
      name +
      ", Phone Number: " +
      mobile +
      ", Date of Travel: " +
      Date +
      ", Number of People: " +
      noOfpeople +
      ", Email Id: " +
      email +
      ", Message: " +
      message +
      ", Url: " +
      `https://www.exploremp.com${match.path}`;

    let errorCount = 0;
    if (name === "" || !name) {
      errorCount++;
      setNameErr("Name is Required");
    }
    if (email === "" || !email) {
      errorCount++;
      setEmailErr("Email is Required");
    }
    if (message === "" || !message) {
      errorCount++;
      setMessageErr("Message is Required");
    }
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Mobile is Required");
    }
    if (Date === "" || !Date) {
      errorCount++;
      setDateErr("Date is Required");
    }
    if (noOfpeople === "" || !noOfpeople) {
      errorCount++;
      setNoOfPeopleErr("Number of People is Required");
    }
    if (errorCount === 0) {
      fetch(
        `http://localhost:8085/api/register/send/byClient?to=info@exploremp.com&email=info@exploremp.com&message=${msg}&subject=${match.path.slice(
          6
        )}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Send Successfully !!!") {
            setSubmitted(true);
            alertTypeOps("success");
            alertMsgOps("Send Successfully");
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const mystyle = {
    lineHeight: 1,
  };

  const ss = {
    display: "inline-block",
    padding: "0px",
    margin: "0px",
  };

  return (
    <div>
      <form>
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classeAlert.button + " " + classeAlert.success}
          />
        ) : null}
        <h3 style={{ color: "red", textAlign: "center", marginBottom: "0px" }}>
          Send Us An Enquiry
        </h3>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} md={12}>
              <CustomInput
                // labelText="Full Name"
                id="first2"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Full Name",
                  value: name,
                  onChange: (e) => {
                    const { value } = e.target;
                    setName(value);
                    setNameErr("");
                  },
                }}
                required={true}
              />
              <span style={{ color: "red" }}>{nameErr}</span>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: "10px" }}>
              {/* <label>Mobile Number</label> */}
              <PhoneInput
                // style={mystyle}
                inputStyle={{
                  width: "100%",
                  border: "none",
                  borderBottom: "1px solid #495057",
                  borderRadius: "0",
                }}
                country={"in"}
                name="mobile"
                value={mobile}
                onChange={(mobile) => {
                  handleMobileNumber(mobile);
                }}
                required={true}
              />
              <span style={{ color: "red" }}>{mobileErr}</span>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: "0px" }}>
              {/* <InputLabel className={classDrop.label}>Date Picker</InputLabel> */}
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    placeholder: "Date Picker Here",
                  }}
                  onChange={(Date) => handleDatePicker(Date)}
                  name="Date"
                  value={Date}
                  required={true}
                />
              </FormControl>
              <span style={{ color: "red" }}>{DateErr}</span>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: "0px" }}>
              <CustomInput
                // labelText="Number of People"
                id="noOfPeolple"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Number of People",
                  value: noOfpeople,
                  onChange: (e) => {
                    const { value } = e.target;
                    setNoOfPeople(value);
                    setNoOfPeopleErr("");
                  },
                }}
                required={true}
              />
              <span style={{ color: "red" }}>{noOfpeopleErr}</span>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: "0px" }}>
              <CustomInput
                // labelText="Email Address"
                id="email-address2"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Email Address",
                  value: email,
                  onChange: (e) => {
                    const { value } = e.target;
                    setEmail(value);
                    setEmailErr("");
                  },
                }}
                required={true}
              />
              <span style={{ color: "red" }}>{emailErr}</span>
            </GridItem>
            <GridItem xs={12} md={12} style={{ marginBottom: "0px" }}>
              <CustomInput
                // labelText="Message"
                id="message2"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: "Message",
                  multiline: true,
                  rows: 5,
                  value: message,
                  onChange: (e) => {
                    const { value } = e.target;
                    setMessage(value);
                    setMessageErr("");
                  },
                }}
                required={true}
              />
              <span style={{ color: "red" }}>{messageErr}</span>
            </GridItem>
          </GridContainer>
        </CardBody>
        <CardFooter
          className={classContact.justifyContentBetween}
          style={mystyle}
        >
          <p>
            <BsCheck color="red" size={20} /> We assure the privacy of your
            contact data.
          </p>
        </CardFooter>
        <CardFooter
          className={classContact.justifyContentBetween}
          style={mystyle}
        >
          <p>
            <BsCheck color="red" size={20} />
            This data will only be used by our team to contact you and no other
            purposes.
          </p>
        </CardFooter>
        <CardFooter>
          {/* <Button
            color="info"
            className={classContact.pullRight}
            onClick={handleMessage}
          >
            Submit
          </Button> */} <p style={{color: "green", fontWeight: "bold"}}>Click on WhatsApp Icon</p>
          <a
            href={`https://api.whatsapp.com/send?phone=918424881169&text=https://exploremp.com/%20Hello%20I%20am%20Interested%20-%20${name}%20-%20${mobile}%20-%20${Date}%20-%20${email}%20-%20${message}%20-%20${match.path}&app_absent=0`}
          >
            <SiWhatsapp
              style={{ marginLeft: "20px" }}
              color="green"
              size={30}
            />
          </a>
        </CardFooter>
        <hr />
        <CardFooter>
          <h4 style={{ color: "red" }}>Why Book With Us?</h4>
        </CardFooter>
        <CardFooter>
          <TbCurrencyDollar size={25} color="red" />
          No-hassle best price guarantee
        </CardFooter>
        <CardFooter>
          <BsHeadset size={20} color="red" />
          &nbsp;Customer care available 24/7
        </CardFooter>
        <CardFooter>
          <GrStar size={20} color="red" />
          &nbsp;Hand-picked Tours & Activities
        </CardFooter>
        <CardFooter>
          <SiYourtraveldottv size={20} color="red" />
          &nbsp;Free Travel Insurance
        </CardFooter>
        <hr />
        <CardFooter>
          <h4 style={{ color: "red" }}>Got a Question?</h4>
        </CardFooter>
        <CardFooter>
          Do not hesitate to give us a call. We are an expert team and we are
          happy to talk to you.
        </CardFooter>
        <CardFooter>
          <FiPhone size={20} color="red" />
          &nbsp;<a href="tel:+91-84248 81169">+91-84248 81169</a>
        </CardFooter>
        <CardFooter>
          <FiPhone size={20} color="red" />
          &nbsp;<a href="tel:+91-84248 82009">+91-84248 82009</a>
        </CardFooter>
        <CardFooter>
          <MdOutlineMailOutline size={20} color="red" />
          &nbsp;info@exploremp.com
        </CardFooter>
        <CardFooter>
          <MdOutlineMailOutline size={20} color="red" />
          &nbsp;exploremp.mumbai@gmail.com
        </CardFooter>
      </form>
    </div>
  );
}

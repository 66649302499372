/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/kanha/01KanhaPench.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function KanhaAndPenchNationalPark() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Kanha And Pench National Park</title>
        <meta
          name="keywords"
          content="Kanha, Kanha National Park, Kanha Tiger Reserve, Kanha Hotels, Hotels in Kanha, Khatia gate, Mukki gate, Sarhi gate, Kanha Jungle Safari, 
Resorts in Kanha National Park Pench, Pench Tiger Reserve, Pench National Park, Rudyard Kipling, Jungle book, Mowgli , Resorts in Pench, Hotels in Pench, Rookhad, 
Turia Gate, Khursapar Gate, Bird watching activity, Jungle activities, Jungle game drive"
        />
        <meta
          name="description"
          content="Kanha -Kanha National Park- Kanha Tiger Reserve- Kanha Hotels- Hotels in Kanha- Khatia gate- Mukki gate- Sarhi gate- Kanha Jungle Safari- 
Resorts in Kanha National Park Pench- Pench Tiger Reserve- Pench National Park- Rudyard Kipling- Jungle book- Mowgli - Resorts in Pench- Hotels in Pench- Rookhad- 
Turia Gate- Khursapar Gate- Bird watching activity-Jungle activities- Jungle game drive"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={
          require("assets/img/destination/kanha/Kanha-Pench-National-park.jpg")
            .default
        }
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Kanha and Pench National Park</h1>
              <h4>Enchanting Kanha and the Untamed Pench!</h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Kanha National Park, nestled in the Maikal ranges of Satpura in
                Madhya Pradesh, the national park has been declared as one of
                the finest wildlife areas in the world!
                <br />
                <br />
                Spread across two districts Kanha was declared a reserve forest
                in 1897! And then changed to a wildlife sanctuary in 1933!
                <br />
                <br />
                The landscapes and luxurious meadows that surround the forest
                are a sight to behold for all nature lovers!
                <br />
                <br />A trip to this beautiful forest takes you on a journey
                inspired, encounter a wide variety of wildlife species with the
                help of guided tours that take you deep into the forest! The
                best time to visit the Kanha Sanctuary would be October to June
                months when the wildlife is at its prime
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                Kanha National Park, nestled in the Maikal ranges of Satpura in
                Madhya Pradesh, the national park has been declared as one of
                the finest wildlife areas in the world!
                <br />
                <br />
                Spread across two districts Kanha was declared a reserve forest
                in 1897! And then changed to a wildlife sanctuary in 1933!
                <br />
                <br />
                The landscapes and luxurious meadows that surround the forest
                are a sight to behold for all nature lovers!
                <br />
                <br />
                A trip to this beautiful forest takes you on a journey inspired,
                encounter a wide variety of wildlife species with the help of
                guided tours that take you deep into the forest!
                <br />
                <br />
                The best time to visit the Kanha Sanctuary would be October to
                June months when the wildlife is at its prime.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import carouselStyle from "assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js";

import image1 from "assets/img/gallery/pilgrimage/Amarkantak/header-scaled.jpg";
import image2 from "assets/img/gallery/pilgrimage/Amarkantak/Kapildhara-Fall.jpg";
import image3 from "assets/img/gallery/pilgrimage/Amarkantak/Narmada-Udgam-and-Kund.jpg";
import image4 from "assets/img/gallery/pilgrimage/Amarkantak/Scenic-Beauty-of-Amarkantak.jpg";
import image5 from "assets/img/gallery/pilgrimage/Amarkantak/Shri-Yantra-Temple.jpg";

import image6 from "assets/img/gallery/pilgrimage/Omkareshwar/Header-2-scaled.jpg";

import image7 from "assets/img/gallery/pilgrimage/Ujjain/Gopal-Mandir.jpg";
import image8 from "assets/img/gallery/pilgrimage/Ujjain/Harsiddhi-Temple.jpg";
import image9 from "assets/img/gallery/pilgrimage/Ujjain/Header-15.jpg";
import image10 from "assets/img/gallery/pilgrimage/Ujjain/kal-bhairav-temple.jpg";
import image11 from "assets/img/gallery/pilgrimage/Ujjain/Mahakaleshwar-Temple.jpg";
import image12 from "assets/img/gallery/pilgrimage/Ujjain/Ram-Ghat-2.jpg";
import image13 from "assets/img/gallery/pilgrimage/Ujjain/Simhastha.jpg";
import image14 from "assets/img/gallery/pilgrimage/Ujjain/Ultimate-Gurudakshna.jpg";
import image15 from "assets/img/gallery/pilgrimage/Ujjain/Ved-Shala.jpg";

const useStyles = makeStyles(carouselStyle);

export default function PilgrimageCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className={classes.section} id="carousel">
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Amarkantak
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image2} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Amarkantak
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image3} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Amarkantak
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image4} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Amarkantak
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image5} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Amarkantak
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image6} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Omkareshwar
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.marginAuto}>
            <Card>
              <Carousel {...settings}>
                <div>
                  <img src={image7} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image8} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img src={image9} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image10}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image11}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image12}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image13}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image14}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
                <div>
                  <img
                    src={image15}
                    alt="First slide"
                    className="slick-image"
                  />
                  <div className="slick-caption">
                    <h4>
                      <LocationOn className="slick-icons" />
                      Ujjain
                    </h4>
                  </div>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Helmet } from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ViewAgendaIcon from "@material-ui/icons/ViewComfy";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
import presentationiPad from "assets/img/destination/sanchi/01Sanchi.jpg";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);

export default function Sanchi() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();

  return (
    <div>
      <Helmet>
        <title>Sanchi</title>
        <meta
          name="keywords"
          content="Sanchi, Sanchi Stupas, Buddhist stupas, Buddhist script, Unesco, World Heritage site, Hotels in Sanchi, Resorts in Sanchi, Udaygiri 
Sanchi guide"
        />
        <meta
          name="description"
          content="Sanchi - Sanchi Stupas - Buddhist stupas- Buddhist script- Unesco- World Heritage site -Hotels in Sanchi- Resorts in Sanchi- Udaygiri 
Sanchi guide"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 300,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/destination/sanchi/Sanchi.jpg").default}
        filter="dark"
        small
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classes.mlAuto,
                classes.mrAuto,
                classes.textCenter
              )}
            >
              <h1 className={classes.title}>Sanchi</h1>
              <h4>
                Sanchi stands tall with its beautiful stupas and mesmerising
                temples.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem md={6}>
              <div
                className={classe.imageContainer}
                style={{ marginTop: "10%" }}
              >
                <img
                  className={classe.ipadImg}
                  src={presentationiPad}
                  alt="iPad"
                />
              </div>
            </GridItem>
            <GridItem md={6}>
              <h5 className={classe.description}>
                Sanchi is a Buddhist complex famous for the Great Sanchi Stupa,
                in the central state of Madhya Pradesh. Located at a distance of
                46 km from Bhopal, this place is home to one of the oldest stone
                structures in India and an important monument
                <br />
                <br />
                Initially commissioned by emperor Ashoka in the 3rd Century BCE,
                it is the center point built over the relics of Buddha.
                <br />
                <br />
                The monuments at Sanchi today are a series of Buddhist monuments
                starting from the Maurya Empire (3rd century BCE), continuing
                with the Gupta Empire (5th century CE), and ending around the
                12th century CE. It is said to be the best-preserved group of
                Buddhist monuments in India.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12}>
              <h5 className={classe.description}>
                A pillar of finely polished sandstone was also raised near the
                side of the main Torana gateway.
                <br />
                The bottom part of the pillar still stands. Whereas the upper
                parts of the pillar are at the nearby Sanchi Archaeological
                Museum, for preservation.
                <br />
                <br />
                The great stupas hold carvings that tell great tales of the time
                period they were erected in. especially, Stupa no.2, with its
                intricate details are said to have the most detailed carvings
                from that time.
                <br />
                <br />
                The gateways to the stupas are the finest specimens of the art
                they hosted at that time.
                <br />
                <br />
                The images and carvings hold a lot of stories to tell!
                <br />
                <br />
                Visiting the stupas is easy as they are publically accessible.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

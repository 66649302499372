/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Carousel from "react-slick";
import { Helmet } from "react-helmet";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import LocationOn from "@material-ui/icons/LocationOn";
import Accordion from "components/Accordion/Accordion.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import NavPills from "components/NavPills/NavPills.js";
import { TbCurrencyDollar, TbCircleDot } from "react-icons/tb";
import {
  BsHeadset,
  BsStopwatch,
  BsCalendar2Event,
  BsCheck,
} from "react-icons/bs";
import { GrStar } from "react-icons/gr";
import { SiYourtraveldottv } from "react-icons/si";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { ImUsers, ImUser, ImLocation } from "react-icons/im";
import { GiMeal } from "react-icons/gi";

import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import Info from "components/Typography/Info.js";
import CardAvatar from "components/Card/CardAvatar.js";
import marc from "assets/img/faces/marc.jpg";

// sections for this page
import Header from "views/ExplorePage/Sections/ExploreHeader";
import HeaderLinks from "views/ExplorePage/Sections/ExploreHeaderLinks";
import Footer from "views/ExplorePage/Sections/ExploreFooter";
import TourMap from "views/ExplorePage/TourPackage/TourMap";

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";
import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";
import styleTable from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.js";
import styles from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";

import image1 from "assets/img/tourPackage/narmada/01_12_2021-30_nov._119_jab_2021.jpg";
import image2 from "assets/img/tourPackage/narmada/518a2f9176224ce2ce9304663c6b9c91.jpg";
import image3 from "assets/img/tourPackage/narmada/l_barwani210222054458_706_H@@IGHT_0_W@@IDTH_600.jpg";
import image4 from "assets/img/tourPackage/narmada/maxresdefault.jpg";
import image5 from "assets/img/tourPackage/narmada/WhatsApp Image1.jpeg";
import image6 from "assets/img/tourPackage/narmada/WhatsApp Image2.jpeg";
import image7 from "assets/img/tourPackage/narmada/WhatsApp Image3.jpeg";
// import image8 from "assets/img/tourPackage/narmada/WhatsApp Image4.jpeg";

import River from "assets/img/tourPackage/narmada/Mp-MH-package.jpg";
import Jungle from "assets/img/tourPackage/narmada/Homepage-Destinations-image-size-370_370-Pachmarhi.png";
import mapImage from "assets/img/tourPackage/narmada/768-512-16645607-thumbnail-3x2-narmada.jpg";

import SendEnquiry from "./SendEnquiry";

const useStyles = makeStyles(aboutUsStyle);
const useStyle = makeStyles(contentStyle);
const useStyl = makeStyles(contactsStyle);
const useStyTable = makeStyles(styleTable);
const useStyCard = makeStyles(styles);

export default function Narmada() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const classe = useStyle();
  const classContact = useStyl();
  const classTable = useStyTable();
  const classCard = useStyCard();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const scrollBar = {
    height: "230px",
    overflowX: "hidden",
    overflowY: "scroll"
  };

  return (
    <div>
      <Helmet>
        <title>Narmada Parikrama Yatra Group Tour Package</title>
        <meta
          name="keywords"
          content="Narmada Parikrama tour packages,
Rewa parikrama route, Narmada parikrama total distance, Narmada Parikrama from pune, Uttarvahini narmada parikrama, sankalp, narmada parikrama yatra, omkareshwar, shahada, ankleshwar, bharuch, ujjain, maheshwar, 
budhni, jabalpur, amarkantak, narsingpur, hoshngabad, Narmada parikrama bus tour, Garudeshwar boating, Hotel Reservation, M I C E, Wildlife Safari Tour, Guided Group Tour, Individual Tours, Pilgrims Tours, Hotel Marketing, Off-beat Destinations, Honeymoon Packages, PUJA SAMAGRI, PUNDITJI DAKSHINA, HOTEL TIPS, ENROUTE EXTRA MEALS,
SIGHT-SEEING POINT APART FROM THE NARMADA PARIKRAMA, NARMADA PARIKRAMA YATRA (GROUP TOUR) 14N/15D, UJJAIN TO MAHESHWAR, OMKARESHWAR TO SHAHADA, AMARKANTAK TO NARSINGPUR, HOSHANGABAD TO OMKARESHWAR, ANKLESHWAR TO BHARUCH, BUDHNI TO JABALPUR"
        />
        <meta
          name="description"
          content="Narmada Parikrama tour packages
Rewa parikrama route-Narmada parikrama total distance- Narmada Parikrama from pune- Uttarvahini narmada parikrama-sankalp-narmada parikrama yatra - omkareshwar-shahada-ankleshwar-bharuch-ujjain-maheshwar-
budhni-jabalpur-amarkantak-narsingpur-hoshngabad-Narmada parikrama bus tour-Garudeshwar boating, Hotel Reservation, M I C E, Wildlife Safari Tour, Guided Group Tour, Individual Tours, Pilgrims Tours, Hotel Marketing, Off-beat Destinations, Honeymoon Packages, PUJA SAMAGRI, PUNDITJI DAKSHINA, HOTEL TIPS, ENROUTE EXTRA MEALS,
SIGHT-SEEING POINT APART FROM THE NARMADA PARIKRAMA, NARMADA PARIKRAMA YATRA (GROUP TOUR) 14N/15D, UJJAIN TO MAHESHWAR, OMKARESHWAR TO SHAHADA, AMARKANTAK TO NARSINGPUR, HOSHANGABAD TO OMKARESHWAR, ANKLESHWAR TO BHARUCH, BUDHNI TO JABALPUR"
        />
      </Helmet>
      <Header
        brand="HOME"
        links={<HeaderLinks dropdownHoverColor="info" />}
        // fixed
        // color="transparent"
        color="info"
      // changeColorOnScroll={{
      //   height: 300,
      //   color: "info",
      // }}
      />

      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginTop: "10px" }}
      >
        <div
          className={classes.container}
          style={{
            padding: "40px 0",
          }}
        >
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              {/* <h3>Photos</h3> */}
              <Card>
                <Carousel {...settings}>
                  <div>
                    <img
                      src={image1}
                      alt="First slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        {/* Mahadev */}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image2}
                      alt="Second slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                       OMKARESHWAR
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image3}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        {/* Madhya Pradesh */}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image4}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        {/* Varun Pyasi */}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image5}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        AMARKANTAK
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image6}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        {/* AMARKANTAK */}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <img
                      src={image7}
                      alt="Third slide"
                      className="slick-image"
                    />
                    <div className="slick-caption">
                      <h2>
                        {/* <LocationOn className="slick-icons" /> */}
                        {/* Varun Pyasi */}
                      </h2>
                    </div>
                  </div>
                </Carousel>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <div>
                <h3 style={{ color: "red", textAlign: "center" }}>
                  NARMADA PARIKRAMA
                </h3>
                <hr />
                <div className="slick-caption" style={{ textAlign: "center" }}>
                  <h5>
                    <LocationOn
                      style={{ color: "#FF0000" }}
                      className="slick-icons"
                    />{" "}
                    14N/15D
                  </h5>
                </div>
                <GridContainer justify="center">
                  <GridItem md={12}>
                    <NavPills
                      color="info"
                      tabs={[
                        {
                          tabButton: "Feb",
                          tabContent: (
                            <span>
                              <GridContainer
                                className={classNames(
                                  classes.mlAuto,
                                  classes.mrAuto
                                )}
                              >
                                <GridItem md={12} xs={12} sm={12}>
                                  <Card>
                                    <CardHeader color="info" icon>
                                      <h6 className={classes.cardIconTitle}>
                                        Departure Dates & Price Per Person
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {/* <marquee behavior="scroll" direction="up" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"> */}
                                      <GridContainer style={scrollBar}>
                                        <GridItem md={12} sm={12} xs={12}>
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>14N/15D</p>

                                              <p>04 Feb 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 47,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                          <hr />
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>14N/15D</p>
                                              {/* <FlightTakeoffIcon style={{ color: "#FF0000" }} /> */}
                                              <p>20 Feb 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 47,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                        </GridItem>
                                      </GridContainer>
                                      {/* </marquee> */}
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </span>
                          ),
                        },
                        {
                          tabButton: "Mar",
                          tabContent: (
                            <span>
                              <GridContainer
                                className={classNames(
                                  classes.mlAuto,
                                  classes.mrAuto
                                )}
                              >
                                <GridItem md={12} xs={12} sm={12}>
                                  <Card>
                                    <CardHeader color="info" icon>
                                      <h6 className={classes.cardIconTitle}>
                                        Departure Dates & Price Per Person
                                      </h6>
                                    </CardHeader>
                                    <CardBody>
                                      {/* <marquee behavior="scroll" direction="up" scrollamount="3" onmouseover="this.stop();" onmouseout="this.start();"> */}
                                      <GridContainer style={scrollBar}>
                                        <GridItem md={12} sm={12} xs={12}>
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>14N/15D</p>

                                              <p>08 Mar 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 47,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                          <hr />
                                          <GridContainer>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <p>Tour Length</p>
                                              <p>14N/15D</p>
                                              {/* <FlightTakeoffIcon style={{ color: "#FF0000" }} /> */}
                                              <p>24 Mar 2023</p>
                                            </GridItem>
                                            <GridItem md={6} sm={6} xs={6}>
                                              <h3>₹ 47,999/-</h3>
                                            </GridItem>
                                          </GridContainer>
                                        </GridItem>
                                      </GridContainer>
                                      {/* </marquee> */}
                                    </CardBody>
                                  </Card>
                                </GridItem>
                              </GridContainer>
                            </span>
                          ),
                        },
                      ]}
                    />
                    <h6
                      className={classNames(classes.mlAuto, classes.mrAuto)}
                      style={{ textAlign: "center" }}
                    >
                      The above Discounted Tour Price is as of now, will change
                      as the seats fill.The Discounted price is inclusive of
                      INR. Taxes Extra Terms and Conditions apply
                    </h6>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem md={8}>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "DETAILS",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>
                              NARMADA PARIKRAMA YATRA (GROUP TOUR)- 14N/15D
                            </h3>

                            <h4>PARIKRAMA START & END POINT- INDORE.</h4>
                            <h4>PACKAGE TOUR COST PER PERSON: RS 47,999/-</h4>
                            <h4>
                              5% GST EXTRA <br />
                              WITH BREAKFAST AND DINNER MEAL PLAN FOR MINIMUM 12 PAX <br />
                              WITH ALL MEALS MINIMUM 30 PAX
                            </h4>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "ITINERARY",
                    tabContent: (
                      <span>
                        <GridContainer
                          className={classNames(classes.mlAuto, classes.mrAuto)}
                        >
                          <GridItem md={12}>
                            <h3>Itinerary</h3>
                            <Accordion
                              active={0}
                              activeColor="info"
                              collapses={[
                                {
                                  title:
                                    "Day 1 PICK UP FROM INDORE AND PROCEED TO OMKARESHWAR. SANKALP N/H",
                                  content: "",
                                },
                                {
                                  title: "Day 2 OMKARESHWAR TO SHAHADA. N/H",
                                  content: "",
                                },
                                {
                                  title: "Day 3 SHAHADA TO ANKLESHWAR. N/H",
                                  content: "",
                                },
                                {
                                  title: "Day 4 ANKLESHWAR TO BHARUCH. N/H",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 5 BHARUCH TO UJJAIN. N/H IN UJJAIN.",
                                  content: "",
                                },
                                {
                                  title: "Day 6 UJJAIN TO MAHESHWAR. N/H",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 7 MAHESHWAR S/S AND RELAX. N/H IN MAHESHWAR",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 8 MAHESHWAR TO BUDHNI. N/H IN BUDHNI",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 9 BUDHNI TO JABALPUR. N/H IN JABALPUR",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 10 JABALPUR TO AMARKANTAK. N/H IN AMARKANTAK",
                                  content: "",
                                },
                                {
                                  title: "Day 11 FULL DAY IN AMARKANTAK. N/H",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 12 AMARKANTAK TO NARSINGPUR. N/H IN NARSINGPUR",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 13 NARSINGPUR TO HOSHANGABAD. N/H IN HOSHANGABAD",
                                  content: "",
                                },
                                {
                                  title:
                                    "Day 14 HOSHANGABAD TO OMKARESHWAR. N/H IN OMKARESHWAR",
                                  content: "",
                                },
                                {
                                  title: "Day 15 TOUR ENDS. DEPART TO INDORE",
                                  content: "",
                                },
                              ]}
                            />
                            <h4>Inclusions:-</h4>
                            <h4>
                              COMFORTABLE ACCOMMODATIONS ON DBL /TRPL SHARING.
                              DAILY B/F + DINNER. (PURE VEG MEALS- SET MENU).
                              APART FROM THIS WILL BE CHARGEABLE OR CAN PAY
                              DIRECTLY. COMFORTABLE AC VEHICLE FROM PICK UP TILL
                              DROP AND BOAT CHARGES.
                            </h4>
                            <h4>Exclusions:-</h4>
                            <h4>
                              PUJA SAMAGRI, PUNDITJI DAKSHINA, HOTEL TIPS,
                              ENROUTE EXTRA MEALS, SIGHT-SEEING POINT APART FROM
                              THE NARMADA PARIKRAMA.
                            </h4>
                            <h4>Things to be noted:-</h4>
                            <h4>Package Cost Exclusions:-</h4>
                            <ul>
                              <li>
                                Govt Tax of 5% over and above the Tour Cost
                                mentioned.
                              </li>
                              <li>TO & FRO Air & Rail Ticket.</li>
                              <li>Cost of Insurance.</li>
                              <li>
                                Cost of Pre/Post tour hotel accommodation.
                              </li>
                              <li>
                                Any extra expense such as route change, Airline
                                change, Date change, Accommodation facilities,
                                etc incurred due to the unforeseen, unavoidable
                                forced majeure circumstances during the tour.
                              </li>
                              <li>
                                Porterage (coolie charges), laundry, telephone
                                charges, shopping, wines and alcoholic
                                beverages, mineral water, items of personal
                                nature and food or drink which is not part of a
                                set group menu.
                              </li>
                              <li>
                                Any extra cost incurred on behalf of an
                                individual due to illness, accident,
                                hospitalisation, or any personal emergency.
                              </li>
                              <li>
                                Any services or activity charges other than
                                those included in the group tour itinerary.
                              </li>
                              <li>
                                Anything specifically not mentioned in the ‘tour
                                price includes’ column.
                              </li>
                            </ul>
                            <h4>Important Guide Lines For The Tour:-</h4>
                            <ul>
                              <li>
                                Room Heater available with an extra cost bourne
                                by the guest directly.
                              </li>
                              <li>
                                Hotels are very strict with the child policy.
                                Please carry the age proof so that it can be
                                produced if asked by hotel.
                              </li>
                              <li>
                                A valid photo ID proof for all guests staying at
                                the hotel is mandatory (compulsory).
                              </li>
                              <li>
                                For Extra adult in the room we will provide an
                                extra bed (wherever possible), but most of the
                                hotels only provide extra mattress or roll out
                                bed. Most of the hotels have no provision of an
                                extra bed.
                              </li>
                              <li>
                                In the area (almost all) the hotels do not have
                                a lift facility and in all the hotels the guest
                                will have to climb stairs. In case if any guest
                                is having problem of blood pressure, knee or
                                other ailment for which they cannot climb the
                                higher floors they will have to intimate us
                                during the time of booking so that we can
                                arrange rooms at the lowest floor of the hotel
                                enabling the guest to climb the minimum number
                                of stairs possible.
                              </li>
                              <li>
                                All the meals will be provided at the hotel
                                dining rooms as per their timings, the food will
                                be provided as per their meal plan.
                              </li>
                              <li>
                                Please ensure that you do not leave your
                                belongings in the vehicle or in hotel rooms as
                                there is very little possibility of getting them
                                back. Always check your baggage before you leave
                                the vehicle or hotel room.
                              </li>
                              <li>
                                If guests want any changes in their sightseeing
                                schedule they should be informed to our
                                executive previous day.
                              </li>
                              <li>
                                The guest should always keep cool with the
                                drivers as they are not tourism educated and
                                come from different remote villages.
                              </li>
                              <li>
                                We would appreciate, if the guest does not lend
                                their ears to the drivers as most of the time
                                they misguide the passengers. In such instances
                                we would request them to contact our executive.
                              </li>
                              <li>
                                Operator reserves the right to re-arrange
                                itinerary to suit hotel availability without
                                changing the total number of days in each
                                destination and without compromising any
                                services.
                              </li>
                            </ul>
                            <h4>
                              Explore MP LLP is no circumstanced be liable
                              for-any death, Personal Injury, Sickness,
                              accident, loss, delay, discomfort, increase
                              expense consequential loss or damage or theft the
                              temporary or permanent loss or damage to baggage
                              or personal effects however caused. The
                              organization reserves the right to claim any
                              additional amount of money for expenses due to
                              delay in train, bus, air or any other weather
                              conditions, strikes, war quarantine or any other
                              cause. All the loss or expenses will have to borne
                              by the passenger.
                            </h4>
                            <h4>Terms & Conditions:-</h4>
                            <ul>
                              <li>
                                At the time of the booking every person will
                                have to pay 50% amount in advance, and the
                                remaining payment should be made 20 days before
                                the departure date of the tour, And if the
                                booking is made 20 days before tour departure
                                full payment shall be made in.
                              </li>
                              <li>
                                Payment can be made by cash, cheque or demand
                                draft, Nat Banking.
                              </li>
                              <li>
                                Air Tickets Issued for this tour are Non
                                Refundable.
                              </li>
                              <li>
                                Sightseeing and Traveling by 2×2 Luxury Coach
                                (in case there are less tourists, then the
                                sightseeing and traveling can be arranged by
                                Sumo, Tempo Traveler, Minibus) hotel stay on
                                twin sharing basis or family basis, meal
                                (breakfast, lunch, Dinner).
                              </li>
                              <li>
                                Seating arrangement in Bus / Coach are
                                allocation rotation daily.
                              </li>
                              <li>
                                Travel arrangement to and from the airport to
                                hotel is at customers own cost for other than
                                certain destinations specially mentioned in
                                tour.
                              </li>
                              <li>
                                There are pre-set menus for meal provided on
                                tour. In Train /Air we do not provide the f We
                                however reserve the right to change the meal
                                arrangements if circumstances make it necessary
                                for us to do so. In the event of the tour
                                participant missing any meal due to any reason,
                                then no claim can be made for the meal he has
                                missed and not utilized.
                              </li>
                              <li>
                                The company reserves the right to alter,
                                postpone or cancel any of the tour advertised in
                                the brochure, without assigning any reason.
                                whatsoever. Under circumstances of cancellation
                                of any tour by the company, money paid by the
                                tour participant will be fully refunded, but no
                                compensation is payable.
                              </li>
                              <li>
                                The organizer or tour manager has sole authority
                                to make changes in the program.
                              </li>
                              <li>
                                In most Hotels extra Bed provided constitutes
                                the mattress with bed sheet, blanket and pillow.
                              </li>
                              <li>
                                If the tour participant misbehaves in a manner
                                causing inconvenience or annoyance to other tour
                                participants or causes damage to the property of
                                the company he / she will be asked to leave the
                                tour immediately and the tour escorts have been
                                authorized to do so. There will not be any
                                compensation whatsoever in such.
                              </li>
                              <li>
                                Cancellation Policy:-
                                <br />* 60 days before the tour departure 15%
                                deduction from the tour cost.
                                <br />* 59 to 51 days before the tour departure
                                30% deduction from the tour cost.
                                <br />* 50 to 31 days before the tour departure
                                40% deduction from the tour cost.
                                <br />* 30 to 11 days before the tour departure
                                50% deduction from the tour cost.
                                <br />* 10 days before the tour departure no
                                refund.
                              </li>
                              <li>
                                10 days before the tour departure no refund.
                              </li>
                              <li>
                                The tour organization in no circumstances be
                                liable for:-
                              </li>
                              <li>
                                Company reserves the right to publish the
                                passengers photograph or group photograph
                                without intimation or permission of the same
                                passenger or group.
                              </li>
                              <li>
                                There can be in increase in the tour cost in
                                case of hike in diesel or petrol.
                              </li>
                              <li>
                                Any disputes arising in respect of the tour
                                shall be subject to Mumbai Jurisdiction.
                              </li>
                              <li>
                                It is understood that tour participants
                                traveling with Explore MP LLP have read and
                                accepted the above terms & conditions.
                              </li>
                            </ul>
                            <h4>
                              <span style={{ fontWeight: "bold" }}>
                                Explore MP LLP ( India )
                              </span>
                              <br />
                              _Be Wanderlust..Let’s Explore_
                            </h4>
                            <h4>
                              <span style={{ fontWeight: "bold" }}>
                                Naina Kshirsagar / Rakesh Tiwari
                              </span>
                              <br />
                              +91-8424882009 | +91-8424881169 | +91-9372641955
                              <br />
                              103, 1st Floor, Vishwas CHS, Ghatkopar (E),
                              Mumbai-400077.
                              <br />
                              Email:- info@exploremp.com |
                              exploremp.mumbai@gmail.com
                              <br />
                              Website:- www.exploremp.com
                            </h4>
                            <h4>
                              <span style={{ fontWeight: "bold" }}>
                                SERVICES WE OFFER
                              </span>
                              <br />
                              Hotel Reservation | M I C E | Wildlife Safari Tour
                              | Guided Group Tour | Individual Tours | Pilgrims
                              Tours | Hotel Marketing | Off-beat Destinations |
                              Honeymoon Packages |{" "}
                            </h4>
                          </GridItem>
                        </GridContainer>
                      </span>
                    ),
                  },
                  {
                    tabButton: "MAP",
                    tabContent: <span>
                      <div>
                        <div className={classCard.container}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <CardHeader image plain>
                                <img src={mapImage} alt="Map" />
                              </CardHeader>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>
                    </span>,
                  },
                  // {
                  //   tabButton: "FAQ",
                  //   tabContent: (
                  //     <span>
                  //       <GridContainer
                  //         className={classNames(classes.mlAuto, classes.mrAuto)}
                  //       >
                  //         <GridItem md={12}>
                  //           <h3>FAQ</h3>
                  //           <Accordion
                  //             active={0}
                  //             activeColor="info"
                  //             collapses={[
                  //               {
                  //                 title: "Best time to visit Madhya Pradesh",
                  //                 content:
                  //                   "The best time to visit Madhya Pradesh is winter months when the weather is cold and the destinations open to take in visitors. Travellers can also visit in the summer and rain months, but that is not recommended.",
                  //               },
                  //               {
                  //                 title: "Services offered?",
                  //                 content:
                  //                   "We offer a wide range of services, from group tours, wildlife tours, exploratory tours, temple tours and more!",
                  //               },
                  //               {
                  //                 title: "Which tour is best for me?",
                  //                 content:
                  //                   "You can find a detailed itinerary on the tours we offer, they cover most of the details you will need to select the best tour for you! Still hesitant? You can call us on ___",
                  //               },
                  //               {
                  //                 title: "Do you provide flight bookings?",
                  //                 content:
                  //                   "No, unfortunately, we do not provide flight booking, but we can recommend the best flights and timings that you can go for to match your selected tour.",
                  //               },
                  //               {
                  //                 title:
                  //                   "Who can I contact in case of any problems during my tour/ stay?",
                  //                 content:
                  //                   "Our tour managers are always on-site and with you at every step of your tour, so there shouldn’t be any problems. In case of any issues, you can call our emergency helpline for immediate assistance.",
                  //               },
                  //             ]}
                  //           />
                  //         </GridItem>
                  //       </GridContainer>
                  //     </span>
                  //   ),
                  // },
                  {
                    tabButton: "REVIEWS",
                    tabContent: (
                      <span>
                        <div>
                          <div className={classCard.container}>
                            <h3>Related Tours</h3>
                            <GridContainer>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={River} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Best Seller
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${River})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        MH-MP (Jyotirlinga) Special Group Tour
                                        Packages
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                              <GridItem xs={12} sm={6} md={6}>
                                <Card profile plain>
                                  <CardHeader image plain>
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <img src={Jungle} alt="..." />
                                      <div
                                        className={classCard.cardTitleAbsolute}
                                      >
                                        Popular Deals
                                      </div>
                                    </a>
                                    <div
                                      className={classCard.coloredShadow}
                                      style={{
                                        backgroundImage: `url(${Jungle})`,
                                        opacity: "1",
                                      }}
                                    />
                                  </CardHeader>
                                  <CardBody plain>
                                    <Info>
                                      <h6 className={classCard.cardCategory}>
                                        Satpuras Queen
                                      </h6>
                                    </Info>
                                  </CardBody>
                                </Card>
                              </GridItem>
                            </GridContainer>
                          </div>
                        </div>
                      </span>
                    ),
                  },
                ]}
              />
            </GridItem>
            <GridItem md={4}>
              <SendEnquiry />
            </GridItem>
          </GridContainer>
          {/* .... */}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

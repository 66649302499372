/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function ExploreHeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem} style={{ fontSize: "13px" }}>
        <Link to="/about-us" className={classes.dropdownLink}>
          ABOUT US
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          // onClick={() => console.log("section Omega")}
          hoverColor={dropdownHoverColor}
          buttonText="DESTINATIONS"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          //   buttonIcon={ViewDay}
          dropdownList={[
            <Link
              to="/bandhavgarh-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Bandhavgarh
            </Link>,
            <Link
              to="/bhopal-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Bhopal
            </Link>,
            <Link
              to="/gwalior-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Gwaliar
            </Link>,
            <Link
              to="/indore-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Indore
            </Link>,
            <Link
              to="/jabalpur-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Jabalpur
            </Link>,
            <Link
              to="/kanha-and-pench-national-park-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Kanha & Pench National Park
            </Link>,
            <Link
              to="/khajuraho-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Khajuraho
            </Link>,
            <Link
              to="/maheshwar-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Maheshwar
            </Link>,
            <Link
              to="/mandu-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Mandu
            </Link>,
            <Link
              to="/orchha-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Orchha
            </Link>,
            <Link
              to="/pachmarhi-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Pachmarhi
            </Link>,
            <Link
              to="/ujjain-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Ujjain
            </Link>,
            <Link
              to="/sanchi-tour"
              className={classes.dropdownLink}
              onClick={(e) => smoothScroll(e, "headers")}
            >
              Sanchi
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="TOUR PACKAGES"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          //   buttonIcon={ViewCarousel}
          dropdownList={[
            <Link
              to="/madhya-pradesh-short-trips"
              className={classes.dropdownLink}
            >
              Short Trips
            </Link>,
            <Link
              to="/tour/enchanting-madhya-pradesh-package-tour-itinerary"
              className={classes.dropdownLink}
            >
              Enchanging Madhya Pradesh
            </Link>,
            <Link
              to="/tour/madhya-pradesh-travel-itinerary"
              className={classes.dropdownLink}
            >
              Glimpses of Madhya Pradesh
            </Link>,
            <Link
              to="/tour/satpura-tour-package"
              className={classes.dropdownLink}
            >
              Satpuras Queen
            </Link>,
            <Link
              to="/tour/madhya-pradesh-tour-plan"
              className={classes.dropdownLink}
            >
              Royal Madhya Pradesh
            </Link>,
            <Link
              to="/tour/malwa-tour-package"
              className={classes.dropdownLink}
            >
              Malwa Delight
            </Link>,
            <Link
              to="/tour/madhya-pradesh-package-tour"
              className={classes.dropdownLink}
            >
              Magical Madhya Pradesh
            </Link>,
            <Link
              to="/tour/15-days-sampoorna-madhya-pradesh-tour-itinerary"
              className={classes.dropdownLink}
            >
              Sampoorna Madhya Pradesh Group Tour Packages
            </Link>,
            <Link
              to="/tour/narmada-parikrama-tour-packages"
              className={classes.dropdownLink}
            >
              Narmada Parikrama Yatra Group Tour Packages
            </Link>,
            <Link
              to="/tour/jyotirlinga-tour-package-from-mumbai"
              className={classes.dropdownLink}
            >
              MH-MP (Jyotirlinga) Special Group Tour Packages
            </Link>,
            <Link
              to="/tour/madhya-pradesh-jain-tour"
              className={classes.dropdownLink}
            >
              Jain Tour
            </Link>,
            <Link
              to="/tour/madhya-pradesh-tour-package-for-ladies"
              className={classes.dropdownLink}
            >
              Ladies Special Tour Packages
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem} style={{ fontSize: "13px" }}>
        <Link to="/our-service" className={classes.dropdownLink}>
          OUR SERVICE
        </Link>
      </ListItem>
      <ListItem className={classes.listItem} style={{ fontSize: "13px" }}>
        <Link to="/gallery" className={classes.dropdownLink}>
          GALLERY
        </Link>
      </ListItem>
      <ListItem className={classes.listItem} style={{ fontSize: "13px" }}>
        <Link to="/contact-us" className={classes.dropdownLink}>
          CONTACT
        </Link>
      </ListItem>
      <ListItem className={classes.listItem} style={{ fontSize: "13px" }}>
        <Link to="/blogs" className={classes.dropdownLink}>
          BLOGS
        </Link>
      </ListItem>
    </List>
  );
}

ExploreHeaderLinks.defaultProps = {
  hoverColor: "primary",
};

ExploreHeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
